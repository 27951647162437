import { PageContainer, ProCard, ProDescriptions, ProSkeleton } from '@ant-design/pro-components';
import React, { useEffect } from 'react';
import { Typography, Button, Table, message, Flex, Popover, QRCode } from 'antd';
import { LicenseDto } from 'apis/ProjectApi';
import { useAppState } from 'providers/AppStateProvider';
import { EditOutlined, ArrowRightOutlined, CopyOutlined, QrcodeOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/lib';
import {
    ProjectDeleteDialog,
    ProjectInputModal,
    ProjectCloneModal,
} from 'components/dialogs/InputProjectDialog';
import { useAuth } from 'providers/AuthProvider';
import { ResourceRole } from 'apis/UserApi';
const { Paragraph, Text } = Typography;
import { Link, useNavigate } from 'react-router-dom';
import { VenueInfoDto } from 'apis/VenueApi';
import { ProjectStatisticModule } from 'components/ProjectStatistic';

const ProjectScreen: React.FC = () => {
    const { isSuperAdmin, hasAccess } = useAuth();
    const { activeProject, project, refreshProject, isMobile } = useAppState();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!activeProject?.projId) {
            const isViewer = hasAccess(ResourceRole.VIEWER, activeProject?.projId);
            if (isViewer) {
                refreshProject(activeProject?.projId);
            }
        }
    }, [activeProject]);

    const venueColumns: TableProps<VenueInfoDto>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ];
    const licenseColumns: TableProps<LicenseDto>['columns'] = [
        {
            title: 'Key',
            dataIndex: 'accessKey',
            key: 'accessKey',
            width: 400,
            render: (_, record) => (
                <>
                    <Text type="secondary">Access key:</Text>
                    <Paragraph copyable={{ text: record.accessKey }}>
                        {record.accessKey}
                        <Popover content={<QRCode value={record.accessKey} bordered={false} />}>
                            <QrcodeOutlined
                                style={{ marginLeft: 6 }}
                                className="ant-typography-expand"
                            />
                        </Popover>
                    </Paragraph>
                    <Text type="secondary">Access secret:</Text>
                    <Paragraph copyable={{ text: record.accessSecret }}>
                        {record.accessSecret}
                        <Popover content={<QRCode value={record.accessSecret} bordered={false} />}>
                            <QrcodeOutlined
                                style={{ marginLeft: 6 }}
                                className="ant-typography-expand"
                            />
                        </Popover>
                    </Paragraph>
                </>
            ),
        },
    ];

    if (!project) {
        return <ProSkeleton type="descriptions" />;
    }
    const extraBtn = [];

    if (isSuperAdmin) {
        extraBtn.push(
            <ProjectCloneModal
                key="p-ex-clone"
                cloningProject={project}
                trigger={<Button icon={<CopyOutlined />}>Clone project</Button>}
                onSuccess={() => {
                    refreshProject();
                    message.success('Project cloned');
                }}
            />,
        );
    }

    if (hasAccess(ResourceRole.ADMIN, activeProject?.projId)) {
        extraBtn.push(
            <ProjectInputModal
                key="p-ex-edit"
                mode="ACTION_EDIT"
                initialValues={project}
                trigger={<Button icon={<EditOutlined />}>Edit</Button>}
                onSuccess={() => {
                    refreshProject();
                    message.success('Saved');
                }}
            />,
        );
    }
    if (isSuperAdmin) {
        extraBtn.push(<ProjectDeleteDialog key="p-ex-del" trigger={<></>} />);
    }

    return (
        <PageContainer
            title={project?.name ?? '-'}
            header={{
                ghost: true,
                breadcrumb: {
                    items: [{ title: 'Project' }, { title: project?.id }],
                },
            }}
            extra={extraBtn}
            content={<Text type="secondary">{project?.description}</Text>}
        >
            <ProCard>
                <ProDescriptions column={isMobile ? 1 : 2}>
                    <ProDescriptions.Item label="Id" copyable>
                        {project?.id}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="Created at">
                        {project?.createdAt}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="venues">
                        {project?.venues?.length}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="licenses">
                        {project?.licenses?.length}
                    </ProDescriptions.Item>
                </ProDescriptions>
            </ProCard>
            <Flex gap="middle" style={{ marginTop: '1em' }} vertical={isMobile}>
                <ProCard
                    title={
                        <Link to="/project/venue" style={{ color: 'black' }}>
                            Venues
                        </Link>
                    }
                    extra={[
                        <Button
                            key="ven-ext-btn"
                            type="link"
                            onClick={() => navigate('/project/venue')}
                        >
                            View all <ArrowRightOutlined />
                        </Button>,
                    ]}
                >
                    <Table
                        rowKey="id"
                        columns={venueColumns}
                        dataSource={project?.venues ?? []}
                        pagination={false}
                        size="small"
                        showHeader={false}
                        rowClassName="cursor-pointer"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => {
                                    navigate(`/project/venue/${record.id}`);
                                },
                            };
                        }}
                    />
                </ProCard>
                <ProCard
                    title={
                        <Link to="/project/license" style={{ color: 'black' }}>
                            Licenses
                        </Link>
                    }
                    extra={[
                        <Button
                            key="lic-ext-btn"
                            type="link"
                            onClick={() => navigate('/project/license')}
                        >
                            View all <ArrowRightOutlined />
                        </Button>,
                    ]}
                >
                    <Table
                        rowKey="accessKey"
                        columns={licenseColumns}
                        dataSource={(project?.licenses ?? []).filter(
                            (el) => el.enabled && new Date(el.expiredAt) > new Date(),
                        )}
                        pagination={false}
                        showHeader={false}
                        size="small"
                    />
                </ProCard>
            </Flex>
            <ProjectStatisticModule />
        </PageContainer>
    );
};

export default ProjectScreen;
