import { Flex, Select, Typography } from 'antd';
import { useVenueState } from 'providers/VenueProvider';
import { memo } from 'react';

interface IFloorSelectorProps {
    warnIds?: string[];
    onChange?: (id: string) => void;
}

const FloorSelector: React.FC<IFloorSelectorProps> = (props) => {
    const { venue, workingMap, selectWorkingMap } = useVenueState();
    const mWarnIds = props?.warnIds ?? [];
    return (
        <Flex
            align="center"
            wrap="wrap"
            gap={12}
            style={{
                backgroundColor: '#e6f4ffaa',
                padding: '12px 24px',
            }}
        >
            <Typography.Text strong>Floor plan:</Typography.Text>
            <Select
                style={{ flex: 1 }}
                onChange={(mapId) => {
                    if (props.onChange) {
                        props.onChange(mapId);
                    } else {
                        selectWorkingMap(mapId);
                    }
                }}
                placeholder="Select a floor plan"
                showSearch
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={venue?.maps.map((map) => ({
                    label: map.name,
                    value: map.id,
                    className: mWarnIds.includes(map.id) ? 'warn-row' : undefined,
                }))}
                value={workingMap?.id}
                title={workingMap?.id}
            />
        </Flex>
    );
};

export default memo(
    FloorSelector,
    (prev, curr) => JSON.stringify(prev.warnIds) === JSON.stringify(curr.warnIds),
);
