import React, { memo, useCallback, useState } from 'react';
import { Flex, Select, Tag, Typography } from 'antd';
import { CONTROL_POSITION_CLASSES } from 'services/BaseMapEngine';
import Markdown from 'react-markdown';
import CustomIcon from 'components/CustomIcon';
import { LayerGroup, Polygon } from 'react-leaflet';
import { useVenueState } from 'providers/VenueProvider';
import * as turf from '@turf/turf';
import ImageOverlayRotated from './ImageOverlayRotated';

type SurveyQualityLayerProps = {
    whitelist?: string[];
};

const SurveyOverlayMenuItems = [
    {
        key: 'coverage',
        title: 'Map coverage',
        icon: <CustomIcon icon="target" size={16} />,
        help: '## Map coverage\nThe polygon depicts the navigable area, i.e. the area that has been mapped and where the devices can be positioned. The area depends on the the location of the map paths.',
    },
    {
        key: 'magnetic',
        title: 'Magnetic mapping quality',
        icon: <CustomIcon icon="compass" size={16} />,
        help: '## Magnetic mapping quality\nThis analytic describes how accurately nearby paths have been collected, that is, to which degree signals from the paths match.\n\n*Note: If this analytic is not available, go to Survey revision panel and click Update analytics.*',
        legend: true,
        _dataKey: 'magneticQlt',
    },
    {
        key: 'wifi-coverage',
        title: 'Wi-Fi mapping coverage',
        icon: <CustomIcon icon="wifi" size={16} />,
        help: '## Wi-Fi mapping coverage\nThis analytic describes the density of Wi-Fi scans in the mapping data across the floor plan. Green indicates that there are enough Wi-Fi scans from an area.\n\nIn short: collect more paths on non-green areas.\n\nThe Wi-Fi scanning rate varies across different mobile phones, so you may need to collect more than one path in every area to ensure sufficient coverage.\n\n*Note: If this analytic is not available, go to Survey revision panel and click Update analytics.*',
        legend: true,
        _dataKey: 'wifiCov',
    },
    {
        key: 'wifi-quality',
        title: 'Wi-Fi environment quality',
        icon: <CustomIcon icon="wifi" size={16} />,
        help: '## Wi-Fi environment quality\nThe Wi-Fi signal environment quality analytics describe how informative radio maps can be generated in the environment. They depend on the average error generated in the mapping data and the observed signal strengths.\n\n*Note: If this analytic is not available, go to Survey revision panel and click Update analytics.*',
        legend: ['<3m', '~7m', '≥10m'],
        _dataKey: 'wifiQlt',
    },
    {
        key: 'beacon-quality',
        title: 'Beacon environment',
        icon: <CustomIcon icon="bluetooth" size={16} />,
        help: '## Beacon environment\nThe Beacon environment analytics displays detected iBeacon locations and their major values in the environment when a beacon plan is unavailable.\n\n*Note: These analytics may update during site package generation.*',
        _dataKey: 'beaconQlt',
    },
    {
        key: 'no-overlay',
        title: 'No overlay',
    },
];

const SurveyQualityLayer: React.FC<SurveyQualityLayerProps> = (props) => {
    const [selectedLayer, setSelectedLayer] = useState<string>('no-overlay');
    const [helpDoc, setHelpDoc] = useState<string>();
    const { surveySessions, workingMap, surveyQuality } = useVenueState();

    const _renderMenu = useCallback(() => {
        const selectedItem = SurveyOverlayMenuItems.find((el) => el.key === selectedLayer);
        return (
            <Flex
                className="leaflet-control"
                onMouseLeave={() => setHelpDoc(undefined)}
                align="start"
            >
                <Flex gap={8} vertical>
                    <Select
                        defaultValue="no-overlay"
                        style={{ width: '16rem' }}
                        onChange={(value) => setSelectedLayer(value)}
                        options={SurveyOverlayMenuItems.map((el) => {
                            const enabled = el._dataKey
                                ? !!((surveyQuality ?? {}) as any)[el._dataKey]
                                : true;
                            return {
                                label: (
                                    <Flex
                                        style={{ width: '16rem', opacity: enabled ? 1 : 0.4 }}
                                        align="center"
                                        gap={8}
                                        onMouseOver={() => setHelpDoc(el.help)}
                                    >
                                        {el.icon}
                                        <Typography.Text>{el.title}</Typography.Text>
                                    </Flex>
                                ),
                                value: el.key,
                                disabled: !enabled,
                            };
                        })}
                    />
                    {_renderLegend(selectedItem?.legend)}
                </Flex>
                {helpDoc ? (
                    <div
                        className="leaflet-control map-toolbar"
                        style={{
                            marginTop: 0,
                            minWidth: 250,
                            width: '20vw',
                            maxHeight: '70vh',
                            overflowY: 'auto',
                        }}
                    >
                        <Markdown>{helpDoc}</Markdown>
                    </div>
                ) : undefined}
            </Flex>
        );
    }, [selectedLayer, helpDoc]);

    const _renderLegend = useCallback((legend?: boolean | string[]) => {
        if (!legend) return undefined;
        const labels = Array.isArray(legend) ? legend : ['Good', 'Ok', 'Bad', 'Unknown'];
        return (
            <Flex gap={8} wrap="wrap" className="legend--tag-group">
                <Tag color="#87d068">{labels[0] ?? 'Good'}</Tag>
                <Tag color="#f8e53a">{labels[1] ?? 'Ok'}</Tag>
                <Tag color="#d5352f">{labels[2] ?? 'Bad'}</Tag>
                {labels.length > 3 ? (
                    <Tag color="#108ee9">{labels[3] ?? 'Unknown'}</Tag>
                ) : undefined}
            </Flex>
        );
    }, []);

    const _renderOverlay = useCallback(() => {
        if (selectedLayer === 'no-overlay') {
            return undefined;
        } else if (selectedLayer === 'coverage') {
            return _renderCoverage();
        } else if (selectedLayer === 'magnetic' && surveyQuality?.magneticQlt) {
            return _renderImgOverlay(surveyQuality.magneticQlt);
        } else if (selectedLayer === 'wifi-coverage' && surveyQuality?.wifiCov) {
            return _renderImgOverlay(surveyQuality.wifiCov);
        } else if (selectedLayer === 'wifi-quality' && surveyQuality?.wifiQlt) {
            return _renderImgOverlay(surveyQuality.wifiQlt);
        } else if (selectedLayer === 'beacon-quality' && surveyQuality?.beaconQlt) {
            return _renderImgOverlay(surveyQuality.beaconQlt);
        }
        return undefined;
    }, [selectedLayer, surveyQuality, props.whitelist]);

    function _renderCoverage() {
        if (!surveySessions || surveySessions.length === 0) return undefined;
        const mPaths = surveySessions.flatMap((x) => x.paths);
        const mPolygons = [];
        const mWhiteList = props.whitelist ?? [];
        for (const curr of mPaths) {
            if (!mWhiteList.includes(curr.name)) continue;
            const line = turf.lineString(curr.polyline);
            const buffered = turf.buffer(line, 2, { units: 'meters' });
            const buffPositions = buffered.geometry.coordinates.map((ring) =>
                ring.map((x) => [x[1], x[0]]),
            );
            mPolygons.push(
                <Polygon
                    key={`polygon-${curr.id}`}
                    positions={buffPositions as any}
                    interactive={false}
                    pathOptions={{
                        fillOpacity: 0.3,
                        stroke: false,
                        color: '#02a5f4',
                        lineCap: 'round',
                    }}
                />,
            );
        }
        return mPolygons;
    }

    function _renderImgOverlay(src: string) {
        if (!workingMap?.mapAlign) return;
        return (
            <ImageOverlayRotated
                imgSrc={src}
                positions={[
                    workingMap.mapAlign.corners.tl,
                    workingMap.mapAlign.corners.tr,
                    workingMap.mapAlign.corners.br,
                    workingMap.mapAlign.corners.bl,
                ]}
                opacity={0.9}
                fitBounds
            />
        );
    }

    return (
        <>
            <div className={CONTROL_POSITION_CLASSES.topleft}>{_renderMenu()}</div>
            <LayerGroup>{_renderOverlay()}</LayerGroup>
        </>
    );
};

export default memo(
    SurveyQualityLayer,
    (prev, curr) => prev.whitelist?.length === curr.whitelist?.length,
);
