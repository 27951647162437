import { ProCard } from '@ant-design/pro-components';
import { Badge, Button, Checkbox, Flex, Space, Tooltip, Tree, Typography } from 'antd';
import { SurveyPathDto, SurveySessionDto } from 'apis/VenueApi';
import { useAuth } from 'providers/AuthProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CustomIcon from './CustomIcon';
import {
    FolderOpenFilled,
    MobileOutlined,
    NodeIndexOutlined,
    SwapOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import moment from 'moment';

export type PathSelectorProps = {
    surveySessions: SurveySessionDto[];
    checkedPaths: string[];
    selectedPath?: string;
    onPathSelected: (name?: string) => void;
    onPathChecked: (names: string[]) => void;
    checkMode?: 'PROJ' | 'PATH';
};

const PathSelector: React.FC<PathSelectorProps> = (props) => {
    const { isSuperAdmin } = useAuth();
    const [speedMode, setSpeedMode] = useState<number>(0);
    const [nameMode, setNameMode] = useState<boolean>(true);
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

    const childCheckable = useMemo(() => {
        return props.checkMode !== 'PROJ';
    }, [props.checkMode]);

    const allPathName = useMemo(() => {
        setExpandedKeys([]);
        return props.surveySessions?.flatMap((el) => el.paths.map((p) => p.name)) || [];
    }, [props.surveySessions]);

    useEffect(() => {
        if (props.selectedPath) {
            const mSelectedPath = props.selectedPath;
            const batchId = props.surveySessions.find((session) =>
                session.paths.some((path) => path.name === mSelectedPath),
            )?.id;
            const mParentId = batchId ? `S:PROJ:${batchId}` : undefined;
            if (mParentId && !expandedKeys.includes(mParentId)) {
                setExpandedKeys((x) => [...x, mParentId]);
            }
            setTimeout(() => scrollToElement(mSelectedPath), 250);
        }
    }, [props.selectedPath]);

    const scrollToElement = useCallback((name: string) => {
        const element = document.querySelector(`[title="${name}"]`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const _renderPathRow = useCallback(
        (item: SurveyPathDto) => {
            const { duration, distance, error, sample } = item;
            const sec = duration / 1000;
            const meterSpeed = distance / sec;
            const getText = () => {
                switch (speedMode) {
                    case 0:
                        return `${meterSpeed.toFixed(1)}m/s`;
                    case 1:
                        return `${distance.toFixed(1)}m / ${sec.toFixed(1)}s`;
                    case 2:
                        const ft = distance * 3.28084;
                        return `${ft.toFixed(1)}ft / ${sec.toFixed(1)}s`;
                    case 3:
                        const _ft = distance * 3.28084;
                        const ftSpeed = _ft / sec;
                        return `${ftSpeed.toFixed(1)}ft/s`;
                    default:
                        return undefined;
                }
            };
            const errorMsg = !!error ? (
                <span>
                    Invalid survey data, please redo this path.
                    <br />
                    <i>{error}</i>
                </span>
            ) : meterSpeed >= 1.2 ? (
                'Walking too fast. Try to walk at a pace of less than 1.2 meters per second.'
            ) : undefined;
            return (
                <Flex justify="center" align="center" gap={8}>
                    {isSuperAdmin && !sample?.wifi ? (
                        <CustomIcon icon="wifi_off" size={16} title="No Wi-Fi data" color="red" />
                    ) : undefined}
                    {isSuperAdmin && !sample?.ble ? (
                        <CustomIcon
                            icon="bluetooth_off"
                            size={16}
                            title="No Bluetooth data"
                            color="red"
                        />
                    ) : undefined}
                    {!!errorMsg ? (
                        <Tooltip title={errorMsg}>
                            <WarningOutlined style={{ color: error ? 'red' : 'orange' }} />
                        </Tooltip>
                    ) : undefined}
                    {getText()}
                </Flex>
            );
        },
        [isSuperAdmin, speedMode],
    );

    const treeData = useMemo(() => {
        return props.surveySessions.map((el) => {
            const deviceInfo = (
                <Space>
                    <MobileOutlined />
                    <span>
                        {el.deviceModel} ({el.deviceOs})
                    </span>
                </Space>
            );
            const hasError =
                isSuperAdmin &&
                el.paths.some((x) => !x.sample?.ble || !x.sample?.wifi || !x.sample?.mov);
            const showName = nameMode && !!el.name;
            const allDisabled = childCheckable ? undefined : !el.paths.some((x) => x.enabled);
            return {
                title: (
                    <Tooltip
                        title={deviceInfo}
                        color="grey"
                        placement="right"
                        trigger="contextMenu"
                    >
                        <Flex className="tree-title" justify="space-between" align="center">
                            <Space title={el.id} style={{ flex: 1 }}>
                                <FolderOpenFilled style={{ color: '#4164fb' }} />
                                <Typography.Text
                                    className={
                                        'line-clamp ' + (hasError ? 'underline-red-wavy' : '')
                                    }
                                >
                                    {showName
                                        ? el.name
                                        : moment(el.recordAt).format('YYYY-MM-DD HH:mm Z')}
                                </Typography.Text>
                            </Space>
                            <Typography.Text type="secondary">{`${el.paths.length} paths`}</Typography.Text>
                            <Badge dot count={el.built ? 0 : 1} title="New survey paths" />
                        </Flex>
                    </Tooltip>
                ),
                key: `S:PROJ:${el.id}`,
                disabled: allDisabled,
                children: el.paths.map((x) => {
                    return {
                        title: (
                            <Flex justify="space-between" align="center">
                                <Typography.Text ellipsis title={x.name}>
                                    <NodeIndexOutlined />
                                    {moment(x.recordAt).format('YYYY-MM-DD HH:mm')}
                                </Typography.Text>
                                <Typography.Text type="secondary" italic>
                                    {_renderPathRow(x)}
                                </Typography.Text>
                            </Flex>
                        ),
                        key: x.name,
                        isLeaf: true,
                        checkable: childCheckable,
                    };
                }),
            };
        });
    }, [props.surveySessions, isSuperAdmin, nameMode, speedMode]);

    const checkedPaths = props.checkedPaths.length;
    const totalPaths = childCheckable === false ? props.surveySessions.length : allPathName.length;

    return (
        <ProCard
            title={
                <Space style={{ marginLeft: 12 }}>
                    <Checkbox
                        indeterminate={checkedPaths !== totalPaths && checkedPaths > 0}
                        onChange={(e) =>
                            props.onPathChecked(
                                e.target.checked
                                    ? childCheckable === false
                                        ? props.surveySessions.map((el) => el.id)
                                        : allPathName
                                    : [],
                            )
                        }
                        checked={checkedPaths === totalPaths && totalPaths > 0}
                    />
                    <Typography.Text
                        onDoubleClick={() => {
                            setNameMode((x) => !x);
                        }}
                    >
                        Survey {childCheckable ? 'paths' : 'batches'}
                    </Typography.Text>
                </Space>
            }
            subTitle={`${checkedPaths}/${totalPaths}`}
            headerBordered
            ghost
            size="small"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
            bodyStyle={{ padding: 0 }}
            extra={[
                <Button
                    key="ext-survey-path"
                    type="text"
                    size="small"
                    icon={<SwapOutlined style={{ color: 'grey' }} />}
                    title="Show speed"
                    onClick={() => setSpeedMode((x) => (x + 1) % 5)}
                />,
            ]}
        >
            <Tree
                checkable
                showIcon
                blockNode
                onExpand={(expKeys, info) => setExpandedKeys(expKeys)}
                onSelect={(selectedKeys, info) => {
                    props.onPathSelected(
                        info.selected && selectedKeys.length > 0
                            ? (selectedKeys as string[])[0]
                            : undefined,
                    );
                }}
                onCheck={(checkedKeys, info) => {
                    props.onPathChecked(
                        childCheckable === false
                            ? (checkedKeys as string[]).map((el) => el.replace('S:PROJ:', ''))
                            : (checkedKeys as string[]).filter((x) => !x.startsWith('S:PROJ:')),
                    );
                }}
                expandedKeys={expandedKeys}
                selectedKeys={props.selectedPath ? [props.selectedPath] : undefined}
                checkedKeys={
                    childCheckable
                        ? props.checkedPaths
                        : props.checkedPaths.map((x) => `S:PROJ:${x}`)
                }
                treeData={treeData}
                rootClassName="survey-tree"
                rootStyle={{
                    backgroundColor: 'transparent',
                    padding: '6px 6px 6px 0',
                }}
            />
        </ProCard>
    );
};

export default React.memo(PathSelector);
