import { Button } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { memo, useState } from 'react';
import { ButtonProps } from 'antd/lib';

interface ILockButtonProps extends ButtonProps {
    initValue?: boolean;
    onToggle?: (flag: boolean) => void;
}

const LockButton: React.FC<ILockButtonProps> = (props) => {
    const [isOn, setIsOn] = useState<boolean>(props.initValue ?? false);
    return (
        <Button
            icon={
                <LinkOutlined
                    rotate={45}
                    style={{ color: isOn ? '#1677ff' : 'rgba(0,0,0,0.15)' }}
                />
            }
            type={props.type ?? 'text'}
            size={props.size ?? 'small'}
            onClick={(evt) => {
                const nextVal = !isOn;
                if (props.onClick) props.onClick(evt);
                if (props.onToggle) props.onToggle(nextVal);
                setIsOn(nextVal);
            }}
            {...props}
        />
    );
};

export default memo(LockButton, (prev, curr) => prev.initValue === curr.initValue);
