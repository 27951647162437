import { useTraceContext } from 'providers/TraceProvider';
import { useEffect, useRef, useState } from 'react';
import TraceQueryExecutionResultsCard from './TraceQueryExecutionResultsCard';
import TraceQueryExecutionsCard from './TraceQueryExecutionsCard';

const RECORD_PER_PAGE = 50;

const TraceQuerySearchCard = ({ isMobile }: { isMobile: boolean }) => {
    const [page, setPage] = useState(1);
    const [isResultsLoading, setIsResultsLoading] = useState(false);
    const {
        fetchExecutions,
        executionList,
        fetchExecutionCount,
        fetchExecutionStatus,
        fetchExecutionResults,
        executionResults,
        executionResultPageConfig,
        setSelectedExecution,
        selectedExecution,
        updateExecutionStatus,
        resetExecutionResults,
        executionPageConfig,
    } = useTraceContext();
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    async function refreshExecutions(page?: number) {
        await fetchExecutions({
            limit: executionPageConfig.limit,
            page: page ?? 0,
        });
    }

    async function refreshExecutionResults(page: number) {
        if (selectedExecution.id) {
            setIsResultsLoading(true);
            try {
                const nextToken = executionResultPageConfig[page - 1];
                await fetchExecutionResults(selectedExecution.id, page, { limit: RECORD_PER_PAGE, nextToken });
                setSelectedExecution({ ...selectedExecution, loaded: true });
            } catch (error) {
                console.error(error);
            }
            setIsResultsLoading(false);
        }
    }

    function handleExecutionChanged(id: string, query: string) {
        if (id !== selectedExecution.id) {
            setSelectedExecution({ id, query, loaded: false });
            setPage(1);
        }
    }

    async function handleResultsPageChanged(page: number) {
        if (!executionResults[page + 1])
            await refreshExecutionResults(page + 1);
        setPage(page + 1);
    }

    useEffect(() => {
        if (executionList.length === 0)
            refreshExecutions().then().catch();
    }, []);

    useEffect(() => {
        if (selectedExecution.id !== null && !selectedExecution.loaded) {
            resetExecutionResults();
            const checkStatus = async () => {
                if (selectedExecution.id !== null) {
                    setIsResultsLoading(true);
                    const status = await fetchExecutionStatus(selectedExecution.id);
                    if (status !== 'QUEUED' && status !== 'RUNNING') {
                        if (status === 'SUCCEEDED') {
                            await fetchExecutionCount(selectedExecution.id);
                            await refreshExecutionResults(1);
                            updateExecutionStatus(selectedExecution.id, status);
                        } else {
                            setIsResultsLoading(false);
                            if (!!status) {
                                await refreshExecutions();
                            }
                        }

                    } else {
                        timerRef.current = setTimeout(checkStatus, 500);
                    }
                }
            };
            checkStatus().then().catch(() => setIsResultsLoading(false));
        }

        return () => {
            if (timerRef.current)
                clearTimeout(timerRef.current);
        };
    }, [selectedExecution.id]);

    return <>
        <TraceQueryExecutionResultsCard
            isMobile={isMobile}
            executionId={selectedExecution.id}
            query={selectedExecution.query}
            page={page}
            perPage={RECORD_PER_PAGE}
            isLoading={isResultsLoading}
            onPageChanged={handleResultsPageChanged}
        />
        <TraceQueryExecutionsCard
            isMobile={isMobile}
            onView={handleExecutionChanged}
            onPageChanged={async (page: number, _size: number) => {
                await refreshExecutions(page - 1);
            }} />
    </>;
};

export default TraceQuerySearchCard;
