import React, { useEffect, useMemo, useState } from 'react';
import { SurveySessionDto } from 'apis/VenueApi';
import { LayerGroup, Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';

type SurveyPathLayerProps = {
    data: SurveySessionDto[];
    whitelist: string[];
    onPathClick?: (name: string) => void;
    showWaypoint?: boolean;
    showArrow?: boolean;
    showPath?: boolean;
    selectedName?: string;
};

interface IPathMeta {
    positions: [number, number][];
    name: string;
    sessId: string;
}

const waypointIcon = L.icon({
    iconUrl:
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iOS41IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSI1Ii8+Cjwvc3ZnPgo=',
    iconSize: [12, 12],
});
const getArrowIcon = (angle: number, color: string = '#3388ff') =>
    L.divIcon({
        className: 'arrowIcon',
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        html: `<div style = 'color: ${color}; font-size: 20px; font-family: sans-serif, serif, Arial, Times; -webkit-transform: rotate(${angle}deg)'>&#10148;</div>`,
    });

const selectedStyle = { color: '#f71b7e', weight: 5 };
const pathStyle = { color: '#3388ff', weight: 3 };

const SurveyPathLayer: React.FC<SurveyPathLayerProps> = (props) => {
    const paths = useMemo<IPathMeta[]>(() => {
        return (props.data ?? []).reduce<IPathMeta[]>((pre, curr) => {
            const child = curr.paths
                .filter((x) => props.whitelist.includes(x.name))
                .map((y) => ({
                    positions: y.polyline.map((z) => [z[1], z[0]] as [number, number]),
                    name: y.name,
                    sessId: `S:PROJ:${curr.id}`,
                }));
            return pre.concat(child);
        }, []);
    }, [props.data, props.whitelist]);

    const dimPath = useMemo<IPathMeta | undefined>(() => {
        if (!!props.selectedName && !props.whitelist.includes(props.selectedName)) {
            const mPath = (props.data ?? [])
                .flatMap((el) => el.paths)
                .find((x) => x.name === props.selectedName);
            return mPath
                ? {
                      positions: mPath.polyline.map((z) => [z[1], z[0]] as [number, number]),
                      name: mPath.name,
                      sessId: 'no',
                  }
                : undefined;
        } else {
            return undefined;
        }
    }, [props.data, props.selectedName]);

    function _onPolylineClick(item: IPathMeta, evt: any) {
        if (props.onPathClick) props.onPathClick(item.name);
    }

    function _renderPath(el: IPathMeta, idx: number, pathOption?: any) {
        const lastIndex = el.positions.length - 1;
        if (lastIndex < 0) return undefined;
        const diffLat = el.positions[lastIndex][0] - el.positions[lastIndex - 1][0];
        const diffLng = el.positions[lastIndex][1] - el.positions[lastIndex - 1][1];
        const angle = 360 - Math.atan2(diffLat, diffLng) * 57.295779513082;
        const isSelected = props.selectedName === el.name || props.selectedName === el.sessId;
        const mStyle = { ...(isSelected ? selectedStyle : pathStyle), ...pathOption };
        const mOpacity = mStyle?.opacity ?? 1;
        return (
            <LayerGroup key={`mak-${idx}`}>
                {props.showArrow ? (
                    <Marker
                        position={el.positions[lastIndex]}
                        icon={getArrowIcon(angle, mStyle.color)}
                        interactive={false}
                        zIndexOffset={2}
                        opacity={mOpacity}
                    />
                ) : undefined}
                {props.showWaypoint
                    ? el.positions.map((p, pIdx) => {
                          return (
                              <Marker
                                  key={`wp-${idx}-${pIdx}`}
                                  position={p as any}
                                  icon={waypointIcon}
                                  interactive={false}
                                  opacity={mOpacity}
                              />
                          );
                      })
                    : undefined}
                {props.showPath ? (
                    <Polyline
                        positions={el.positions}
                        eventHandlers={{ click: (e) => _onPolylineClick(el, e) }}
                        pathOptions={mStyle}
                    />
                ) : undefined}
            </LayerGroup>
        );
    }

    return (
        <LayerGroup>
            {paths.map((el, idx) => _renderPath(el, idx))}
            {dimPath
                ? _renderPath(dimPath, -1, { color: 'black', opacity: 0.4, weight: 5 })
                : undefined}
        </LayerGroup>
    );
};

export default SurveyPathLayer;
