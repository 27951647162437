import { ProCard } from '@ant-design/pro-components';
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Button, Spin, Empty, Typography, Flex, message, Badge, Alert, Divider } from 'antd';
import { FireOutlined, SettingOutlined } from '@ant-design/icons';
import { useAppState } from 'providers/AppStateProvider';
import { useVenueState } from 'providers/VenueProvider';
import FloorPlanMapView from 'components/maps/FloorPlanMapView';
import { updateSurveyRevision } from 'apis/VenueApi';
import SurveyPathLayer from 'components/maps/SurveyPathLayer';
import moment from 'moment';
import LoadingOverlay from 'components/LoadingOverlay';
import LayerToolbar from 'components/maps/LayerToolbar';
import SurveyQualityLayer from 'components/maps/SurveyQualityLayer';
import FloorSelector from 'components/FloorSelector';
import { useAuth } from 'providers/AuthProvider';
import { ResourceRole } from 'apis/UserApi';
import CustomIcon from 'components/CustomIcon';
import AugmentationModal from 'components/dialogs/AugmentationDialog';
import PathSelector from 'components/PathSelector';
import AugmentCreateModule from 'components/dialogs/AugmentCreateDialog';

const { Text } = Typography;

const VenueSurveyScreen: React.FC = () => {
    const { hasAccess, isSuperAdmin } = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const {
        venue,
        workingMap,
        surveySessions,
        fetchSurveyData,
        fetchSurveyQuality,
        isLoading,
        surveyQuality,
        analyzeSurvey,
    } = useVenueState();
    const { project, isMobile, appSettings, saveAppSettings } = useAppState();
    const [pathVisible, setPathVisible] = useState<boolean>(true);
    const [checkedPaths, setCheckedPaths] = useState<string[]>([]);
    const [selectedPath, setSelectedPath] = useState<string>();
    const enabledSnapshot = useRef<string[]>([]);
    const allPathName = useRef<string[]>([]);

    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const isEditor = hasAccess(ResourceRole.EDITOR, project?.id);

    useEffect(() => {
        fetchSurveyData();
    }, [venue, workingMap]);

    const mSessions = useMemo(() => {
        const mAllPaths = [];
        const enabledPathName = [];
        if (surveySessions && surveySessions?.length > 0) {
            for (const el of surveySessions) {
                for (const p of el.paths) {
                    mAllPaths.push(p.name);
                    if (p.enabled) enabledPathName.push(p.name);
                }
            }
        }
        enabledSnapshot.current = enabledPathName.sort();
        allPathName.current = mAllPaths;
        setCheckedPaths(enabledPathName);
        setSelectedPath(undefined);
        return surveySessions;
    }, [surveySessions, setCheckedPaths, setCheckedPaths]);

    const _hasChange = useMemo(() => {
        return enabledSnapshot.current.join(',') !== checkedPaths.sort().join(',');
    }, [enabledSnapshot, checkedPaths]);

    const _handleReset = useCallback(() => {
        setCheckedPaths(enabledSnapshot.current);
    }, [setCheckedPaths]);

    const _handleSubmit = () => {
        if (!workingMap) return;
        const disabled = allPathName.current.filter((el) => !checkedPaths.includes(el));
        setIsUpdating(true);
        updateSurveyRevision(workingMap.id, {
            mapId: workingMap.id,
            blacklist: disabled,
        })
            .then((resp) => {
                if (resp.response?.status ?? 999 < 400) {
                    messageApi.success('Survey revision updated.');
                    fetchSurveyData();
                }
            })
            .finally(() => {
                setIsUpdating(false);
            });
    };
    const handleAnalyzeClick = () => {
        if (!!workingMap && !!surveyQuality && surveyQuality.analyzeState !== 'ANALYZING') {
            analyzeSurvey();
        }
    };

    const _renderAugmentCard = useCallback(() => {
        if (!isSuperAdmin) return <></>;
        const mAugState = surveyQuality?.augmentState;
        const isNotReady = mAugState === 'NOT_READY';
        const mState = isNotReady
            ? { status: 'error', text: 'Not Available', type: 'danger' }
            : mAugState === 'AVAILABLE'
              ? { status: 'default', text: 'Ready', type: 'secondary' }
              : { status: 'success', text: 'Available', type: 'success' };
        return (
            <ProCard
                size="small"
                bordered
                style={{ marginTop: '1rem', borderStyle: 'dashed' }}
                headStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
                headerBordered
                bodyStyle={{ padding: 0 }}
                title={
                    <Flex gap={8} align="center">
                        <CustomIcon icon="stars" size={18} color="yellow" />
                        <span>Survey Augmentation</span>
                        <code
                            style={{
                                opacity: 0.4,
                                fontSize: '60%',
                                fontStretch: 'expanded',
                            }}
                        >
                            BETA
                        </code>
                    </Flex>
                }
                extra={
                    <Badge
                        status={mState.status as any}
                        text={
                            <Typography.Text type={mState.type as any} className="text-08">
                                {mState.text}
                            </Typography.Text>
                        }
                    />
                }
            >
                <Flex justify="space-around" align="center">
                    {isNotReady ? undefined : (
                        <>
                            <AugmentationModal
                                trigger={
                                    <Button
                                        key="btn_setting"
                                        type="text"
                                        style={{ flex: 1 }}
                                        icon={<SettingOutlined />}
                                    >
                                        Setting
                                    </Button>
                                }
                                onSuccess={(flag: boolean) => {
                                    if (flag) fetchSurveyQuality();
                                }}
                            />
                            <Divider type="vertical" style={{ margin: 0 }} />
                        </>
                    )}
                    <AugmentCreateModule
                        trigger={
                            <Button
                                key="btn_aug"
                                type="text"
                                style={{ flex: 1 }}
                                icon={<FireOutlined />}
                            >
                                Augment
                            </Button>
                        }
                        onSuccess={(flag)=>{
                            if (flag) fetchSurveyQuality();
                        }}
                    />
                </Flex>
            </ProCard>
        );
    }, [fetchSurveyQuality, surveyQuality, isSuperAdmin]);

    const _renderPanelContent = () => {
        if (!mSessions || mSessions.length == 0) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No survey data."
                    style={{ margin: '2rem 1rem' }}
                />
            );
        }
        const mSelector = (
            <PathSelector
                surveySessions={mSessions}
                checkedPaths={checkedPaths}
                selectedPath={selectedPath}
                onPathChecked={setCheckedPaths}
                onPathSelected={setSelectedPath}
            />
        );
        if (!isEditor) {
            return (
                <Flex style={{ padding: 12 }} vertical>
                    {mSelector}
                </Flex>
            );
        }

        const hasNewRevision = mSessions.some((el) => !el.built);
        const isAnalyzing = surveyQuality?.analyzeState === 'ANALYZING';
        return (
            <Flex style={{ padding: '0 12px 24px 12px' }} vertical gap={6}>
                {isSuperAdmin ? _renderAugmentCard() : undefined}
                <Text style={{ padding: '6px 12px 0 12px' }} type="secondary">
                    Enable the paths which you wish to include in the map, and update analytics to
                    see the effect of the changes on the map.
                </Text>
                <Flex
                    style={{ padding: '6px 12px' }}
                    align="center"
                    justify="space-between"
                    gap={8}
                    wrap="wrap"
                >
                    <span style={{ color: 'grey', fontSize: '80%' }}>
                        {'Last analyze: '}
                        {surveyQuality?.analyzeAt
                            ? moment(surveyQuality?.analyzeAt).format('YYYY-MM-DD HH:mm')
                            : '-'}
                    </span>
                    <Button
                        shape="round"
                        size="small"
                        type={surveyQuality?.analyzeState === 'OUTDATED' ? 'primary' : 'default'}
                        disabled={!surveyQuality || isAnalyzing}
                        loading={isAnalyzing}
                        onClick={handleAnalyzeClick}
                    >
                        Update analytics
                    </Button>
                </Flex>
                {hasNewRevision ? (
                    <Alert
                        banner
                        type="warning"
                        message="You have unapplied survey revisions. Please build a new site package to apply these changes."
                    />
                ) : undefined}
                {_hasChange ? (
                    <Text type="warning">Note: You have unsaved change.</Text>
                ) : undefined}
                {mSelector}
                <Flex gap="1em" style={{ padding: '1rem' }} justify="flex-end">
                    <Button onClick={_handleReset}>Reset</Button>
                    <Button type="primary" disabled={!_hasChange} onClick={_handleSubmit}>
                        Save
                    </Button>
                </Flex>
            </Flex>
        );
    };
    const _renderPanel = () => {
        return (
            <ProCard
                colSpan={isMobile ? undefined : '30%'}
                className="venue-panel"
                style={{ overflowY: isUpdating ? 'hidden' : 'auto' }}
                ghost
                split="horizontal"
            >
                <LoadingOverlay visible={isUpdating || isLoading} spin />
                <FloorSelector warnIds={venue?.maps.filter((x) => !x.coverage).map((y) => y.id)} />
                {workingMap?.id && _renderPanelContent()}
            </ProCard>
        );
    };
    const _renderMap = () => {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#ddd',
                    position: 'relative',
                }}
            >
                {venue && workingMap && surveySessions && surveySessions.length > 0 ? (
                    <FloorPlanMapView
                        imageUrl={workingMap.mapImg}
                        mapAlign={workingMap.mapAlign}
                        initCenter={workingMap?.mapAlign?.center ?? venue.center}
                        layerControl={
                            appSettings.layerGray
                                ? { initTier: 'CartoDB.Positron', visible: false }
                                : false
                        }
                        grayscale={appSettings.layerGray}
                    >
                        <LayerToolbar
                            options={[
                                { name: 'Waypoint', key: 'layerWaypoint', defaultChecked: appSettings.layerWaypoint },
                                { name: 'Path', key: 'path', defaultChecked: pathVisible },
                                { name: 'Arrow', key: 'layerArrow', defaultChecked: appSettings.layerArrow },
                                { name: 'Grayscale', key: 'layerGray', defaultChecked: appSettings.layerGray },
                            ]}
                            onChange={(key, checked) => {
                                if (key.startsWith('layer')) {
                                    saveAppSettings({[key]: checked})
                                } else if (key === 'path') {
                                    setPathVisible(checked);
                                }
                            }}
                        />
                        <SurveyPathLayer
                            data={surveySessions}
                            whitelist={checkedPaths}
                            onPathClick={setSelectedPath}
                            selectedName={selectedPath}
                            showWaypoint={appSettings.layerWaypoint}
                            showArrow={appSettings.layerArrow}
                            showPath={pathVisible}
                        />
                        <SurveyQualityLayer whitelist={checkedPaths} />
                    </FloorPlanMapView>
                ) : (
                    <Empty
                        image={false}
                        description={
                            !workingMap
                                ? 'Select floor plan to continue.'
                                : !surveySessions || surveySessions.length == 0
                                  ? 'No survey data, please start mapping with our survey app.'
                                  : 'No map data :('
                        }
                    />
                )}
            </div>
        );
    };
    if (!project || !venue) return <Spin />;
    return (
        <ProCard
            style={{ height: '100%' }}
            split={isMobile ? 'horizontal' : 'vertical'}
            className="full-content-height"
        >
            {contextHolder}
            {_renderPanel()}
            {_renderMap()}
        </ProCard>
    );
};

export default VenueSurveyScreen;
