import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
    Alert,
    Badge,
    Button,
    Empty,
    Flex,
    message,
    Pagination,
    Space,
    Steps,
    Table,
    Tag,
    Typography,
} from 'antd';
import { ModalForm } from '@ant-design/pro-components';
import { CheckCircleOutlined, ExportOutlined, HistoryOutlined } from '@ant-design/icons';
import {
    AugmentedBatchDto,
    AugBatchStatus,
    getAugmentedList,
    updateAugmentConfig,
} from 'apis/VenueApi';
import moment from 'moment';
import { useVenueState } from 'providers/VenueProvider';
import { BaseDialogProps } from './BaseDialogProps.type';
import { Link, useNavigate } from 'react-router-dom';

const AugmentationModal: React.FC<BaseDialogProps> = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { workingMap, surveyQuality } = useVenueState();
    const pageMetaRef = useRef({ page: 0, total: 0, totalPage: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [augBatches, setAugBatches] = useState<AugmentedBatchDto[]>([]);
    const [selectedBatch, setSelectedBatch] = useState<React.Key | undefined>(undefined);
    const [moduleVisible, setModuleVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleOnClose = useCallback((flag: boolean) => {
        setModuleVisible(false);
        setAugBatches([]);
        setSelectedBatch(undefined);
        if (props.onSuccess) props.onSuccess(flag);
    }, []);

    const activatedAugId = useMemo(() => {
        const mState = surveyQuality?.augmentState;
        const augId =
            !!mState && mState !== 'NOT_READY' && mState !== 'AVAILABLE' ? mState : undefined;
        if (augId) setSelectedBatch(augId);
        return augId;
    }, [surveyQuality]);

    const augStep = useMemo(() => {
        const augId = surveyQuality?.augmentState;
        if (augId === 'NOT_READY') {
            return -1;
        } else if (augId === 'AVAILABLE') {
            return 1;
        } else if (!!augId && augBatches.length > 0) {
            return 2;
        } else {
            return -1;
        }
    }, [surveyQuality, augBatches]);

    const refreshAugList = (page: number = 0, limit: number = 5) => {
        if (!workingMap?.id) return;
        setIsLoading(true);
        getAugmentedList(workingMap?.id, page, limit)
            .then((resp) => {
                const mPageConf = resp.response?.data?._metadata?.pagination;
                if (mPageConf) pageMetaRef.current = { page, ...mPageConf };
                setAugBatches(resp.data ?? []);
            })
            .catch(() => {
                pageMetaRef.current = { page: 0, total: 0, totalPage: 0 };
                setAugBatches([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateActiveBatch = useCallback(() => {
        const targetAugId = selectedBatch ? selectedBatch.toString() : activatedAugId;
        if (!targetAugId) {
            console.warn('No augment id.');
            return;
        }
        setIsLoading(true);
        updateAugmentConfig(workingMap!.id, {
            augId: targetAugId,
            activate: !!selectedBatch,
        })
            .then((resp) => {
                messageApi.success('Change saved');
                handleOnClose(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [messageApi, selectedBatch, setIsLoading, updateAugmentConfig]);

    const onClickPreview = useCallback(
        (item: AugmentedBatchDto) => {
            if (!workingMap?.mapAlign) return;
            navigate(`/project/venue/${workingMap.venueId}/augment`, {
                state: { augBatch: item },
            });
        },
        [navigate, workingMap],
    );

    const _renderTags = useCallback((item: AugmentedBatchDto, index: number) => {
        const isRunning = !(
            [AugBatchStatus.FAIL, AugBatchStatus.READY, AugBatchStatus.FORMATTED] as string[]
        ).includes(item.status);
        return (
            <>
                {item.status === AugBatchStatus.FAIL ? (
                    <Badge status="error" text="Failed" />
                ) : isRunning ? (
                    <Badge status="processing" text={item.status} />
                ) : undefined}
            </>
        );
    }, []);

    const isChanged = useMemo(() => {
        return activatedAugId !== selectedBatch;
    }, [activatedAugId, selectedBatch]);

    const _renderFooter = () => {
        return (
            <Flex justify="space-between" align="center">
                <Space>
                    {selectedBatch ? (
                        <Button size="small" onClick={() => setSelectedBatch(undefined)}>
                            Unset
                        </Button>
                    ) : undefined}
                    {isChanged ? (
                        <>
                            <Button type="primary" size="small" onClick={() => updateActiveBatch()}>
                                Save
                            </Button>
                            <Typography.Text type="warning">
                                Note: You have unsaved change.
                            </Typography.Text>
                        </>
                    ) : undefined}
                </Space>
                <Pagination
                    size="small"
                    hideOnSinglePage={true}
                    defaultPageSize={5}
                    defaultCurrent={1}
                    current={(pageMetaRef.current?.page ?? 0) + 1}
                    total={pageMetaRef.current?.total}
                    onChange={(page, size) => {
                        refreshAugList(page - 1, size);
                    }}
                />
            </Flex>
        );
    };

    const _renderBatchTable = () => {
        return (
            <Table<AugmentedBatchDto>
                key={`aug-b-${workingMap?.id}`}
                rowKey="id"
                size="small"
                loading={isLoading}
                rowClassName={(record) => (record.outdated ? 'opacity-05' : '')}
                rowSelection={{
                    type: 'radio',
                    selectedRowKeys: selectedBatch ? [selectedBatch] : undefined,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: AugmentedBatchDto[]) => {
                        if (selectedRowKeys.length === 1) {
                            setSelectedBatch(selectedRowKeys[0]);
                        }
                    },
                    getCheckboxProps: (record: AugmentedBatchDto) => ({
                        disabled: record.status !== AugBatchStatus.READY,
                    }),
                }}
                columns={[
                    {
                        title: 'Snapshot',
                        dataIndex: 'createdAt',
                        width: 150,
                        render: (value, record) => {
                            return moment(value).format('YYYY-MM-DD HH:mm').toString();
                        },
                    },
                    {
                        dataIndex: 'blockSize',
                        width: 50,
                        render: (value, record) => {
                            return value !== undefined ? (
                                <Tag bordered={false}>
                                    {value}×{value}
                                </Tag>
                            ) : (
                                '-'
                            );
                        },
                    },
                    {
                        dataIndex: 'outdated',
                        width: 220,
                        render: (value, record) => {
                            return (
                                <Space>
                                    {record.outdated ? (
                                        <Tag icon={<HistoryOutlined />}>Outdated</Tag>
                                    ) : (
                                        <Tag color="#2db7f5">Latest</Tag>
                                    )}
                                    {record.activated ? (
                                        <Tag icon={<CheckCircleOutlined />} color="success">
                                            Activated
                                        </Tag>
                                    ) : undefined}
                                </Space>
                            );
                        },
                    },
                    {
                        dataIndex: 'status',
                        align: 'right',
                        render: (value, record, index) => {
                            if (record.status === AugBatchStatus.FORMATTED) {
                                return (
                                    <Alert
                                        message="Pending to adjust the fingerprint!"
                                        type="warning"
                                        showIcon
                                        style={{ padding: '0px 6px' }}
                                        action={
                                            <Button
                                                size="small"
                                                style={{ marginLeft: 12 }}
                                                onClick={() => onClickPreview(record)}
                                            >
                                                Adjust
                                            </Button>
                                        }
                                    />
                                );
                            } else if (record.status === AugBatchStatus.READY) {
                                return (
                                    <Button
                                        type={record.outdated ? 'text' : 'link'}
                                        className={record.outdated ? 'opacity-05' : undefined}
                                        key="btn_preview"
                                        size="small"
                                        icon={<ExportOutlined />}
                                        iconPosition="end"
                                        onClick={() => onClickPreview(record)}
                                    >
                                        Preview
                                    </Button>
                                );
                            } else {
                                return _renderTags(record, index);
                            }
                        },
                    },
                ]}
                dataSource={augBatches}
                pagination={false}
                footer={_renderFooter}
            />
        );
    };

    const stepItems = useMemo(() => {
        const _renderTitle = (step: number, title: any) => {
            return (
                <Space>
                    <b>Step {step}:</b>
                    <span>{title}</span>
                </Space>
            );
        };
        return [
            {
                title: _renderTitle(1, 'Request augmentation'),
            },
            {
                title: _renderTitle(2, 'Select augmented batch'),
                description: _renderBatchTable(),
            },
            {
                title: _renderTitle(
                    3,
                    <>
                        <Link to={`/project/venue/${workingMap?.venueId}/package`}>
                            Build site package
                        </Link>
                        <span> with augmented fingerprint.</span>
                    </>,
                ),
            },
        ];
    }, [workingMap, _renderBatchTable]);

    if (!workingMap?.id) {
        return <Empty style={{ margin: 36 }} description="No augmentation info available." />;
    }

    return (
        <>
            {contextHolder}
            <ModalForm
                title="Survey Augmentation"
                trigger={props.trigger}
                modalProps={{
                    destroyOnClose: true,
                    onCancel: () => handleOnClose(false),
                }}
                open={moduleVisible}
                onOpenChange={(visible) => {
                    setModuleVisible(visible);
                    if (visible && workingMap) {
                        if (activatedAugId) setSelectedBatch(activatedAugId);
                        refreshAugList();
                    }
                }}
                style={{ top: 20 }}
                width="80%"
                submitter={false}
            >
                <Typography.Text type="secondary">
                    Enhance your WiFi fingerprint coverage and accuracy with survey augmentation
                    tool.
                </Typography.Text>
                <Steps
                    direction="vertical"
                    style={{ marginTop: 24 }}
                    current={augStep}
                    size="small"
                    items={stepItems}
                />
            </ModalForm>
        </>
    );
};

export default React.memo(AugmentationModal, () => true);
