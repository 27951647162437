import {
    PageContainer,
    ProCard,
    ProDescriptions,
    ProList,
    StatisticCard,
} from '@ant-design/pro-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Space,
    Button,
    message,
    Typography,
    Tag,
    Flex,
    Divider,
    Descriptions,
    Badge,
    Alert,
    Modal,
    Steps,
    Input,
    Radio,
    Table,
} from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    CopyOutlined,
    CloseOutlined,
    CheckOutlined,
    QuestionCircleOutlined,
    TableOutlined,
    UnorderedListOutlined,
    DownloadOutlined,
    FireFilled,
} from '@ant-design/icons';
import {
    AddProjVenueModule,
    CloneProjVenueModule,
    DeleteProjVenueModule,
} from 'components/dialogs/HandleProjVenueDialog';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from 'react-router-dom';
import { ResourceRole } from 'apis/UserApi';
import BaseMapEngine from 'services/BaseMapEngine';
import { useVenueState } from 'providers/VenueProvider';
import { updateVenue, VenueDetailDto, VenueMapMetaDto } from 'apis/VenueApi';
import { ViewDocumentModule } from 'components/dialogs/DocumentDialog';
import CachedImage from 'components/CachedImage';
import CustomIcon from 'components/CustomIcon';
const { Text } = Typography;
const mapEngine = new BaseMapEngine();

const exportVenueData = (data: VenueDetailDto) => {
    const csvData = [
        'venueId,venueName,mapId,name,level,coverage,width(m),height(m),width(px),height(px)',
    ];
    for (let i = 0; i < data.maps.length; i++) {
        const item = data.maps[i];
        const row = [
            data.id,
            data.name,
            item.id,
            item.name,
            item.level,
            item?.coverage ?? null,
            item?.mapAlign?.sizeInMeters?.width ?? null,
            item?.mapAlign?.sizeInMeters?.height ?? null,
            item?.mapAlign?.size?.width ?? null,
            item?.mapAlign?.size?.height ?? null,
        ];
        csvData.push(row.join(','));
    }
    const blob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${data.name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const VenueOverviewScreen: React.FC = () => {
    const { hasAccess, isSuperAdmin } = useAuth();
    const { fetchVenue, isLoading, venue } = useVenueState();
    const {
        activeProject,
        project,
        refreshProject,
        isMobile,
        isTablet,
        appSettings,
        saveAppSettings,
    } = useAppState();
    const navigate = useNavigate();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const isAdmin = hasAccess(ResourceRole.ADMIN, activeProject?.projId);
    const [modal, contextHolder] = Modal.useModal();
    const [keyword, setKeyword] = useState<string | undefined>(undefined);

    useEffect(() => {
        setKeyword(undefined);
    }, [venue]);

    function _handleEnabledVenue(enable: boolean, hasError: boolean) {
        if (!venue) return;
        modal
            .confirm({
                title: `Are you sure to ${enable ? 'enable' : 'disable'} ${venue.name}${hasError && enable ? ' anyway' : ''}?`,
            })
            .then(
                (confirmed) => {
                    if (confirmed) {
                        updateVenue(venue.id, {
                            projectId: venue.projectId,
                            name: venue.name,
                            center: venue.center,
                            enabled: enable,
                            id: venue.id,
                        }).then((resp) => {
                            fetchVenue(venue.id);
                            if (resp.response?.status === 200) {
                                message.success('Venue ' + enable ? 'enabled' : 'disabled');
                            }
                        });
                    }
                },
                () => {},
            );
    }

    const filteredMaps = useMemo(() => {
        if (!keyword) return venue?.maps ?? [];
        const mKeyword = keyword.toLowerCase();

        return (venue?.maps ?? []).filter((el) => {
            const fullText =
                el.name.toLowerCase().includes(mKeyword) ||
                el.id.toLowerCase().includes(mKeyword) ||
                `${el.level}`.includes(mKeyword);
            if (fullText) return true;

            let subFilter = false;
            let matched;
            if ((matched = mKeyword.match('survey=(true|false)'))) {
                const flag = matched[1] === 'true';
                if (!(el.coverage > 0 === flag)) return false;
                else subFilter = true;
            }
            if ((matched = mKeyword.match('beacon=(true|false)'))) {
                const flag = matched[1] === 'true';
                if (!(!!el.beaconPlanId === flag)) return false;
                else subFilter = true;
            }
            if ((matched = mKeyword.match('mask=(true|false)'))) {
                const flag = matched[1] === 'true';
                if (!(!!el.boundary === flag)) return false;
                else subFilter = true;
            }
            if ((matched = mKeyword.match('augment=(true|false)'))) {
                const flag = matched[1] === 'true';
                if (!(!!el.augmentId === flag)) return false;
                else subFilter = true;
            }
            return subFilter;
        });
    }, [venue?.maps, keyword]);

    const maxNameLength = useMemo(() => {
        if (!venue?.maps || venue.maps.length === 0) return 0;
        return Math.max(...venue.maps.map((el) => el.name.length));
    }, [venue?.maps]);

    const hasBeacon = useMemo(() => {
        if (!venue?.maps || venue.maps.length === 0) return false;
        return venue.maps.some((el) => !!el.beaconPlanId);
    }, [venue?.maps]);

    function _renderVenueMapList() {
        return (
            <ProList
                search={false}
                loading={isLoading}
                rowKey="id"
                itemLayout={isTablet ? 'vertical' : undefined}
                metas={{
                    title: { dataIndex: 'name' },
                    subTitle: {
                        dataIndex: 'level',
                        render: (dom, row) => <Text type="secondary">Level: {dom}</Text>,
                    },
                    description: {
                        dataIndex: 'id',
                        render: (dom, row) => (
                            <Text type="secondary" copyable={{ text: row.id }}>
                                ID: {dom}
                            </Text>
                        ),
                    },
                    actions: {
                        dataIndex: 'updatedAt',
                        render: (dom, row) => {
                            return [
                                <Space key={'k2-' + row.id}>
                                    {row.beaconPlanId ? (
                                        <Tag color="cyan" icon={<CheckOutlined />}>
                                            Beacon
                                        </Tag>
                                    ) : undefined}
                                </Space>,
                                <Space direction="vertical" align="end" key={'k1-' + row.id}>
                                    {!row.mapImg && row.boundary ? (
                                        <Tag color="purple">Polygon</Tag>
                                    ) : undefined}
                                </Space>,
                                <Space direction="vertical" align="end" key={'k0-' + row.id}>
                                    {!row.coverage || !row.mapAlign || !row.mapImg ? (
                                        <Space>
                                            {row.coverage > 0 ? undefined : (
                                                <Tag color="red" icon={<CloseOutlined />}>
                                                    Survey
                                                </Tag>
                                            )}
                                            {!row.mapAlign || !row.mapImg ? (
                                                <Tag color="red" icon={<CloseOutlined />}>
                                                    Floor Plan
                                                </Tag>
                                            ) : undefined}
                                        </Space>
                                    ) : undefined}
                                    {row.boundary ? undefined : (
                                        <Tag color="red" icon={<CloseOutlined />}>
                                            Floor Plan Mask
                                        </Tag>
                                    )}
                                </Space>,
                            ];
                        },
                    },
                }}
                dataSource={filteredMaps}
                rowHoverable
                rowClassName="cursor-pointer"
                onItem={(record, index) => {
                    return {
                        onClick: (event) => {
                            if (record.venueId) {
                                navigate(`/project/venue/${record.venueId}/map`, {
                                    state: { mapId: record.id },
                                });
                            }
                        },
                    };
                }}
            />
        );
    }

    function _renderVenueMapTable() {
        const checkCrossIcon = (flag: boolean) => (
            <center>
                {flag ? (
                    <CheckOutlined className="color-success" />
                ) : (
                    <CloseOutlined className="color-error" />
                )}
            </center>
        );
        return (
            <Table<VenueMapMetaDto>
                className="tiny-table"
                rowKey="id"
                rowClassName="cursor-pointer"
                size="small"
                loading={isLoading}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        filterSearch: true,
                        width: maxNameLength < 15 ? 280 : undefined,
                        render: (value, record, index) => (
                            <Text
                                title={value}
                                copyable={{ text: value }}
                                className="text-08"
                                style={{ fontFamily: 'monospace' }}
                                ellipsis
                            >
                                {value}
                            </Text>
                        ),
                        filters: filteredMaps.map((el) => ({ text: el.id, value: el.id })),
                        onFilter: (value, record) => record.id.includes(value as string),
                        sorter: (a, b) => a.id.localeCompare(b.id),
                    },
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        filterSearch: true,
                        filters: filteredMaps.map((el) => ({ text: el.name, value: el.name })),
                        onFilter: (value, record) => record.name.includes(value as string),
                        sorter: (a, b) => a.name.localeCompare(b.name),
                    },
                    {
                        title: 'Lv.',
                        dataIndex: 'level',
                        filterSearch: true,
                        filters: Array.from(new Set(filteredMaps.map((el) => el.level))).map(
                            (el) => ({
                                text: el,
                                value: el,
                            }),
                        ),
                        width: 1,
                        onFilter: (value, record) => record.name.includes(value as string),
                        sorter: (a, b) => a.level - b.level,
                    },
                    {
                        title: 'Survey',
                        dataIndex: 'coverage',
                        width: 70,
                        render: (value, record) =>
                            value ? (
                                <center>
                                    <Text className="color-success text-08">{`${value.toFixed(1)} ㎡`}</Text>
                                </center>
                            ) : (
                                checkCrossIcon(false)
                            ),
                        sorter: (a, b) => (a.coverage ?? -1) - (b.coverage ?? -1),
                    },
                    {
                        title: 'Beacon',
                        dataIndex: 'beaconPlanId',
                        filters: [
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                        ],
                        width: 1,
                        hidden: !hasBeacon,
                        render: (value, record) => checkCrossIcon(!!record.beaconPlanId),
                        onFilter: (value, record) => value === !!record.beaconPlanId,
                    },
                    {
                        title: '',
                        dataIndex: 'id',
                        width: 1,
                        render: (value, record) => (
                            <Space>
                                {!record.mapImg && record.boundary ? (
                                    <CustomIcon
                                        icon="polygon"
                                        size={18}
                                        title="Polygon available"
                                    />
                                ) : !record.mapAlign || !record.mapImg ? (
                                    <CustomIcon
                                        icon="map_err"
                                        color="yellow"
                                        size={18}
                                        title="No Floor Plan"
                                    />
                                ) : undefined}
                                {!record.boundary ? (
                                    <CustomIcon
                                        icon="polygon_err"
                                        color={record.coverage > 0 ? 'red' : 'yellow'}
                                        size={18}
                                        title="No Floor Plan Mask"
                                    />
                                ) : undefined}
                                {isSuperAdmin && record.augmentId ? (
                                    <FireFilled
                                        className="text-08"
                                        title="Augmented"
                                        style={{ color: 'orange' }}
                                    />
                                ) : undefined}
                            </Space>
                        ),
                    },
                ]}
                dataSource={filteredMaps}
                onRow={(record, index) => {
                    return {
                        onClick: (event) => {
                            if (record.venueId) {
                                navigate(`/project/venue/${record.venueId}/map`, {
                                    state: { mapId: record.id },
                                });
                            }
                        },
                    };
                }}
                pagination={false}
            />
        );
    }

    function _renderInfoArea() {
        if (!venue) return <></>;
        return (
            <>
                <ProCard ghost>
                    <ProDescriptions column={1} layout="vertical" dataSource={venue}>
                        <ProDescriptions.Item label="Name" dataIndex="name" />
                        <ProDescriptions.Item label="ID" dataIndex="id" copyable />
                        {venue?.extId ? (
                            <ProDescriptions.Item label="Ext. ID" dataIndex="extId" copyable />
                        ) : undefined}
                        <ProDescriptions.Item label="Center">
                            {venue.center.lat + ',' + venue.center.lng}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item>
                            <CachedImage
                                style={{ width: '100%', height: 180, objectFit: 'cover' }}
                                alt="Map"
                                src={mapEngine.getStaticImageUrl(
                                    venue.center.lat,
                                    venue.center.lng,
                                )}
                            />
                        </ProDescriptions.Item>
                    </ProDescriptions>
                </ProCard>
                <Divider />
                <ProCard ghost>
                    <Flex justify="space-between">
                        {isEditor && project ? (
                            <Space>
                                <AddProjVenueModule
                                    key="btn-edit-venue"
                                    projectId={project.id}
                                    isEdit={true}
                                    editingVenue={venue}
                                    trigger={<Button icon={<EditOutlined />}>Edit</Button>}
                                    onSuccess={() => {
                                        refreshProject(activeProject?.projId);
                                        fetchVenue(venue.id);
                                        message.success('Venue updated');
                                    }}
                                />
                                <CloneProjVenueModule
                                    key="btn-clone-venue"
                                    processingProjectId={project.id}
                                    cloningVenue={venue}
                                    trigger={<Button icon={<CopyOutlined />}>Copy</Button>}
                                    onSuccess={() => {
                                        refreshProject(activeProject?.projId);
                                        fetchVenue(venue.id);
                                        message.success('Venue copied');
                                    }}
                                />
                            </Space>
                        ) : undefined}
                        {isAdmin ? (
                            <DeleteProjVenueModule
                                key="btn-delete-venue"
                                processingVenue={venue}
                                onSuccess={() => {
                                    refreshProject(activeProject?.projId);
                                    message.success('Venue deleted');
                                    navigate('/project/venue', { replace: true });
                                }}
                                trigger={
                                    <Button danger icon={<DeleteOutlined />}>
                                        Delete
                                    </Button>
                                }
                            />
                        ) : undefined}
                    </Flex>
                </ProCard>
            </>
        );
    }

    function _renderEnableAlert(pendingItem?: string[]) {
        if (!venue || !pendingItem || (venue?.enabled && pendingItem.length === 0))
            return undefined;
        return (
            <>
                {venue.enabled ? undefined : (
                    <Alert
                        message="Venue is not enabled."
                        description="This venue is only available for the client SDK if it is enabled."
                        type="warning"
                        style={{ marginBottom: 12 }}
                        showIcon
                        action={
                            isEditor ? (
                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() =>
                                        _handleEnabledVenue(!venue?.enabled, pendingItem.length > 0)
                                    }
                                >
                                    {venue?.enabled ? 'Disable' : 'Enable'}
                                </Button>
                            ) : undefined
                        }
                    />
                )}
                {pendingItem.length > 0 ? (
                    <Alert
                        message="Venue is not setup properly."
                        description={
                            <div className="small-ul-wrapper">
                                Some issues were detected. Please ensure the venue is properly set
                                up:
                                <ul>
                                    {pendingItem.map((el, idx) => (
                                        <li key={`err-${idx}`}>{el}</li>
                                    ))}
                                </ul>
                            </div>
                        }
                        type="error"
                        style={{ marginBottom: 12 }}
                        showIcon
                        action={
                            isEditor && venue.enabled ? (
                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() => _handleEnabledVenue(false, true)}
                                >
                                    Disable
                                </Button>
                            ) : undefined
                        }
                    />
                ) : undefined}
            </>
        );
    }

    const _renderHelp = useCallback(() => {
        return <ViewDocumentModule trigger={<QuestionCircleOutlined />} docName="integration.md" />;
    }, []);

    const { notCompleted, status } = useMemo(() => {
        if (!venue) return { notCompleted: undefined, status: 0 };
        const mPending: string[] = [];
        const missingPackage = !venue?.publish;
        const missingMapAlign = venue?.maps.some((el) => !el.mapAlign || !el.mapImg);
        const missingMask = venue?.maps.some((el) => !el.boundary?.length);
        const missingSurvey = venue?.maps.some((el) => !el.coverage);

        if (missingPackage) mPending.push('No site package is available.');
        if (missingMapAlign) mPending.push('Some map images are not ready.');
        if (missingMask) mPending.push('Some boundary masks are missing.');
        if (missingSurvey) mPending.push('The survey is incomplete.');

        let mStatus = 0;
        if (mPending.length === 0) {
            mStatus = 2;
        } else if (!missingMapAlign) {
            mStatus = 1;
        }
        return { notCompleted: mPending, status: mStatus };
    }, [venue]);

    const mMapCount = (venue?.maps ?? []).length;
    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            {contextHolder}
            <ProCard
                ghost
                gutter={8}
                bodyStyle={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}
            >
                <ProCard ghost split="horizontal">
                    {_renderEnableAlert(notCompleted)}
                    <ProCard
                        bordered
                        title={`Maps (${!!keyword && keyword.length > 0 ? filteredMaps.length + '/' : ''}${mMapCount})`}
                        headStyle={{ paddingBottom: 0 }}
                        extra={
                            mMapCount > 0 ? (
                                <Space key="map-filter-gp">
                                    <Input.Search
                                        key="map-search-box"
                                        size="small"
                                        allowClear
                                        onSearch={(val) => setKeyword(val)}
                                        placeholder="Search a map"
                                    />
                                    <Radio.Group
                                        defaultValue="view_ls"
                                        key="view-radio"
                                        size="small"
                                        value={appSettings.mapTbMode ? 'view_tb' : 'view_ls'}
                                        onChange={(e) =>
                                            saveAppSettings({
                                                mapTbMode: e.target.value === 'view_tb',
                                            })
                                        }
                                    >
                                        <Radio.Button value="view_ls" title="List view">
                                            <UnorderedListOutlined />
                                        </Radio.Button>
                                        <Radio.Button value="view_tb" title="Table view">
                                            <TableOutlined />
                                        </Radio.Button>
                                    </Radio.Group>
                                </Space>
                            ) : undefined
                        }
                        actions={
                            venue && mMapCount > 0 ? (
                                <center>
                                    <Button
                                        style={{ color: 'grey' }}
                                        type="text"
                                        size="small"
                                        className="text-08"
                                        icon={<DownloadOutlined />}
                                        onClick={() => exportVenueData(venue)}
                                    >
                                        Export venue overview (CSV)
                                    </Button>
                                </center>
                            ) : undefined
                        }
                    >
                        {appSettings.mapTbMode ? _renderVenueMapTable() : _renderVenueMapList()}
                    </ProCard>
                </ProCard>
                <ProCard colSpan={isMobile ? undefined : 10} ghost direction="column">
                    <ProCard ghost>
                        <Flex justify="space-around" gap="small">
                            <StatisticCard
                                statistic={{
                                    title: 'Site engagement',
                                    value: venue?.publish?.count ?? '-',
                                    tip: 'Site package download count',
                                    description: venue?.publish ? (
                                        <Descriptions>
                                            <Descriptions.Item label="Ver." style={{ padding: 0 }}>
                                                {venue?.publish?.version ?? '-'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    ) : (
                                        <Badge status="error" text="No site package" />
                                    ),
                                }}
                                loading={isLoading}
                            />
                            <StatisticCard
                                statistic={{
                                    title: (
                                        <Space>
                                            <span>Status</span>
                                            {_renderHelp()}
                                        </Space>
                                    ),
                                    value: ['Setup Venue', 'Ready to survey', 'Build your app'][
                                        status
                                    ],
                                    description: isTablet ? undefined : (
                                        <Steps size="small" current={status} items={[{}, {}, {}]} />
                                    ),
                                }}
                                loading={isLoading}
                            />
                        </Flex>
                    </ProCard>
                    <ProCard bordered style={{ margin: '8px 0' }}>
                        {_renderInfoArea()}
                    </ProCard>
                </ProCard>
            </ProCard>
        </PageContainer>
    );
};

export default VenueOverviewScreen;
