import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
    Button,
    Empty,
    Flex,
    InputNumber,
    message,
    Skeleton,
    Space,
    Steps,
    Typography,
} from 'antd';
import { ModalForm } from '@ant-design/pro-components';
import { CaretRightOutlined } from '@ant-design/icons';
import { requestAugmentation } from 'apis/VenueApi';
import { useVenueState } from 'providers/VenueProvider';
import { BaseDialogProps } from './BaseDialogProps.type';
import PathSelector from 'components/PathSelector';
import FloorPlanMapView from 'components/maps/FloorPlanMapView';
import SurveyPathLayer from 'components/maps/SurveyPathLayer';

const AugmentCreateModule: React.FC<BaseDialogProps> = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { workingMap, surveySessions } = useVenueState();
    // form input
    const bSizeInputRef = useRef<HTMLInputElement>();
    const [checkedPaths, setCheckedPaths] = useState<string[]>([]);
    const [selectedPath, setSelectedPath] = useState<string>();
    const [mapVisible, setMapVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [moduleVisible, setModuleVisible] = useState<boolean>(false);

    if (!workingMap?.mapAlign || !surveySessions?.length) {
        return <Empty style={{ margin: 36 }} description="No survey data available" />;
    }

    const handleOnClose = useCallback((flag: boolean) => {
        setModuleVisible(false);
        if (props.onSuccess) props.onSuccess(flag);
        setMapVisible(false);
        setCheckedPaths([]);
        setSelectedPath(undefined);
    }, []);

    const handleGenerate = useCallback(() => {
        if (!workingMap?.id) return;
        const inputBSize = bSizeInputRef.current?.value;
        const mBlockSize = isNaN(Number(inputBSize)) ? undefined : Number(inputBSize);
        setIsLoading(true);
        requestAugmentation(workingMap.id, {
            blockSize: mBlockSize ?? 3,
            denseIds: checkedPaths ?? [],
        })
            .then((resp) => {
                messageApi.success('Augmentation request sent.');
                handleOnClose(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [workingMap, bSizeInputRef, checkedPaths, requestAugmentation, setIsLoading]);

    const _renderBlockInput = useCallback(() => {
        return (
            <InputNumber
                ref={bSizeInputRef as any}
                min={1}
                max={10}
                defaultValue={3}
                placeholder="default = 3"
                required
                size="small"
            />
        );
    }, [bSizeInputRef]);

    const _renderSelector = () => {
        return (
            <PathSelector
                surveySessions={surveySessions ?? []}
                checkedPaths={checkedPaths}
                selectedPath={selectedPath}
                onPathChecked={setCheckedPaths}
                onPathSelected={setSelectedPath}
                checkMode="PROJ"
            />
        );
    };

    const stepItems = useMemo(() => {
        return [
            {
                title: (
                    <Space>
                        <span>(Optional) Define block size</span>
                        <InputNumber
                            ref={bSizeInputRef as any}
                            min={1}
                            max={10}
                            defaultValue={3}
                            placeholder="default = 3"
                            required
                            size="small"
                        />
                        <span>(default = 3)</span>
                    </Space>
                ),
            },
            {
                title: '(Optional) Select dense survey',
                description: _renderSelector(),
            },
            {
                title: 'Generate from current revision',
                description: (
                    <div>
                        <span style={{ display: 'block', paddingBottom: 6 }} className="text-08">
                            This process typically takes approx an hour to complete. You can view
                            the status of this process in augmentation settings.
                        </span>
                        <Button
                            shape="round"
                            type="primary"
                            icon={<CaretRightOutlined />}
                            onClick={() => handleGenerate()}
                            loading={isLoading}
                        >
                            Generate
                        </Button>
                    </div>
                ),
            },
        ];
    }, [workingMap, _renderBlockInput, _renderSelector, handleGenerate, isLoading]);

    const whitelistPaths = useMemo(() => {
        return (
            surveySessions
                .filter((el) => checkedPaths.includes(el.id))
                .flatMap((el) => el.paths.map((p) => p.name)) ?? []
        );
    }, [checkedPaths, surveySessions]);

    const _renderMap = () => {
        return (
            <FloorPlanMapView
                imageUrl={workingMap.mapImg}
                mapAlign={workingMap.mapAlign}
                initCenter={workingMap.mapAlign!.center}
                layerControl={{ initTier: 'CartoDB.Positron', visible: false }}
                grayscale={true}
                style={{ position: 'relative' }}
            >
                <SurveyPathLayer
                    data={surveySessions}
                    whitelist={whitelistPaths}
                    onPathClick={setSelectedPath}
                    selectedName={selectedPath}
                    showWaypoint={false}
                    showArrow={false}
                    showPath={true}
                />
            </FloorPlanMapView>
        );
    };

    const _buildModuleBody = () => {
        return (
            <Flex style={{ overflow: 'hidden' }} className="h100">
                <Flex
                    className="venue-panel"
                    style={{
                        width: '40%',
                        minWidth: 450,
                        zIndex: 2,
                    }}
                    vertical
                >
                    <Typography.Text type="secondary">
                        Enhance your WiFi fingerprint coverage and accuracy from current survey
                        revision.
                    </Typography.Text>
                    <Steps
                        direction="vertical"
                        style={{ marginTop: 24, paddingRight: 12 }}
                        current={-1}
                        size="small"
                        items={stepItems}
                    />
                </Flex>
                <Flex
                    className="w100 h100"
                    style={{ position: 'relative', backgroundColor: '#f7f7f7' }}
                >
                    {mapVisible ? _renderMap() : <Skeleton style={{ margin: 24 }} active />}
                </Flex>
            </Flex>
        );
    };

    return (
        <>
            {contextHolder}
            <ModalForm
                title="Generate Augmented Fingerprint"
                trigger={props.trigger}
                width="98vw"
                modalProps={{
                    style: { top: 12 },
                    destroyOnClose: true,
                    maskClosable: false,
                    keyboard: false,
                    zIndex: 1000,
                    onCancel: (e) => handleOnClose(false),
                }}
                submitter={false}
                open={moduleVisible}
                onOpenChange={(visible) => {
                    setModuleVisible(visible);
                    if (visible) {
                        setTimeout(() => setMapVisible(true), 500);
                    }
                }}
            >
                <div style={{ height: `${window.innerHeight - 120}px` }}>{_buildModuleBody()}</div>
            </ModalForm>
        </>
    );
};

export default React.memo(AugmentCreateModule, () => true);
