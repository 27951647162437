import React, { useImperativeHandle, useState } from 'react';
import { Drawer, Flex, Form, Typography } from 'antd';
import { ProForm, ProFormCheckbox, ProFormDependency } from '@ant-design/pro-components';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { JobBatchParmaDto } from 'apis/VenueApi';

export type BuildOptionDrawerRef = {
    open: () => void;
};

export type BuildOptionDrawerProps = {
    onClosed?: (result?: JobBatchParmaDto) => void;
};

const BuildOptionDrawer = React.forwardRef<BuildOptionDrawerRef, BuildOptionDrawerProps>(
    (props, ref) => {
        const { isSuperAdmin } = useAuth();
        if (!isSuperAdmin) return <></>;

        const [paramForm] = Form.useForm();
        const { isTablet } = useAppState();
        const [isOpen, setIsOpen] = useState<boolean>();

        useImperativeHandle(ref, () => ({
            open() {
                if (paramForm) paramForm.resetFields();
                setIsOpen(true);
                return;
            },
        }));

        const onClose = (result?: JobBatchParmaDto) => {
            if (props.onClosed) props.onClosed(result);
            setIsOpen(false);
        };

        const _renderSwitchTier = (
            name: string,
            title: string,
            desc: string,
            enabled: boolean = true,
        ) => {
            return (
                <ProForm.Item
                    style={{
                        borderTop: '1px #f7f7f7 solid',
                        paddingTop: 12,
                    }}
                >
                    <Flex align="center" gap={12}>
                        <ProFormCheckbox noStyle name={name} disabled={!enabled} />
                        <Typography.Text style={{ color: enabled ? 'black' : 'grey' }}>
                            {title}
                        </Typography.Text>
                    </Flex>
                    <Typography.Text type="secondary" className="text-08">
                        {desc}
                    </Typography.Text>
                </ProForm.Item>
            );
        };

        return (
            <Drawer
                title="Custom Build"
                onClose={() => onClose()}
                open={isOpen}
                width={isTablet ? '70%' : '50%'}
            >
                <Typography.Paragraph>
                    This custom build option allows super admin to tailor their build process to
                    their specific needs.
                </Typography.Paragraph>
                <ProForm<JobBatchParmaDto>
                    className="tiny-row"
                    layout="horizontal"
                    form={paramForm}
                    readonly={false}
                    onFinish={async (values) => {
                        onClose(values);
                        return true;
                    }}
                    submitter={{
                        searchConfig: { submitText: 'Build' },
                        resetButtonProps: false,
                    }}
                    initialValues={{
                        updateFp: true,
                        minimal: true,
                        genBeacon: true,
                        clean: false,
                    }}
                >
                    {_renderSwitchTier(
                        'updateFp',
                        'Generate fingerprint',
                        'Generate a fingerprint from the current survey revision.\nOtherwise, published site package is used and modify the configuration only, including floor plan alignment, floor plan mask, beacon plan, and package configuration.',
                    )}
                    {_renderSwitchTier(
                        'minimal',
                        'Minimal',
                        'Keep necessary fingerprint data only.',
                    )}
                    <ProFormDependency name={['updateFp']}>
                        {({ updateFp }) => {
                            if (updateFp === false) {
                                setTimeout(() => {
                                    if (paramForm) {
                                        paramForm.setFieldValue('genBeacon', false);
                                        paramForm.setFieldValue('clean', false);
                                    }
                                }, 100);
                                return <></>;
                            }
                            return (
                                <>
                                    {_renderSwitchTier(
                                        'genBeacon',
                                        'Generate beacon table',
                                        "Generate beacon table from survey data.\n(Portal's beacon plan might used if it is available)",
                                        updateFp === true,
                                    )}
                                    {_renderSwitchTier(
                                        'augment',
                                        'Augmented fingerprint',
                                        'Merge augmented WiFi fingerprint if available',
                                        updateFp === true,
                                    )}
                                    {_renderSwitchTier(
                                        'clean',
                                        'Clean build',
                                        'Build from scratch. The pipeline will not take reference to the published site package.',
                                        updateFp === true,
                                    )}
                                </>
                            );
                        }}
                    </ProFormDependency>
                </ProForm>
            </Drawer>
        );
    },
);

export default BuildOptionDrawer;
