import {
    PageContainer,
    ProCard,
    ProFormDateTimeRangePicker,
    ProForm,
    ProFormSelect,
} from '@ant-design/pro-components';
import { Form, Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { Tag, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/lib';
import { useAuth } from 'providers/AuthProvider';
import {
    CrashEnquiryDto,
    CrashRecordDto,
    getCrashDlUrl,
    PaginationDto,
    searchCrashLog,
} from 'apis/TraceApi';
import moment from 'moment';
import { useAppState } from 'providers/AppStateProvider';
import Markdown from 'react-markdown';

const CrashSearchScreen: React.FC = () => {
    const { isMobile, project } = useAppState();
    const { isSuperAdmin } = useAuth();
    const [searchForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [pageConfig, setPageConfig] = useState<PaginationDto>({ total: 0, totalPage: 0 });
    const [records, setRecords] = useState<CrashRecordDto[]>([]);
    const [crashFilter, setCrashFilter] = useState<CrashEnquiryDto | undefined>(undefined);
    const mSuggestAppId = (project?.licenses ?? [])
        .reduce<string[]>((acc, curr) => acc.concat(curr.whitelist ?? []), [])
        .filter((el) => el !== '*')
        .map((x) => ({ value: x }));

    useEffect(() => {
        if (!!crashFilter) {
            setIsLoading(true);
            searchCrashLog(crashFilter)
                .then((resp) => {
                    setRecords(resp.data ?? []);
                    setPageConfig(resp.response?.data._metadata?.pagination);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [crashFilter]);

    function _handleQueryChange(paging?: { limit?: number; page?: number }) {
        const mLimit = paging?.limit ?? 20;
        const mPage = paging?.page ?? 0;
        const inAppId = searchForm.getFieldValue('appId')[0];
        const inDate = searchForm.getFieldValue('date');
        const mFilter: CrashEnquiryDto = { limit: mLimit, page: mPage, appId: inAppId };
        if (inDate?.length == 2) {
            mFilter.from = new Date(inDate[0]).getTime();
            mFilter.to = new Date(inDate[1]).getTime();
        }
        setCrashFilter(mFilter);
    }

    function _handleDownload(id: string) {
        setIsLoading(true);
        getCrashDlUrl(id)
            .then((resp) => {
                const mDlUrl = resp.data?.dlUrl;
                if (mDlUrl) window.open(mDlUrl, '_blank');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    if (!isSuperAdmin) return <Result status="warning" title="Permission denied" />;

    const columns: TableProps<CrashRecordDto>['columns'] = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 'auto',
            render: (field, entity, index) => {
                return moment(entity.date).format('YYYY-MM-DD HH:mm Z');
            },
        },
        {
            title: 'App Id',
            dataIndex: 'appId',
            key: 'appId',
            width: 'auto',
            render: (field, _, index) => <Tag key={`ta-${index}`}>{field}</Tag>,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            key: 'detail',
            render: (field, entity, index) => <Markdown className="p-no-margin">{field}</Markdown>,
        },
        {
            dataIndex: 'id',
            key: 'id',
            render: (field, entity, index) => (
                <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    title="Download"
                    onClick={() => _handleDownload(entity.id)}
                />
            ),
        },
    ];

    return (
        <PageContainer title="Crash Reports">
            <ProCard>
                <ProForm
                    form={searchForm}
                    autoFocus={false}
                    submitter={{
                        searchConfig: {
                            submitText: 'Query',
                            resetText: 'Reset',
                        },
                    }}
                    onFinish={(values) =>
                        _handleQueryChange({ page: 0, limit: crashFilter?.limit ?? 20 })
                    }
                >
                    <ProFormSelect
                        width="lg"
                        name="appId"
                        label="App ID"
                        rules={[{ required: true }]}
                        mode="tags"
                        options={mSuggestAppId}
                        fieldProps={{ maxCount: 1 }}
                    />
                    <ProFormDateTimeRangePicker width="lg" name="date" label="Date time" />
                </ProForm>
            </ProCard>
            <ProCard style={{ marginTop: '1em' }} ghost={isMobile}>
                <Table
                    loading={isLoading}
                    rowKey="id"
                    columns={columns}
                    dataSource={records}
                    pagination={{
                        defaultCurrent: (crashFilter?.page ?? 0) + 1,
                        defaultPageSize: 20,
                        total: pageConfig?.total ?? 1,
                        current: (crashFilter?.page ?? 0) + 1,
                        showTotal: (total) => `Total ${total} items`,
                        onChange: (page, size) => {
                            _handleQueryChange({ limit: size, page: page - 1 });
                        },
                    }}
                    size="small"
                />
            </ProCard>
        </PageContainer>
    );
};

export default CrashSearchScreen;
