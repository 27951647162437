import { ModalForm, ProForm, ProFormDigit, ProFormText } from '@ant-design/pro-components';
import { Form, Modal, message, Upload, Col, Flex, Card, Space } from 'antd';
import { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
    VenueMapCreateDto,
    VenueMapDto,
    createVenueMap,
    updateVenueMap,
    deleteVenueMap,
} from 'apis/VenueApi';
import type { GetProp, UploadProps } from 'antd';
import { BaseDialogProps } from './BaseDialogProps.type';
import NetworkManager from 'utils/NetworkManager';
import { useAuth } from 'providers/AuthProvider';

export type AddVenueMapProp = BaseDialogProps & {
    projectId: string;
    venueId: string;
    isEdit: boolean;
    editingVenueMap: VenueMapDto;
};
export const AddProjVenueMapModule: React.FC<AddVenueMapProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [venueMapForm] = Form.useForm();
    const isEdit = props.isEdit;
    const [imageUrl, setImageUrl] = useState<string>();
    const [imageSize, setImageSize] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [pendingImageInfo, setPendingImageInfo] = useState<any>(undefined);
    const editingVenueMap = props.editingVenueMap;
    const { isSuperAdmin } = useAuth();
    const [idTrigger, setIdTrigger] = useState<number>(0);
    const allowIdInput = isSuperAdmin && !props.isEdit;

    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{isLoading ? 'Loading' : 'Upload'}</div>
        </button>
    );

    const beforeUpload = () => {
        return false;
    };

    const handleChange: UploadProps['onChange'] = (info) => {
        setImageUrl(undefined);
        setIsLoading(true);
        //showPreview(info);

        const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Only JPG/PNG file is accepted');
            return;
        }

        if (info.fileList[info.fileList.length - 1].originFileObj) {
            getBase64(info.fileList[info.fileList.length - 1].originFileObj as FileType, (url) => {
                setIsLoading(false);
                setImageUrl(url);
                setPendingImageInfo(info.fileList[info.fileList.length - 1]);
            });
        }
    };

    const getBase64 = (img: FileType, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const _handleOnLoad = (event: any) => {
        setImageSize(`${event.target.naturalWidth}×${event.target.naturalHeight}`);
    };

    return (
        <ModalForm
            form={venueMapForm}
            title={
                <span onClick={allowIdInput ? () => setIdTrigger((x) => x + 1) : undefined}>
                    {isEdit ? 'Edit map' : 'Add map'}
                </span>
            }
            trigger={props.trigger}
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                venueMapForm.resetFields();
                if (isOpen) {
                    venueMapForm.setFieldsValue(props.editingVenueMap ?? {});
                    setImageUrl(undefined);
                    setPendingImageInfo(undefined);
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);
                let result = false;

                if (!isEdit) {
                    const mapImageFileName = pendingImageInfo ? pendingImageInfo.name : undefined;

                    const mData: VenueMapCreateDto = {
                        venueId: props.venueId,
                        name: values.name,
                        level: Number(values.level),
                        mapImg: mapImageFileName,
                        mapAlign: undefined,
                    };
                    if (allowIdInput && !!values.id) mData.id = values.id;
                    try {
                        const resp = await createVenueMap(props.venueId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            result = true;
                            if (resp.response.data.data && resp.response.data.data.uploadUrl) {
                                try {
                                    message.info('Uploading map image');
                                    await NetworkManager.putFile(
                                        resp.response.data.data.uploadUrl,
                                        pendingImageInfo.originFileObj as File,
                                    );
                                    if (props.onSuccess) props.onSuccess();
                                    setFormLoading(false);
                                    return result;
                                } catch (ex: any) {
                                    message.error('upload image error');
                                }
                            } else {
                                if (props.onSuccess) props.onSuccess();
                                setFormLoading(false);
                                return result;
                            }
                        }
                    } catch (ex: any) {
                        message.error('error');
                    }
                } else {
                    const mapImageFileName = pendingImageInfo
                        ? pendingImageInfo.name
                        : editingVenueMap.mapImg;

                    const mData: VenueMapDto = {
                        venueId: props.venueId,
                        name: values.name,
                        level: Number(values.level),
                        mapImg: mapImageFileName,
                        mapAlign: editingVenueMap.mapAlign,
                        id: editingVenueMap.id,
                    };
                    try {
                        const resp = await updateVenueMap(props.venueId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            result = true;
                            if (resp.response.data.data && resp.response.data.data.uploadUrl) {
                                try {
                                    message.info('Uploading map image');
                                    await NetworkManager.putFile(
                                        resp.response.data.data.uploadUrl,
                                        pendingImageInfo.originFileObj as File,
                                    );
                                    if (props.onSuccess) props.onSuccess();
                                    setFormLoading(false);
                                    return result;
                                } catch (ex: any) {
                                    message.error('upload image error');
                                }
                            } else {
                                if (props.onSuccess) props.onSuccess();
                                setFormLoading(false);
                                return result;
                            }
                        }
                    } catch (ex: any) {
                        message.error('error');
                    }
                }
            }}
        >
            {allowIdInput && idTrigger > 3 ? (
                <ProForm.Group>
                    <ProFormText
                        label="ID"
                        name="id"
                        width="lg"
                        tooltip="Please proceed with caution as setting custom map ID."
                        placeholder="(Optional) Custom map ID"
                    />
                </ProForm.Group>
            ) : undefined}
            <ProForm.Group>
                <ProFormText
                    label="Name"
                    name="name"
                    placeholder="Map name"
                    width="md"
                    rules={[{ required: true }]}
                />
                <ProFormDigit
                    label="Level"
                    name="level"
                    tooltip="Apple Core Location floor number"
                    placeholder="Floor level"
                    width="md"
                    rules={[{ required: true }]}
                    min={-99999}
                    max={99999}
                    fieldProps={{ precision: 0 }}
                />
            </ProForm.Group>

            <Form.Item label="Floor plan image">
                <Flex gap="small">
                    <Col>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="w100"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            fileList={[]}
                            onChange={handleChange}
                            accept="image/*"
                        >
                            {isEdit && !imageUrl && editingVenueMap.mapImg ? (
                                <img
                                    onLoad={_handleOnLoad}
                                    src={editingVenueMap.mapImg}
                                    style={{ width: '100%' }}
                                />
                            ) : imageUrl ? (
                                <img src={imageUrl} style={{ width: '100%' }} />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Col>
                    <Col>
                        <Card
                            style={{ backgroundColor: '#eee' }}
                            styles={{
                                body: { padding: '8px 16px' },
                            }}
                        >
                            <Space>
                                <InfoCircleOutlined /> Note
                            </Space>
                            {isEdit && editingVenueMap.mapImg ? (
                                <p>
                                    To replace floor plan image, the image must be exactly{' '}
                                    {imageSize} pixels in size and needs to be in exactly the same
                                    alignment and scale as the original image.
                                    <br />
                                    You can align the floor plan image using alignment tools later.
                                </p>
                            ) : (
                                <p>
                                    The image size should be 1 pixel = 0.1 meter.
                                    <br />
                                    You can align the floor plan image using alignment tools later.
                                </p>
                            )}
                        </Card>
                    </Col>
                </Flex>
            </Form.Item>
        </ModalForm>
    );
};

export type DeleteVenueMapProp = BaseDialogProps & {
    processingVenueMap: any;
};

export const DeleteProjVenueMapModule: React.FC<DeleteVenueMapProp> = (props) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const confirmDelete = async () => {
        setConfirmLoading(true);

        try {
            const resp = await deleteVenueMap(
                props.processingVenueMap.venueId,
                props.processingVenueMap.id,
            );
            if (resp.response?.status === 200 || resp.response?.status === 201) {
                if (props.onSuccess) props.onSuccess();
            }
        } catch (ex: any) {}

        setOpen(false);
        setConfirmLoading(false);
    };

    const cancelDelete = () => {
        setOpen(false);
    };
    return (
        <>
            {props.trigger ? <div onClick={() => setOpen(true)}>{props.trigger}</div> : undefined}
            <Modal
                title={'Delete ' + props.processingVenueMap.name}
                open={open}
                onOk={confirmDelete}
                okButtonProps={{ danger: true }}
                confirmLoading={confirmLoading}
                onCancel={cancelDelete}
            >
                <p>
                    Are you sure to delete <b>{props.processingVenueMap.name}</b> ?
                </p>
            </Modal>
        </>
    );
};
