import { PageContainer, ProList } from '@ant-design/pro-components';
import React, { useEffect, useRef, useState } from 'react';
import { Space, Tag, Button, Badge, Descriptions, Result, Modal } from 'antd';
import { getSourcedVenues, SourcedVenueDto } from 'apis/CrowdApi';
import moment from 'moment';
import { useAuth } from 'providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import LocationPicker from 'components/maps/LocationPicker';
import SourceTrainingDrawer, {
    SourceTrainingDrawerRef,
} from 'components/dialogs/SourceTrainingDrawer';

const CrowdSourceScreen: React.FC = () => {
    const { isSuperAdmin } = useAuth();
    if (!isSuperAdmin) return <Result status="warning" title="Permission denied" />;
    const [isLoading, setIsLoading] = useState<boolean>();
    const counterRef = useRef<{ total: number; isNew: number; trained: number }>({
        total: 0,
        isNew: 0,
        trained: 0,
    });
    const [sourcedVenues, setSourcedVenues] = useState<SourcedVenueDto[]>();
    const [filterStatus, setFilterStatus] = useState<React.Key | undefined>('ALL');
    const [filterKeyword, setFilterKeyword] = useState<string | undefined>();
    const [mapPreview, setMapPreview] = useState<SourcedVenueDto | undefined>();
    const trainingReqRef = useRef<SourceTrainingDrawerRef>();
    const navigate = useNavigate();
    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        _refreshData();
    }, []);
    const _refreshData = () => {
        setIsLoading(true);
        getSourcedVenues()
            .then((resp) => {
                const mData = resp.data ?? [];
                let isNewSource = 0,
                    trainedSource = 0;
                for (let i = 0; i < mData.length; i++) {
                    if (mData[i].isNew) isNewSource++;
                    if (!!mData[i].source?.lastSourceAt) trainedSource++;
                }
                counterRef.current = {
                    total: mData.length,
                    isNew: isNewSource,
                    trained: trainedSource,
                };
                setSourcedVenues(resp.data);
            })
            .catch((e) => {
                counterRef.current = { total: 0, isNew: 0, trained: 0 };
                console.error(e);
            })
            .finally(() => setIsLoading(false));
    };

    const handleStartTraining = (bVenue: SourcedVenueDto) => {
        if (trainingReqRef.current) {
            trainingReqRef.current.open(bVenue);
        }
    };

    const renderBadge = (count: number, active = false) => {
        return (
            <Badge
                count={count}
                overflowCount={999}
                style={{
                    marginBlockStart: -2,
                    marginInlineStart: 4,
                    color: active ? '#1890FF' : '#999',
                    backgroundColor: active ? '#E6F7FF' : '#eee',
                }}
            />
        );
    };

    const _renderMapPreview = () => {
        return (
            <Modal
                title={mapPreview?.name ?? '-'}
                open={!!mapPreview}
                onCancel={() => setMapPreview(undefined)}
                footer={false}
                width={800}
            >
                <div style={{ width: '100%', height: 300 }}>
                    {mapPreview?.center ? (
                        <LocationPicker key="crowd-map" center={mapPreview.center} editable={false} />
                    ) : undefined}
                </div>
            </Modal>
        );
    };

    return (
        <PageContainer title="Sourced venues">
            {contextHolder}
            <ProList<SourcedVenueDto>
                search={false}
                rowKey="id"
                headerTitle="Crowd-sourced venues"
                pagination={false}
                showActions="hover"
                loading={isLoading}
                metas={{
                    title: {
                        dataIndex: 'name',
                        title: 'Name',
                        search: true,
                        render: (schema, row, index) => {
                            const venueSetup = row.source?.venueId !== undefined;
                            return (
                                <span
                                    style={{ color: venueSetup ? 'black' : 'darkgrey' }}
                                    onClick={() => {
                                        if (venueSetup) {
                                            navigate(`/project/venue/${row.source?.venueId}`);
                                        } else {
                                            setMapPreview(row);
                                        }
                                    }}
                                >
                                    {row.name}
                                </span>
                            );
                        },
                    },
                    subTitle: {
                        dataIndex: 'center',
                        render: (schema, row, index) => {
                            return (
                                <Space>
                                    {row.isNew ? (
                                        <Tag
                                            key={`${index}-is-new`}
                                            color="orange"
                                            title={`Last session at: ${row.lastEndAt ? moment(row.lastEndAt).format('YYYY-MM-DD HH:mm') : '-'}`}
                                        >
                                            New
                                        </Tag>
                                    ) : undefined}
                                    <Badge
                                        count={row.count}
                                        style={{ backgroundColor: '#52c41a' }}
                                        title={`${row.count} sessions`}
                                    />
                                </Space>
                            );
                        },
                        search: false,
                    },
                    description: {
                        dataIndex: 'source',
                        render: (schema, row, index) => {
                            if (!row.source) return undefined;
                            const isFinished =
                                row.source?.status === 'FAIL' || row.source?.status === 'DONE';
                            return (
                                <Descriptions style={{ width: '100%' }} column={2}>
                                    <Descriptions.Item label="Last build" style={{ padding: 0 }}>
                                        {moment(row.source.lastSourceAt).format('YYYY-MM-DD HH:mm')}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Status" style={{ padding: 0 }}>
                                        <Badge
                                            status={isFinished ? 'default' : 'processing'}
                                            text={row.source.status}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                            );
                        },
                    },
                    actions: {
                        render: (text, row, index) => {
                            const enabled =
                                !row.source ||
                                row.source?.status === 'FAIL' ||
                                row.source?.status === 'DONE';
                            const mAction = [
                                <Button
                                    size="small"
                                    disabled={!enabled}
                                    key={`act-${index}-start`}
                                    onClick={() => handleStartTraining(row)}
                                >
                                    {enabled
                                        ? 'Start training'
                                        : row.source?.status ?? 'Start training'}
                                </Button>,
                            ];

                            return mAction;
                        },
                        search: false,
                    },
                }}
                dataSource={(sourcedVenues ?? []).filter((el) => {
                    // Match status first
                    if (
                        (filterStatus == 'NEW' && el.isNew !== true) ||
                        (filterStatus == 'TRAINED' && !el.source?.lastSourceAt)
                    ) {
                        return false;
                    }
                    // Match keyword
                    if (!!filterKeyword && !el.name.toLowerCase().includes(filterKeyword)) {
                        return false;
                    }
                    return true;
                })}
                toolbar={{
                    menu: {
                        activeKey: filterStatus,
                        items: [
                            {
                                key: 'ALL',
                                label: (
                                    <span>
                                        All
                                        {renderBadge(
                                            counterRef.current.total,
                                            filterStatus === 'ALL',
                                        )}
                                    </span>
                                ),
                            },
                            {
                                key: 'NEW',
                                label: (
                                    <span>
                                        New
                                        {renderBadge(
                                            counterRef.current.isNew,
                                            filterStatus === 'NEW',
                                        )}
                                    </span>
                                ),
                            },
                            {
                                key: 'TRAINED',
                                label: (
                                    <span>
                                        Trained
                                        {renderBadge(
                                            counterRef.current.trained,
                                            filterStatus === 'TRAINED',
                                        )}
                                    </span>
                                ),
                            },
                        ],
                        onChange(key) {
                            setFilterStatus(key);
                        },
                    },
                    search: {
                        onSearch: (value: string) => {
                            setFilterKeyword(value.toLowerCase());
                        },
                    },
                }}
            />
            {_renderMapPreview()}
            <SourceTrainingDrawer
                ref={trainingReqRef as any}
                onClosed={(sId?: string) => {
                    if (!!sId) _refreshData();
                }}
            />
        </PageContainer>
    );
};

export default CrowdSourceScreen;
