import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import {
    Alert,
    Button,
    Checkbox,
    Empty,
    Flex,
    InputNumber,
    message,
    Space,
    Switch,
    Tag,
    Typography,
} from 'antd';
import { ProCard, ProDescriptions } from '@ant-design/pro-components';
import { DragInputNumber } from 'components/DragInputNumber';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    LeftOutlined,
    RetweetOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import {
    AugmentedBatchDto,
    AugBatchStatus,
    getAugmented,
    AugmentedFpDto,
    updateAugmentConfig,
    AugmentedAlignDto,
    SizeDto,
} from 'apis/VenueApi';
import moment from 'moment';
import LoadingOverlay from 'components/LoadingOverlay';
import LockButton from 'components/LockButton';
import { useVenueState } from 'providers/VenueProvider';
import { useLocation, useNavigate } from 'react-router-dom';

const { Text } = Typography;

const basePlotConf: Partial<Plotly.Config> = {
    displayModeBar: true,
    responsive: true,
    displaylogo: false,
    modeBarButtons: [['zoom2d', 'pan2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d']],
};

const AugmentAlignScreen: React.FC = (props) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const { workingMap } = useVenueState();
    const bodyElRf = useRef<HTMLDivElement>();
    const lockScaleRef = useRef<boolean>(false);
    const lockOffsetRef = useRef<boolean>(false);
    const pxInMeterRef = useRef<number>(1);
    const [augBatch, setAugBatch] = useState<AugmentedBatchDto>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [augDetail, setAugDetail] = useState<AugmentedFpDto | undefined>();
    const [offsetConf, setOffsetConf] = useState<AugmentedAlignDto>({
        offsetX: 0,
        offsetY: 0,
        scaleX: 100,
        scaleY: 100,
    });
    const [activeAug, setActiveAug] = useState<boolean>(false);
    if (!workingMap) return <p>Working map not set</p>;

    useEffect(() => {
        if (!state?.augBatch || !workingMap?.mapAlign) return;
        setAugBatch(state.augBatch);
        refreshAugmentData(state.augBatch.id);
        pxInMeterRef.current = _getPixInMeter(
            workingMap.mapAlign.size,
            workingMap.mapAlign.sizeInMeters,
        );
        setActiveAug(state.augBatch.activated ?? false);
    }, [state, workingMap, setAugBatch]);

    const _getPixInMeter = useCallback((size: SizeDto, sizeInMeters: SizeDto) => {
        if (!size?.width || !sizeInMeters?.width) return 0.1;
        const pxDistance = Math.sqrt(size.width ** 2 + size.height ** 2);
        const meterDistance = Math.sqrt(sizeInMeters.width ** 2 + sizeInMeters.height ** 2);
        return 1 / (meterDistance / pxDistance);
    }, []);

    const _humanFileSize = useCallback((size: number) => {
        if (isNaN(size)) return '-';
        const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return +(size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }, []);

    const refreshAugmentData = async (augId: string) => {
        const mAugId = augId;
        if (!workingMap || !mAugId) return;

        setIsLoading(true);
        getAugmented(workingMap.id, mAugId)
            .then((resp) => {
                if (!!resp.response?.data?.data?.analyzeState) {
                    messageApi.warning('Analyze in progress. Please try again later.');
                    throw 'Analyze in progress.';
                }
                const augChanged =
                    augDetail?.augId === undefined || augDetail.augId !== resp.data?.augId;
                setAugDetail(resp.data);
                if (augChanged && resp.data?.align) {
                    setOffsetConf({
                        ...resp.data.align,
                        scaleX: resp.data.align.scaleX * 100,
                        scaleY: resp.data.align.scaleY * 100,
                    });
                }
            })
            .catch((ex) => {
                setAugDetail(undefined);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onSubmit = async () => {
        if (!augBatch) return;
        const mAlignConf = {
            ...offsetConf,
            scaleX: offsetConf.scaleX / 100,
            scaleY: offsetConf.scaleY / 100,
        };
        setIsLoading(true);
        updateAugmentConfig(workingMap.id, {
            augId: augBatch.id,
            activate: activeAug,
            align: mAlignConf,
        })
            .then((resp) => {
                messageApi.success('Change submitted');
                handleOnFinish(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleOnFinish = useCallback((flag: boolean) => {
        setAugDetail(undefined);
        navigate(-1);
    }, [setAugDetail, navigate]);

    const plotLayout: Partial<Plotly.Layout> = useMemo(
        () => ({
            autosize: true,
            width: undefined,
            height: bodyElRf?.current?.clientHeight,
            images: [
                {
                    source: workingMap.mapImg,
                    x: 0,
                    y: 0,
                    xref: 'x',
                    yref: 'y',
                    sizex: workingMap.mapAlign!.size.width,
                    sizey: workingMap.mapAlign!.size.height,
                    xanchor: 'left',
                    yanchor: 'top',
                    layer: 'below',
                    opacity: 1,
                },
            ],
            xaxis: {
                range: [0, workingMap.mapAlign!.size.width],
                scaleanchor: 'y',
                showgrid: true,
                zeroline: true,
                showline: false,
                // ticks: '',
                showticklabels: false,
            },
            yaxis: {
                range: [workingMap.mapAlign!.size.height, 0],
                // scaleanchor: 'x',
                showgrid: true,
                zeroline: true,
                showline: false,
                // ticks: '',
                showticklabels: false,
            },
            margin: { t: 24, b: 0, l: 0, r: 0, pad: 0 },
            plot_bgcolor: '#e2e9f5',
            paper_bgcolor: '#d4e0f2',
        }),
        [workingMap, bodyElRf.current],
    );

    const plotResult: {
        data: Partial<Plotly.Data>;
        coverage: number;
        fileSize: string;
    } = useMemo(() => {
        const { points = [], curByte = 1, blockSize = 3 } = augDetail ?? {};
        const mScaleX = offsetConf.scaleX / 100;
        const mScaleY = offsetConf.scaleY / 100;
        const xArr = [],
            yArr = [],
            colorArr = [];
        let validCount = 0;
        for (let i = 0; i < points.length; i++) {
            const mX = points[i][0] * pxInMeterRef.current * mScaleX + offsetConf.offsetX;
            const mY = points[i][1] * pxInMeterRef.current * mScaleY + offsetConf.offsetY;
            xArr.push(mX);
            yArr.push(mY);
            const isValid =
                mX >= 0 &&
                mX <= workingMap.mapAlign!.size.width &&
                mY >= 0 &&
                mY <= workingMap.mapAlign!.size.height;
            if (isValid) validCount++;
            colorArr.push(isValid ? '#1f77b4' : 'grey');
        }
        const ptByte = curByte / points.length;
        return {
            data: {
                x: xArr,
                y: yArr,
                name: 'pos',
                type: 'scatter',
                mode: 'markers',
                marker: {
                    color: colorArr,
                    opacity: 0.4,
                    size: (offsetConf.blockSize ?? blockSize) * pxInMeterRef.current,
                },
                hoverinfo: 'none',
            },
            coverage: validCount * Math.pow(blockSize, 2),
            fileSize: _humanFileSize(validCount * ptByte),
        };
    }, [
        workingMap,
        augDetail,
        offsetConf
    ]);

    const allowPreview = useMemo<boolean>(() => {
        return (
            (augBatch?.status === AugBatchStatus.READY ||
                augBatch?.status === AugBatchStatus.FORMATTED) &&
            !!augDetail &&
            !!workingMap?.mapAlign
        );
    }, [augBatch, augDetail, workingMap]);

    /**
     * Components
     */
    const _renderInput = (name: string, key: string) => {
        return (
            <DragInputNumber
                placeholder={name}
                title={name}
                style={{ borderBottom: '1px #d9d9d9 dashed' }}
                size="small"
                value={(offsetConf as any)[key]}
                onChange={(e) => {
                    if (!isNaN(Number(e))) {
                        if (lockOffsetRef.current && key.startsWith('offset')) {
                            setOffsetConf((val) => {
                                return { ...val, offsetX: Number(e), offsetY: Number(e) };
                            });
                        } else if (lockScaleRef.current && key.startsWith('scale')) {
                            setOffsetConf((val) => {
                                return { ...val, scaleX: Number(e), scaleY: Number(e) };
                            });
                        } else {
                            setOffsetConf((val) => {
                                return { ...val, [key]: Number(e) };
                            });
                        }
                    }
                }}
            />
        );
    };
    const _renderStatusTag = useCallback((status: string) => {
        const mTagConf: any = { icon: undefined, color: 'default' };
        if (status === AugBatchStatus.FAIL) {
            mTagConf.icon = <CloseCircleOutlined />;
            mTagConf.color = 'error';
        } else if (status === AugBatchStatus.READY) {
            mTagConf.icon = <CheckCircleOutlined />;
            mTagConf.color = 'success';
        } else if (status === AugBatchStatus.FORMATTED) {
            mTagConf.color = 'default';
        } else {
            mTagConf.icon = <SyncOutlined spin />;
            mTagConf.color = 'processing';
        }
        return <Tag {...mTagConf}>{status}</Tag>;
    }, []);

    const _renderPanel = () => {
        return (
            <ProCard
                colSpan={'30%'}
                className="venue-panel"
                title={
                    <Flex align="center" gap={12}>
                        <Button
                            type="link"
                            size="small"
                            icon={<LeftOutlined />}
                            onClick={() => handleOnFinish(false)}
                        >
                            Back
                        </Button>
                        <Typography.Text strong>Survey Augmentation</Typography.Text>
                    </Flex>
                }
                headerBordered
                headStyle={{ padding: 12, backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                bodyStyle={{ padding: 12, backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
                ghost
                direction="column"
            >
                <ProDescriptions column={1} style={{ marginBottom: '1em' }}>
                    <ProDescriptions.Item label="Map" copyable>
                        {workingMap.name}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="ID" copyable>
                        {workingMap.id}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="Created At">
                        {moment().format('YYYY-MM-DD HH:mm')}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="Status">
                        {augBatch?.outdated ? undefined : <Tag color="#2db7f5">Latest</Tag>}
                        {augBatch ? _renderStatusTag(augBatch.status) : undefined}
                    </ProDescriptions.Item>
                </ProDescriptions>

                {augBatch?.status === AugBatchStatus.FORMATTED ? (
                    <Alert
                        message="Please adjust the fingerprint!"
                        type="warning"
                        style={{ marginBottom: '1em' }}
                    />
                ) : undefined}

                {allowPreview ? _renderForm() : undefined}
            </ProCard>
        );
    };

    const _renderForm = () => {
        return (
            <>
                <ProCard bordered size="small" title="Adjustment" headerBordered>
                    <Flex vertical gap={12}>
                        <Flex gap="0.5em" align="center" justify="space-between">
                            <Text>
                                Override Block Size
                                <Text style={{ marginLeft: 6 }} type="secondary">
                                    (Generated={augDetail?.blockSize})
                                </Text>
                            </Text>
                            <InputNumber
                                min={1}
                                max={10}
                                style={{ width: '6em' }}
                                value={offsetConf?.blockSize}
                                onChange={(val) => {
                                    if (!!val) setOffsetConf((x) => ({ ...x, blockSize: val }));
                                }}
                                size="small"
                                placeholder="block size"
                            />
                        </Flex>
                        <Flex gap="0.5em" align="center" justify="space-between">
                            <Text>Offset</Text>
                            <Space style={{ width: '80%' }}>
                                {_renderInput('X', 'offsetX')}
                                <LockButton onToggle={(f) => (lockOffsetRef.current = f)} />
                                {_renderInput('Y', 'offsetY')}px
                            </Space>
                        </Flex>
                        <Flex gap="0.5em" align="center" justify="space-between">
                            <Text>Scale</Text>
                            <Space style={{ width: '80%' }}>
                                {_renderInput('W', 'scaleX')}
                                <LockButton onToggle={(f) => (lockScaleRef.current = f)} />
                                {_renderInput('H', 'scaleY')}%
                            </Space>
                        </Flex>
                        {/* <Flex gap="0.5em" align="center" justify="space-between">
                            <Text>Crop with floor plan mask</Text>
                            <Space>
                                <Switch
                                    onChange={(e) => {
                                        setOffsetConf((val) => ({ ...val, useMask: e }));
                                    }}
                                />
                            </Space>
                        </Flex> */}
                    </Flex>
                </ProCard>

                <Checkbox
                    style={{ marginTop: '1em' }}
                    checked={activeAug}
                    onChange={(e) => {
                        setActiveAug(e.target.checked);
                    }}
                >
                    Use this batch in next build
                </Checkbox>

                <Flex style={{ marginTop: '1em' }} gap={12}>
                    <Button onClick={() => handleOnFinish(false)}>Cancel</Button>
                    <Button type="primary" onClick={() => onSubmit()}>
                        Save
                    </Button>
                </Flex>
            </>
        );
    };

    const _renderGraph = () => {
        return (
            <div ref={bodyElRf as any} className="bg-wrapper w100" style={{ position: 'relative' }}>
                {allowPreview ? (
                    <>
                        <Plot
                            style={{ flex: 1 }}
                            data={[plotResult.data]}
                            config={basePlotConf}
                            layout={plotLayout}
                        />

                        <ProDescriptions
                            column={2}
                            style={{ position: 'absolute', top: 0, left: 12, width: '70%' }}
                        >
                            {/* <ProDescriptions.Item label="Coverage area">
                                {plotResult.coverage} m²
                            </ProDescriptions.Item> */}
                            <ProDescriptions.Item label="Estimated size">
                                {plotResult.fileSize}
                            </ProDescriptions.Item>
                            <ProDescriptions.Item label="Block size">
                                {augDetail?.blockSize ?? '-'}
                            </ProDescriptions.Item>
                        </ProDescriptions>
                    </>
                ) : (
                    <Empty
                        image={false}
                        description={
                            <div>
                                <p>Augmentation not ready.</p>
                                <Button type="link" onClick={() => handleOnFinish(false)}>
                                    Back
                                </Button>
                            </div>
                        }
                    />
                )}
            </div>
        );
    };

    return (
        <ProCard
            style={{ height: '100%', overflow: 'hidden' }}
            split={'vertical'}
            className="full-content-height"
        >
            {contextHolder}
            <LoadingOverlay visible={isLoading} />
            {_renderPanel()}
            {_renderGraph()}
        </ProCard>
    );
};

export default AugmentAlignScreen;
