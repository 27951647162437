import { Key, ProCard, ProForm, ProFormSelect, ProFormText, ProFormTextArea } from '@ant-design/pro-components';
import { Flex, Form, Radio, RadioChangeEvent, Tree, TreeDataNode, Typography } from 'antd';
import { traceAdvanceSearch } from 'apis/TraceApi';
import { useTraceContext } from 'providers/TraceProvider';
import { useState } from 'react';

const { Text, Title } = Typography;

const tables = [
    {
        name: 'ble',
        columns: [
            {
                'Name': 'ts',
                'Type': 'bigint',
            },
            {
                'Name': 'type',
                'Type': 'int',
            },
            {
                'Name': 'address',
                'Type': 'string',
            },
            {
                'Name': 'name',
                'Type': 'string',
            },
            {
                'Name': 'rssi',
                'Type': 'int',
            },
            {
                'Name': 'scanrecord',
                'Type': 'string',
            },
            {
                'Name': 'serviceids',
                'Type': 'string',
            },
            {
                'Name': 'bletype',
                'Type': 'string',
            },
            {
                'Name': 'id1',
                'Type': 'string',
            },
            {
                'Name': 'id2',
                'Type': 'string',
            },
            {
                'Name': 'id3',
                'Type': 'string',
            },
            {
                'Name': 'txpower',
                'Type': 'string',
            },
            {
                'Name': 'appid',
                'Type': 'string',
                'PartitionKey': 'Partition (0)',
            },
            {
                'Name': 'year',
                'Type': 'int',
                'PartitionKey': 'Partition (1)',
            },
            {
                'Name': 'month',
                'Type': 'int',
                'PartitionKey': 'Partition (2)',
            },
            {
                'Name': 'day',
                'Type': 'int',
                'PartitionKey': 'Partition (3)',
            },
        ],
    },
    {
        name: 'gps',
        columns: [
            {
                'Name': 'ts',
                'Type': 'bigint',
            },
            {
                'Name': 'type',
                'Type': 'int',
            },
            {
                'Name': 'altitude',
                'Type': 'float',
            },
            {
                'Name': 'accuracy',
                'Type': 'float',
            },
            {
                'Name': 'vertical_accuracy',
                'Type': 'float',
            },
            {
                'Name': 'bearing',
                'Type': 'float',
            },
            {
                'Name': 'speed',
                'Type': 'float',
            },
            {
                'Name': 'foreground',
                'Type': 'boolean',
            },
            {
                'Name': 'is_mock',
                'Type': 'boolean',
            },
            {
                'Name': 'satellite_view',
                'Type': 'int',
            },
            {
                'Name': 'satellite_used',
                'Type': 'int',
            },
            {
                'Name': 'satellite_dbhz',
                'Type': 'string',
            },
            {
                'Name': 'appid',
                'Type': 'string',
                'PartitionKey': 'Partition (0)',
            },
            {
                'Name': 'year',
                'Type': 'int',
                'PartitionKey': 'Partition (1)',
            },
            {
                'Name': 'month',
                'Type': 'int',
                'PartitionKey': 'Partition (2)',
            },
            {
                'Name': 'day',
                'Type': 'int',
                'PartitionKey': 'Partition (3)',
            },
        ],
    },
    {
        name: 'gt',
        columns: [
            {
                'Name': 'ts',
                'Type': 'bigint',
            },
            {
                'Name': 'type',
                'Type': 'int',
            },
            {
                'Name': 'latitude',
                'Type': 'float',
            },
            {
                'Name': 'longitude',
                'Type': 'float',
            },
            {
                'Name': 'level',
                'Type': 'int',
            },
            {
                'Name': 'remark',
                'Type': 'string',
            },
            {
                'Name': 'total_floor',
                'Type': 'int',
            },
            {
                'Name': 'uuid',
                'Type': 'string',
            },
            {
                'Name': 'building',
                'Type': 'string',
            },
            {
                'Name': 'venue_id',
                'Type': 'string',
            },
            {
                'Name': 'appid',
                'Type': 'string',
                'PartitionKey': 'Partition (0)',
            },
            {
                'Name': 'year',
                'Type': 'int',
                'PartitionKey': 'Partition (1)',
            },
            {
                'Name': 'month',
                'Type': 'int',
                'PartitionKey': 'Partition (2)',
            },
            {
                'Name': 'day',
                'Type': 'int',
                'PartitionKey': 'Partition (3)',
            },
        ],
    },
    {
        name: 'remarks',
        columns: [
            {
                'Name': 'ts',
                'Type': 'bigint',
            },
            {
                'Name': 'type',
                'Type': 'int',
            },
            {
                'Name': 'remark_type',
                'Type': 'int',
            },
            {
                'Name': 'value',
                'Type': 'string',
            },
            {
                'Name': 'remark',
                'Type': 'string',
            },
            {
                'Name': 'appid',
                'Type': 'string',
                'PartitionKey': 'Partition (0)',
            },
            {
                'Name': 'year',
                'Type': 'int',
                'PartitionKey': 'Partition (1)',
            },
            {
                'Name': 'month',
                'Type': 'int',
                'PartitionKey': 'Partition (2)',
            },
            {
                'Name': 'day',
                'Type': 'int',
                'PartitionKey': 'Partition (3)',
            },
        ],
    },
    {
        name: 'wifi',
        columns: [
            {
                'Name': 'ts',
                'Type': 'bigint',
            },
            {
                'Name': 'type',
                'Type': 'int',
            },
            {
                'Name': 'bssid',
                'Type': 'string',
            },
            {
                'Name': 'ssid',
                'Type': 'string',
            },
            {
                'Name': 'capabilities',
                'Type': 'string',
            },
            {
                'Name': 'level',
                'Type': 'int',
            },
            {
                'Name': 'frequency',
                'Type': 'int',
            },
            {
                'Name': 'channel_width',
                'Type': 'int',
            },
            {
                'Name': 'center_freq0',
                'Type': 'int',
            },
            {
                'Name': 'center_freq1',
                'Type': 'int',
            },
            {
                'Name': 'standard',
                'Type': 'int',
            },
            {
                'Name': 'scan_throttle',
                'Type': 'boolean',
            },
            {
                'Name': 'appid',
                'Type': 'string',
                'PartitionKey': 'Partition (0)',
            },
            {
                'Name': 'year',
                'Type': 'int',
                'PartitionKey': 'Partition (1)',
            },
            {
                'Name': 'month',
                'Type': 'int',
                'PartitionKey': 'Partition (2)',
            },
            {
                'Name': 'day',
                'Type': 'int',
                'PartitionKey': 'Partition (3)',
            },
        ],
    },
];
const yearOptions: { value: number, label: number }[] = [];
const monthOptions: { value: number, label: number }[] = [];
const dayOptions: { value: number, label: number }[] = [];

for (let i = 2024; i <= new Date().getFullYear(); i++)
    yearOptions.push({ value: i - 2000, label: i });
for (let i = 1; i <= 12; i++)
    monthOptions.push({ value: i, label: i });
for (let i = 1; i <= 31; i++)
    dayOptions.push({ value: i, label: i });

const TraceQuerySearchForm = ({ isMobile }: { isMobile: boolean }) => {
    const [selected, setSelected] = useState<Key[]>([]);
    const [selectedParent, setSelectedParent] = useState<string | null>(null);
    const [whereQuery, setWhereQuery] = useState<string>('');
    const [queryMode, setQueryMode] = useState<'builder' | 'write'>('builder');
    const [loading, setLoading] = useState<boolean>(false);
    const [searchForm] = Form.useForm();
    const { fetchExecutions, setSelectedExecution, executionPageConfig } = useTraceContext();

    function handleChecked(checkedKeys: { checked: Key[]; halfChecked: Key[] } | Key[], event: any) {
        const selected = Array.isArray(checkedKeys) ? checkedKeys : checkedKeys.checked;
        setSelected(selected);
        if (selected.length === 0)
            setSelectedParent(null);
        else
            setSelectedParent(selected[0].toString().split('-')[0]);
        const newQuery = _buildQuery();
        searchForm.setFieldsValue({ query: newQuery });
    }

    function handleQueryModeChange({ target: { value } }: RadioChangeEvent) {
        setQueryMode(value);
    }

    function handleValuesChanged(_value: Record<string, any>, values: Record<string, any>) {
        const keys = Object.keys(values);
        if (keys.length === 0) setWhereQuery('');
        else {
            const newQuery = keys.filter(key => !!values[key])
                .map((key) => typeof values[key] === 'string' ? `${key}='${values[key]}'` : `${key}=${values[key]}`)
                .join(' AND ');
            setWhereQuery(newQuery);
        }
    }

    async function handleSubmitted(values: Record<string, any>) {
        setLoading(true);
        try {
            const query = (queryMode === 'builder' ? _buildQuery() : values.query).trim();
            const result = await traceAdvanceSearch(query);
            if (!!result.data?.executionId) {
                await fetchExecutions({
                    limit: executionPageConfig.limit,
                    page: 0,
                });
                setSelectedExecution({
                    id: result.data?.executionId,
                    query,
                    loaded: false,
                });
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    function _buildQuery(): string {
        if (!selectedParent) return '';
        const fields = selected.reduce<string[]>((prev, cur) => {
            if (cur.toString() !== selectedParent)
                prev.push(cur.toString().split('-')[1]);
            return prev;
        }, []).join(', ');

        let query = `SELECT ${fields} ` + `FROM ${selectedParent}`;
        if (!!whereQuery) query += ` WHERE ${whereQuery}`;
        return query;
    }

    const treeData: TreeDataNode[] = tables.map(table => {
        const isDisabled = queryMode !== 'builder' || (selectedParent !== null && selectedParent !== table.name);
        return {
            title: table.name,
            key: table.name,
            disableCheckbox: isDisabled,
            children: [
                ...table.columns.map(column => ({
                    title: column.Name,
                    key: `${table.name}-${column.Name}`,
                    disableCheckbox: isDisabled,
                })),
                { title: '"$path"', key: `${table.name}-"$path"`, disableCheckbox: isDisabled },
            ],
        };
    });

    return <ProForm
        form={searchForm}
        submitter={{
            searchConfig: {
                submitText: 'Query',
            },
            resetButtonProps: {
                style: { display: 'none' },
            },
        }}
        onFinish={handleSubmitted}
        onValuesChange={handleValuesChanged}
        disabled={loading}
    >
        <ProCard
            bordered={true}
            headStyle={{ background: '#f0f0f0' }}
            bodyStyle={{ background: '#f0f0f0', color: '#999' }}
        >
            <Flex gap={24} vertical={isMobile}>
                <Flex flex={1} vertical={true} style={{ maxHeight: '180px', overflow: 'auto', paddingRight: 20 }}>
                    <Title level={5} style={{ marginTop: 0 }}>Tables</Title>
                    <Tree
                        treeData={treeData}
                        checkable={true}
                        blockNode={true}
                        onCheck={handleChecked}
                        rootStyle={{ width: '100%' }}
                    />
                    <Title level={5}>Filter Conditions</Title>
                    <ProFormText width="md" name="appId" label="App ID" disabled={queryMode !== 'builder'} />
                    <ProFormSelect
                        name="year"
                        label="Year"
                        options={yearOptions}
                        disabled={queryMode !== 'builder'}
                    />
                    <ProFormSelect
                        name="month"
                        label="Month"
                        options={monthOptions}
                        disabled={queryMode !== 'builder'}
                    />
                    <ProFormSelect
                        name="day"
                        label="Day"
                        options={dayOptions}
                        disabled={queryMode !== 'builder'}
                    />
                </Flex>
                <Flex flex={3} gap={12} vertical={true} style={{ maxHeight: '180px', overflow: 'auto' }}>
                    <Radio.Group
                        style={{}}
                        options={[
                            { label: 'Query Builder', value: 'builder' },
                            { label: 'Write Query', value: 'write' },
                        ]}
                        onChange={handleQueryModeChange}
                        value={queryMode}
                        optionType="button" />
                    {queryMode === 'builder' &&
                        <>
                            <Text
                                type="secondary"
                                style={{ width: '100%', fontStyle: 'italic' }}>* Choose a table and columns, and enter
                                at least one filter condition to generate a query</Text>
                            <Text
                                type="secondary"
                                style={{ width: '100%', minHeight: 32 }}>{_buildQuery()}</Text>
                        </>}
                    {queryMode === 'write' && <>
                        <Text
                            type="secondary"
                            style={{ width: '100%', fontStyle: 'italic' }}>* Only SELECT query is supported and WHERE
                            clause is necessary to reduce data scanning</Text>
                        <ProFormTextArea
                            name="query"
                            placeholder="Enter a sql like query"
                            fieldProps={{
                                width: '100%',
                                variant: 'outlined',
                            }}
                            style={{ width: '100%' }}
                        />
                    </>}
                </Flex>
            </Flex>
        </ProCard>
        <ProCard />
    </ProForm>;
};

export default TraceQuerySearchForm;
