import { ProCard, ProForm, ProFormDependency } from '@ant-design/pro-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppState } from 'providers/AppStateProvider';
import {
    Typography,
    Button,
    Flex,
    Select,
    DatePicker,
    Statistic,
    Badge,
    Tooltip,
    Space,
    Skeleton,
    Popover,
    Radio,
    Form,
    InputNumber,
} from 'antd';
import { ProjStatisticFilterDto, StatisticResultDto, getProjectStatistic } from 'apis/ProjectApi';
import { FilterOutlined } from '@ant-design/icons';

import Plot from 'react-plotly.js';
import moment from 'moment';
import dayjs from 'dayjs';

const plotConf: {
    config: Partial<Plotly.Config>;
    layout: Partial<Plotly.Layout>;
    iosColor: string;
    aosColor: string;
} = {
    config: {
        displayModeBar: false,
        responsive: true,
    },
    layout: {
        autosize: true,
        width: undefined,
        height: 250,
        margin: {
            l: 50,
            r: 5,
            b: 50,
            t: 10,
        },
        showlegend: false,
        xaxis: {
            title: 'UTC datetime',
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            title: 'Count',
            showline: false,
            zeroline: true,
        },
    },
    aosColor: '#96be84',
    iosColor: '#6db0d8',
};

export const ProjectStatisticModule: React.FC = (props) => {
    const { activeProject, isMobile } = useAppState();

    const [filter, setFilter] = useState<ProjStatisticFilterDto & { name: string }>({
        from: moment().subtract(30, 'days').toDate(),
        to: new Date(),
        group: 'D',
        name: 'Range',
    });
    const [result, setResult] = useState<{
        data?: StatisticResultDto;
        filter: ProjStatisticFilterDto;
    }>();

    useEffect(() => {
        getStatistics();
    }, [activeProject?.projId, filter]);

    const getStatistics = useCallback(async () => {
        if (!activeProject?.projId) return;
        getProjectStatistic(activeProject.projId, {
            from: filter.group !== 'H' ? moment(filter.from).startOf('day').toDate() : filter.from,
            to: filter.group !== 'H' ? moment(filter.to).endOf('day').toDate() : filter.to,
            group: filter.group,
        })
            .then((resp) => {
                setResult({ data: resp.data, filter });
            })
            .catch((ex) => setResult(undefined));
    }, [activeProject?.projId, filter]);

    const plotData = useMemo(() => {
        if (!result || !result.data) {
            return {
                xData: [],
                iosData: [],
                aosData: [],
                uniqueAos: 0,
                uniqueIos: 0,
            };
        }
        const entries: string[] = [];
        const entryKeys: string[] = [];
        const startDate = moment(result.filter.from);
        const endDate = moment(result.filter.to);
        let current = startDate;
        const mPattern = {
            M: { unit: 'month', format: 'YYYY-MM' },
            D: { unit: 'day', format: 'YYYY-MM-DD' },
            H: { unit: 'hour', format: 'YYYY-MM-DD HH:00:00' },
        }[result.filter.group];

        while (current.isBefore(endDate, mPattern!.unit as any)) {
            const strDate = moment.utc(current).format(mPattern!.format as any);
            entries.push(strDate);
            entryKeys.push(strDate.substring(0, 13).replace(' ', '-'));
            current = current.add(1, mPattern!.unit as any);
        }

        const fillData = (input: { platform: string; count: number; date: string }[]) => {
            return entryKeys.map((d) => {
                const mData = input.find((el) => el.date === d);
                return mData?.count ?? 0;
            });
        };

        return {
            xData: entries,
            iosData: fillData(result.data.result.filter((el) => el.platform === 'IOS')),
            aosData: fillData(result.data.result.filter((el) => el.platform === 'AOS')),
            uniqueAos: result.data.devices.find((el) => el.platform === 'AOS')?.count ?? 0,
            uniqueIos: result.data.devices.find((el) => el.platform === 'IOS')?.count ?? 0,
        };
    }, [result]);

    const _buildSelector = () => {
        return (
            <Flex key="ext_filter" gap="small" align="end" vertical={isMobile}>
                <Space.Compact>
                    <FilterPopover
                        label={filter.name}
                        onChange={(from, to, name) => {
                            setFilter((val) => ({ ...val, from, to, name }));
                        }}
                    />
                    <DatePicker.RangePicker
                        picker={filter.group === 'M' ? 'month' : 'date'}
                        showTime={filter.group === 'H'}
                        key="sel_date"
                        size="small"
                        placement="topRight"
                        allowEmpty={[false, false]}
                        value={[dayjs(filter.from), dayjs(filter.to)]}
                        presets={[
                            { label: 'Last 24 Hours', value: [dayjs().add(-1, 'd'), dayjs()] },
                            { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
                            { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
                            { label: 'Last Month', value: [dayjs().add(-1, 'month'), dayjs()] },
                            { label: 'Last 6 Months', value: [dayjs().add(-6, 'month'), dayjs()] },
                            { label: 'Last Year', value: [dayjs().add(-1, 'year'), dayjs()] },
                        ]}
                        onChange={(dates) => {
                            if (dates != null && dates[0] !== null && dates[1] !== null) {
                                setFilter((val) => ({
                                    ...val,
                                    from: dates[0]!.toDate(),
                                    to: dates[1]!.toDate(),
                                    name: 'Range',
                                }));
                            }
                        }}
                    />
                </Space.Compact>
                <Select
                    key="option_select"
                    defaultValue={'D'}
                    style={{ width: 120 }}
                    size="small"
                    onChange={(val) => {
                        setFilter((x) => ({ ...x, group: val }));
                    }}
                    options={[
                        { value: 'H', label: 'By Hour' },
                        { value: 'D', label: 'By Date' },
                        { value: 'M', label: 'By Month' },
                    ]}
                />
            </Flex>
        );
    };

    return (
        <ProCard
            style={{ marginTop: '1em' }}
            title={'User engagement'}
            tooltip="Number of sessions engaged"
            extra={[_buildSelector()]}
            headerBordered
            split={isMobile ? 'horizontal' : 'vertical'}
        >
            <ProCard colSpan={isMobile ? '100%' : '90%'}>
                {plotData.xData ? (
                    <Plot
                        style={{ flex: 1, marginTop: '0.5rem' }}
                        data={[
                            {
                                x: plotData.xData,
                                y: plotData.iosData,
                                name: 'IOS',
                                type: 'scatter',
                                mode: 'lines+markers',
                                marker: { color: plotConf.iosColor },
                            },
                            {
                                x: plotData.xData,
                                y: plotData.aosData,
                                name: 'Android',
                                type: 'scatter',
                                mode: 'lines+markers',
                                marker: { color: plotConf.aosColor },
                            },
                        ]}
                        layout={plotConf.layout}
                        config={plotConf.config}
                    />
                ) : (
                    <Flex style={{ height: 250, marginTop: '0.5rem' }}>
                        <Skeleton active />
                    </Flex>
                )}
            </ProCard>
            <ProCard split="horizontal" style={{ padding: '1rem' }} gutter={[16, 16]}>
                <p>
                    <Tooltip title="Unique device">
                        <Typography.Text type="secondary">Devices:</Typography.Text>
                    </Tooltip>
                </p>
                <Flex gap="1rem" vertical={!isMobile}>
                    <Statistic
                        title={<Badge color={plotConf.aosColor} text="Android" />}
                        value={plotData.uniqueAos}
                    />
                    <Statistic
                        title={<Badge color={plotConf.iosColor} text={'iOS'} />}
                        value={plotData.uniqueIos}
                    />
                </Flex>
            </ProCard>
        </ProCard>
    );
};

const FilterPopover = React.memo<{
    onChange: (from: Date, to: Date, name: string) => void;
    label: string;
}>(
    (props) => {
        const [filterInputForm] = Form.useForm();
        const [filterOpen, setFilterOpen] = useState<boolean>(false);

        const handleChange = useCallback((key: string) => {
            if (key === 'C') return;
            const parts = key.split('_');
            props.onChange(
                moment()
                    .subtract(Number(parts[0]), parts[1] as any)
                    .toDate(),
                new Date(),
                key.replace('_', ' '),
            );
            setFilterOpen(false);
        }, []);

        const quickSelector = useMemo(
            () => (
                <ProForm
                    form={filterInputForm}
                    layout="vertical"
                    className="tiny-row"
                    submitter={false}
                    initialValues={{ quickMode: null, duration: 1, unit: 'days' }}
                >
                    <Form.Item name="quickMode">
                        <Radio.Group
                            onChange={(evt) => {
                                if (evt.target.value !== 'C') handleChange(evt.target.value);
                            }}
                        >
                            <Space.Compact block style={{ marginBottom: 6 }}>
                                <Radio.Button value="12_hours">12 Hours</Radio.Button>
                                <Radio.Button value="24_hours">24 Hours</Radio.Button>
                                <Radio.Button value="48_hours">48 Hours</Radio.Button>
                            </Space.Compact>
                            <Space.Compact block style={{ marginBottom: 6 }}>
                                <Radio.Button value="7_days">7 Days</Radio.Button>
                                <Radio.Button value="14_days">14 Days</Radio.Button>
                            </Space.Compact>
                            <Space.Compact block style={{ marginBottom: 6 }}>
                                <Radio.Button value="1_months">1 Month</Radio.Button>
                                <Radio.Button value="3_months">3 Months</Radio.Button>
                                <Radio.Button value="6_months">6 Months</Radio.Button>
                                <Radio.Button value="12_months">12 Month</Radio.Button>
                            </Space.Compact>
                            <Space.Compact block style={{ marginBottom: 6 }}>
                                <Radio.Button value="C">Custom</Radio.Button>
                            </Space.Compact>
                        </Radio.Group>
                    </Form.Item>

                    <ProFormDependency name={['quickMode']}>
                        {({ quickMode }) => {
                            if (quickMode === 'C') {
                                return (
                                    <Flex gap={6} align="end">
                                        <Form.Item label="Duration" name="duration">
                                            <InputNumber min={1} max={999} placeholder="Duration" />
                                        </Form.Item>
                                        <Form.Item label="Unit of time" name="unit">
                                            <Select
                                                options={[
                                                    { value: 'hours', label: 'Hours' },
                                                    { value: 'days', label: 'Days' },
                                                    { value: 'months', label: 'Months' },
                                                ]}
                                            />
                                        </Form.Item>
                                        <Button
                                            style={{ marginBottom: 12, marginLeft: 12 }}
                                            type="primary"
                                            onClick={() => {
                                                if (filterInputForm) {
                                                    const mDuration =
                                                        filterInputForm.getFieldValue('duration');
                                                    const mUnit =
                                                        filterInputForm.getFieldValue('unit');
                                                    if (Number(mDuration) > 0)
                                                        handleChange(`${mDuration}_${mUnit}`);
                                                }
                                            }}
                                        >
                                            Apply
                                        </Button>
                                    </Flex>
                                );
                            } else {
                                return undefined;
                            }
                        }}
                    </ProFormDependency>
                </ProForm>
            ),
            [filterInputForm, handleChange],
        );

        return (
            <Popover
                content={quickSelector}
                title="Quick select"
                trigger="click"
                open={filterOpen}
                onOpenChange={(open) => {
                    if (open && filterInputForm) filterInputForm.resetFields();
                    setFilterOpen(open);
                }}
            >
                <Button size="small" icon={<FilterOutlined />}>
                    {props.label}
                </Button>
            </Popover>
        );
    },
    (prev, curr) => prev.label === curr.label,
);
