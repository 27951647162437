import React, {
    useRef,
    useContext,
    createContext,
    useEffect,
    useState,
    memo,
    useCallback,
} from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import { HK_CENTER } from 'services/BaseMapEngine';
import { tilesList } from 'components/maps/MapTileControl';

export type LngLatPair = [number, number] | { lat: number; lng: number };

export type MapBoxContextType = {
    map?: mapboxgl.Map;
    switchTier: (key: string) => void;
    selectedTier: string,
};

const mapBoxContext = createContext<MapBoxContextType>({
    map: undefined,
} as MapBoxContextType);

export type MapBoxContainerProps = {
    center?: LngLatPair;
    initTier?: string;
    children?: React.ReactNode;
};

const MapBoxContainer: React.FC<MapBoxContainerProps> = (props) => {
    const mapContainerRef = useRef<HTMLElement>();
    const [mapRef, setMapRef] = useState<mapboxgl.Map>();
    const [selectedTier, setSelectedTier] = useState<string>('Mapbox.light');

    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_KEY!;
        const mTier = _getTierConfByKey(props.initTier);
        const mMap = new mapboxgl.Map({
            container: mapContainerRef.current!,
            style: _toTierStyle(mTier),
            center: props.center ?? HK_CENTER,
            zoom: 12,
        });
        mMap.on('load', () => {
            setMapRef(mMap);
            setSelectedTier(mTier.key);
        });
        return () => {};
    }, [props.initTier]);

    const switchTier = useCallback(
        (inputKey: string) => {
            if (!!mapRef) {
                const mTier = _getTierConfByKey(inputKey);
                mapRef.setStyle(_toTierStyle(mTier));
                mapRef.on('style.load', () => {
                    setSelectedTier(mTier.key);
                });
            }
        },
        [mapRef, setMapRef],
    );
    const _getTierConfByKey = useCallback((inputKey?: string): any => {
        const customConf = inputKey ? tilesList.find((el) => el.key === inputKey) : undefined;
        return customConf ?? tilesList.find((el) => el.key === 'Mapbox.light');
    }, []);
    const _toTierStyle = useCallback((tierConf: any): mapboxgl.Style => {
        if (!!tierConf?.styleUrl) return tierConf.styleUrl;
        return {
            version: 8,
            sources: {
                'custom-tile-source': {
                    type: 'raster',
                    tiles: (tierConf.subdomains ?? ['a', 'b', 'c']).map((x: string) =>
                        tierConf.url.replace('{s}', x),
                    ),
                    tileSize: 256,
                    attribution: tierConf.attribution,
                },
            },
            layers: [
                {
                    id: 'custom-tile-layer',
                    type: 'raster',
                    source: 'custom-tile-source',
                    minzoom: 0,
                    maxzoom: 19,
                },
            ],
        };
    }, []);

    return (
        <mapBoxContext.Provider value={{ map: mapRef, switchTier, selectedTier }}>
            <div
                ref={mapContainerRef as any}
                id="map-view"
                style={{ width: '100%', height: '100%' }}
            />
            <div key={selectedTier}>{props.children}</div>
        </mapBoxContext.Provider>
    );
};

export default memo(MapBoxContainer);

export const useMapBox = () => {
    return useContext(mapBoxContext);
};
