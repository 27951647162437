import { memo, useMemo } from 'react';
import { computeColorFilter } from 'utils/ColorFilterGenerator';
const IconNames = [
    'topCorner',
    'bevelCorner',
    'area',
    'size',
    'altitude',
    'accuracy',
    'ruler',
    'target',
    'wifi',
    'bluetooth',
    'compass',
    'layer',
    'stars',
    'select',
    'wifi_off',
    'bluetooth_off',
    'polygon',
    'polygon_err',
    'map',
    'map_err',
    'survey',
    'survey_err',
] as const;
type IconKey = (typeof IconNames)[number];
// Reference: https://angel-rs.github.io/css-color-filter-generator/
const IconColorFilter: Record<string, string> = {
    green: 'brightness(0) saturate(100%) invert(34%) sepia(97%) saturate(1891%) hue-rotate(60deg) brightness(99%) contrast(89%)',
    yellow: 'brightness(0) saturate(100%) invert(79%) sepia(61%) saturate(485%) hue-rotate(320deg) brightness(103%) contrast(107%)',
    red: 'brightness(0) saturate(100%) invert(26%) sepia(62%) saturate(4154%) hue-rotate(340deg) brightness(80%) contrast(112%)',
    orange: 'brightness(0) saturate(100%) invert(58%) sepia(100%) saturate(850%) hue-rotate(359deg) brightness(101%) contrast(105%)',
    grey: 'brightness(0) saturate(100%) invert(90%) sepia(0%) saturate(85%) hue-rotate(142deg) brightness(98%) contrast(81%)',
};
interface CustomIconProp {
    icon: IconKey | string;
    size?: number;
    title?: string;
    style?: React.CSSProperties;
    color?: string;
}
const CustomIcon: React.FC<CustomIconProp> = (props) => {
    const iconIndex = IconNames.indexOf(props.icon as IconKey);
    const mSize = props.size ?? 24;

    const elStyle = useMemo(() => {
        if (props.color && !!IconColorFilter[props.color]) {
            return { ...props.style, filter: IconColorFilter[props.color] };
        } else if (props.color) {
            const mFilter = computeColorFilter(props.color);
            return { ...props.style, filter: mFilter };
        } else {
            return props.style;
        }
    }, [props.style, props.color]);

    return (
        <div
            style={{ display: 'inline-block', width: mSize, height: mSize, ...elStyle }}
            title={props.title ?? props.icon.replaceAll('_', ' ')}
        >
            <span
                className="ic-group"
                style={{
                    backgroundSize: `auto ${mSize}px`,
                    width: `${mSize}px`,
                    height: `${mSize}px`,
                    backgroundPosition: `-${iconIndex * mSize}px 0`,
                }}
            />
        </div>
    );
};

export default memo(CustomIcon);
