import {
    VenueDetailDto,
    VenueBoundConfigDto,
    FloorPlanMaskDto,
    getVenue,
    getVenuePackages,
    getVenueActiveBoundConfig,
    getFloorplanMask,
    getVenueRuntimeConfig,
    VenueConfigGroup,
    getBatchJobs,
    JobBatchInfoDto,
    JobBatchStatus,
    getSurveySessions,
    SurveySessionDto,
    getSurveyQuality,
    SurveyQualityDto,
    analyzeSurveyQuality,
    VenueMapMetaDto,
} from 'apis/VenueApi';

import { BeaconPlanDto, BeaconPlanInfoDto, getBeaconList, getBeaconPlan } from 'apis/BeaconApi';

import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from './AppStateProvider';
import { PaginationDto } from 'apis/TraceApi';

export type VenueContextType = {
    isLoading?: boolean;
    venue?: VenueDetailDto;
    fetchVenue: (venueId?: string) => void;
    activeVenueId?: string;
    switchVenue: (venueId: string, mapId?: string | null) => void;
    workingMap?: VenueMapMetaDto;
    selectWorkingMap: (mapEl?: string | VenueMapMetaDto) => void;

    venueRtConfig?: VenueConfigGroup;
    fetchVenueRtConfig: (venueId: string) => void;

    venuePackages?: any;
    fetchPackages: (page: number) => void;

    venueBoundConfig?: VenueBoundConfigDto;
    fetchBoundConfig: (venueId: string) => void;

    beaconPlanList?: BeaconPlanInfoDto[];
    fetchBeaconList: () => void;
    beaconPlan?: BeaconPlanDto;
    fetchBeaconPlan: (planId?: string) => void;

    floorPlanMask?: FloorPlanMaskDto;
    fetchFloorPlanMask: () => void;

    batchJobPage?: PaginationDto;
    batchJobs?: JobBatchInfoDto[];
    batchRunning?: JobBatchInfoDto[];
    fetchBatchJobs: (page?: number, running?: boolean, type?: string) => void;

    surveySessions?: SurveySessionDto[];
    fetchSurveyData: () => void;
    fetchSurveyQuality: () => void;
    surveyQuality?: SurveyQualityDto;
    analyzeSurvey: () => void;
};
const VenueContext = createContext<VenueContextType>({} as VenueContextType);
const VenueContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [venue, setVenue] = useState<VenueDetailDto>();
    const [activeVenueId, setActiveVenueId] = useState<string>();
    const [workingMap, setWorkingMap] = useState<VenueMapMetaDto>();
    const [venuePackages, setVenuePackages] = useState<any>();

    const [venueRtConfig, setVenueRtConfig] = useState<VenueConfigGroup>();
    const [venueBoundConfig, setVenueBoundConfig] = useState<VenueBoundConfigDto>();

    const [beaconPlanList, setBeaconPlanList] = useState<BeaconPlanInfoDto[]>();
    const [beaconPlan, setBeaconPlan] = useState<BeaconPlanDto>();

    const [floorPlanMask, setFloorPlanMask] = useState<FloorPlanMaskDto>();

    const [batchJobPage, setBatchJobsPage] = useState<PaginationDto>();
    const [batchJobs, setBatchJobs] = useState<JobBatchInfoDto[]>();
    const [batchRunning, setBatchRunning] = useState<JobBatchInfoDto[]>();

    const [surveySessions, setSurveySessions] = useState<SurveySessionDto[]>();
    const [surveyQuality, setSurveyQuality] = useState<SurveyQualityDto>();

    const { activeProject, switchProject } = useAppState();
    const params = useParams();
    const nextMapIdRef = useRef<string | undefined | null>(undefined);

    useEffect(() => {
        if (activeVenueId || activeVenueId != venue?.id) {
            fetchVenue(activeVenueId, true);
        }
    }, [activeProject, activeVenueId]);

    function switchVenue(venueId: string, mapId?: string | null) {
        setActiveVenueId(venueId);
        setWorkingMap(undefined);
        nextMapIdRef.current = mapId;
    }
    function selectWorkingMap(mapEl?: string | VenueMapMetaDto) {
        if (typeof mapEl === 'string') {
            const mMap = (venue?.maps ?? []).find((el) => el.id === mapEl);
            setWorkingMap(mMap);
        } else {
            setWorkingMap(mapEl);
        }
    }
    function resetState() {
        setVenue(undefined);
        setWorkingMap(undefined);
        setVenuePackages(undefined);
        setVenueRtConfig(undefined);
        setVenueBoundConfig(undefined);
        setBeaconPlanList(undefined);
        setBeaconPlan(undefined);
        setFloorPlanMask(undefined);
        setBatchJobsPage(undefined);
        setBatchJobs(undefined);
        setBatchRunning(undefined);
        setSurveySessions(undefined);
        setSurveyQuality(undefined);
    }

    function fetchVenue(venueId?: string, resetAll?: boolean) {
        const mVenueId = venueId ?? activeVenueId ?? params?.venueId;
        if (!mVenueId || mVenueId == '') {
            setVenue(undefined);
            setWorkingMap(undefined);
            return;
        }
        setIsLoading(true);
        if (resetAll) resetState();
        getVenue(mVenueId)
            .then((res) => {
                if (res.data) {
                    setVenue(res.data);
                    setIsLoading(false);
                }
                if (!!workingMap || !!nextMapIdRef.current) {
                    const targetMapId = nextMapIdRef.current ?? workingMap?.id ?? '';
                    const activeMap = (res?.data?.maps ?? []).find((el) => el?.id === targetMapId);
                    setWorkingMap(activeMap);
                    nextMapIdRef.current = undefined;
                }
                if (!!res.data?.projectId && activeProject?.projId !== res.data?.projectId) {
                    switchProject(res.data?.projectId);
                }
            })
            .catch(() => {
                setVenue(undefined);
                setIsLoading(false);
            });
    }

    function fetchPackages(page: number) {
        if (!venue) return;
        setIsLoading(true);
        getVenuePackages(venue.id, page.toString())
            .then((res) => {
                setVenuePackages(res.response?.data);
                setIsLoading(false);
            })
            .catch(() => {
                setVenuePackages(undefined);
                setIsLoading(false);
            });
    }

    function fetchVenueRtConfig(venueId: string) {
        setIsLoading(true);
        getVenueRuntimeConfig(venueId)
            .then(async (resp) => {
                if (resp.response?.status === 200 || resp.response?.status === 201) {
                    setVenueRtConfig(resp.data);
                    setIsLoading(false);
                } else {
                    setVenueRtConfig(undefined);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setVenueRtConfig(undefined);
                setIsLoading(false);
            });
    }

    function fetchBoundConfig(venueId: string) {
        getVenueActiveBoundConfig(venueId)
            .then((res) => {
                setVenueBoundConfig(res?.data);
            })
            .catch(() => {
                setVenueBoundConfig(undefined);
            });
    }

    function fetchBeaconList() {
        if (!venue?.id || !workingMap?.id) {
            setBeaconPlanList(undefined);
            setBeaconPlan(undefined);
            return;
        }

        setIsLoading(true);
        getBeaconList(workingMap.id)
            .then((res) => {
                setBeaconPlanList(res.data);
            })
            .catch(() => {
                setBeaconPlanList(undefined);
                setBeaconPlan(undefined);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function fetchBeaconPlan(planId?: string) {
        if (!planId || planId === '') {
            setBeaconPlan(undefined);
            return;
        }

        setIsLoading(true);
        getBeaconPlan(planId)
            .then((res) => {
                setBeaconPlan(res.data);
            })
            .catch(() => {
                setBeaconPlan(undefined);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function fetchFloorPlanMask() {
        if (!venue?.id || !workingMap?.id) return;
        setIsLoading(true);
        getFloorplanMask(venue.id, workingMap.id)
            .then((res) => {
                setFloorPlanMask(res.data as FloorPlanMaskDto);
            })
            .catch(() => {
                setFloorPlanMask(undefined);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function fetchBatchJobs(page: number = 0, running?: boolean, type?: string) {
        if (!venue?.id) return;
        setIsLoading(true);
        getBatchJobs(venue.id, page, running, type)
            .then((resp) => {
                const mData = resp.data;
                setBatchJobsPage(resp.response?.data?._metadata?.pagination);
                setBatchJobs(mData);
                if (page === 0) {
                    const mRunning = (mData ?? []).filter((el) => {
                        return ![
                            JobBatchStatus.CANCELLED,
                            JobBatchStatus.DONE,
                            JobBatchStatus.FAIL,
                        ].includes(el.status);
                    });
                    setBatchRunning(mRunning);
                }
            })
            .catch(() => {
                setBatchJobsPage(undefined);
                setBatchJobs(undefined);
                setBatchRunning(undefined);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function fetchSurveyData() {
        if (!venue || !workingMap) {
            setSurveySessions(undefined);
            setSurveyQuality(undefined);
            return;
        }
        setIsLoading(true);
        getSurveySessions(workingMap.id)
            .then((resp) => {
                const lastUpdate = resp.data?.updatedAt;
                const result = resp.data?.sessions ?? [];
                setSurveySessions(result);
            })
            .catch((ex) => {
                setSurveySessions(undefined);
            })
            .finally(() => {
                setIsLoading(false);
            });
        fetchSurveyQuality();
    }

    function fetchSurveyQuality() {
        if (!workingMap) return;
        getSurveyQuality(workingMap.id)
            .then((resp) => {
                setSurveyQuality(resp.data);
            })
            .catch((ex) => {
                setSurveyQuality(undefined);
            });
    }

    function analyzeSurvey() {
        if (!venue || !workingMap) return;
        setSurveyQuality((x) => (x?.analyzeState ? { ...x, analyzeState: 'ANALYZING' } : x));
        analyzeSurveyQuality(workingMap.id)
            .then((resp) => {
                setSurveyQuality((x) => (!!x ? { ...x, ...resp.data } : x));
            })
            .catch((ex) => {
                setSurveyQuality((x) => (x?.analyzeState ? { ...x, analyzeState: 'FAILED' } : x));
            });
    }

    return (
        <VenueContext.Provider
            value={{
                isLoading,
                venue,
                fetchVenue,
                activeVenueId,
                switchVenue,
                workingMap,
                selectWorkingMap,
                venuePackages,
                fetchPackages,

                venueRtConfig,
                fetchVenueRtConfig,

                venueBoundConfig,
                fetchBoundConfig,

                beaconPlanList,
                fetchBeaconList,
                beaconPlan,
                fetchBeaconPlan,

                floorPlanMask,
                fetchFloorPlanMask,

                batchJobPage,
                batchJobs,
                batchRunning,
                fetchBatchJobs,

                surveySessions,
                fetchSurveyData,
                fetchSurveyQuality,
                surveyQuality,
                analyzeSurvey,
            }}
        >
            {children}
        </VenueContext.Provider>
    );
};
export default VenueContextProvider;
export const useVenueState = () => {
    const ctx = useContext(VenueContext);
    if (!ctx) {
        throw new Error('useVenue has to be used within <VenueContextProvider>');
    }
    return ctx;
};
