import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import { Empty, Flex, Spin, Table, Typography } from 'antd';
import { TableProps } from 'antd/lib';
import { useTraceContext } from 'providers/TraceProvider';
import { Link } from 'react-router-dom';

const MAX_VISIBLE_PAGES = 7;

const TraceQueryExecutionResultsCard = ({
    isMobile, executionId, query, page, perPage, isLoading, onPageChanged,
}: {
    isMobile: boolean,
    executionId: string | null,
    query: string | null,
    page: number,
    perPage: number,
    isLoading: boolean,
    onPageChanged: (page: number) => void,
}) => {
    const {
        executionCount,
        executionResults,
    } = useTraceContext();

    const availablePages = Object.keys(executionResults).map(Number).sort((a, b) => a - b);

    function handlePageClicked(page: number) {
        onPageChanged(page);
    }

    function _renderPagination() {
        const maxPage = Math.ceil(executionCount / perPage);
        const visiblePages: number[] = [];
        if (availablePages.length <= MAX_VISIBLE_PAGES) {
            visiblePages.push(...availablePages);
            if (availablePages.length < maxPage) {
                visiblePages.push(-1);
            }
        } else {
            visiblePages.push(availablePages[0]);
            const rangeIndex = Math.floor(MAX_VISIBLE_PAGES / 2);
            const middlePages = [];
            const currentIndex = availablePages.indexOf(page);
            let end = Math.min(maxPage, currentIndex + rangeIndex + 1, availablePages.length);
            let start = Math.max(1, page - (MAX_VISIBLE_PAGES - (end - currentIndex)) + 1);
            if (start <= 1) {
                start = 2;
                end = MAX_VISIBLE_PAGES;
            }
            if (start > 2) visiblePages.push(-1);
            for (let i = start; i <= end; i++) {
                if (availablePages.includes(i)) middlePages.push(i);
            }

            visiblePages.push(...middlePages);
            if (end < maxPage) visiblePages.push(-1);
        }


        return <Flex gap={12}>
            <a onClick={() => page === 1 ? false : handlePageClicked(page - 2)}><LeftOutlined /></a>
            {
                visiblePages.map((p, index) =>
                    <a key={`page-${p}-${index}`} onClick={() => p === -1 ? false : handlePageClicked(p - 1)}>
                        <Typography.Text
                            style={{ fontWeight: p === page ? 'bold' : undefined }}>{p === -1 ? '...' : p}</Typography.Text>
                    </a>)
            }
            <a onClick={() => page === maxPage ? false : handlePageClicked(page)}><RightOutlined /></a>
        </Flex>;
    }

    function _renderContent() {
        if (!executionId) return <Typography.Text type={'secondary'}>* Run a query or click "View Results" of previous
            executions</Typography.Text>;
        if (!executionResults[1]) return isLoading ? null : <Empty />;
        let hasPath = false;
        const columns: TableProps['columns'] = executionResults[1].length > 0 ? Object.keys(executionResults[1][0])
            .map(key => {
                if (key === '$path') {
                    hasPath = true;
                    return {
                        title: '*traceId',
                        dataIndex: key,
                        key,
                        render: (field) => {
                            const id = field.split('/').pop()
                                .split('_')[1].replace('.csv', '');
                            return <Link to={`/analysis/session/${id}`}>
                                {id}
                            </Link>;
                        },
                    };
                }
                if (key === 'id')
                    return {
                        title: key,
                        dataIndex: key,
                        key,
                        render: (field: number) => field + 1 + (page - 1) * perPage,
                    };

                return {
                    title: key,
                    dataIndex: key,
                    key,
                };
            }) : [];
        return <>
            <Typography.Text>Showing results of {executionId} (<strong>{executionCount.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong> records)</Typography.Text>
            <ProCard style={{ background: '#f0f0f0', margin: '0.5em 0' }}>
                <Typography.Text>{query}</Typography.Text>
            </ProCard>
            <Flex justify={'space-between'}>
                <Typography.Text
                    type="secondary">{hasPath && `* traceId is extracted from "$path" column`}</Typography.Text>
                {_renderPagination()}
            </Flex>
            <ProCard style={{ maxHeight: 400, overflow: 'auto' }}>
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={executionResults[page]}
                    scroll={{ x: true }}
                    size="small"
                    pagination={false}
                    loading={isLoading}
                />
            </ProCard>
        </>;
    }

    return <ProCard title={'Results'} style={{ marginTop: '1em' }} ghost={isMobile}>
        <Flex>{isLoading && <Spin />}</Flex>
        {_renderContent()}
    </ProCard>;
};

export default TraceQueryExecutionResultsCard;
