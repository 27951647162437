import ApiClient from 'utils/NetworkManager';

const statsBaseUrl = process.env.REACT_APP_STATS_API_BASE_URL;
export interface IdVersionDto {
    id: string;
    version: number;
    count?: number;
    desc?: string;
}

export interface VenueInfoDto {
    id: string;
    name: string;
    center: LatLngDto;
}

export interface LatLngDto {
    lat: number;
    lng: number;
}

export interface VenueCreateDto {
    projectId: string;
    name: string;
    center: LatLngDto;
    extId?: string;
}

export interface VenueDto extends VenueCreateDto {
    id: string;
    enabled: boolean;
}

export interface VenueDetailDto extends VenueDto {
    maps: VenueMapMetaDto[];
    publish?: IdVersionDto;
    config?: IdVersionDto;
    lastBuildAt?: Date;
}

export interface VenueCloneDto {
    projectId: string;
}

export interface CornersDto {
    tl: LatLngDto;
    tr: LatLngDto;
    br: LatLngDto;
    bl: LatLngDto;
}

export interface SizeDto {
    width: number;
    height: number;
}

export interface AlignmentDto {
    center: LatLngDto;
    angle: number;
    scale: number;
    corners: CornersDto;
    size: SizeDto;
    sizeInMeters: SizeDto;
}

export interface VenueMapCreateDto {
    id?: string;
    venueId: string;
    name: string;
    level: number;
    mapImg?: string;
    mapAlign?: AlignmentDto;
    boundary?: [number, number][];
}

export interface VenueMapDto extends VenueMapCreateDto {
    id: string;
}
export interface VenueMapMetaDto extends VenueMapDto {
    beaconPlanId?: string;
    coverage: number;
    augmentId?: string;
}

export interface VenueMapAlignDto {
    id: string;
    venueId: string;
    mapAlign?: AlignmentDto;
}

export interface VenuePackageDto {
    id: string;
    desc: string;
    version: number;
    path: string;
    createdAt: Date;
    publishAt: Date;
    isLive: boolean;
    note?: { ts: Date; msg: string };
}

export interface VenuePackageCreateDto {
    venueId: string;
    desc: string;
    fileName: string;
}

export interface VenuePackagePublishDto {
    id: string;
    desc: string;
}

export interface ConfigRule {
    brand?: string;
    os?: string;
    osVerFrom?: string;
    osVerTo?: string;
    sdkVerFrom?: string;
    sdkVerTo?: string;
}

export interface VenueRuntimeConfigDto {
    type: string;
    desc: string;
    content: string;
    enabled: boolean;
    priority: number;
    rules: ConfigRule;
}

export interface VenueConfigGroup {
    runtime: VenueRuntimeConfigInfoDto[];
    package: VenueRuntimeConfigInfoDto;
}

export interface VenueRuntimeConfigInfoDto extends VenueRuntimeConfigDto {
    id: string;
    version: string;
    updatedAt: Date;
}

export interface VenueRuntimeConfigEditDto extends VenueRuntimeConfigDto {
    id: string;
}

export interface ConfigSimulateDto {
    keyPrefix: string;
    platform?: string;
    brand?: string;
    osVersion?: string;
    sdkVersion?: string;
}

export interface ConfigSimulateResultDto {
    version?: string;
    content?: string;
}

export interface ActivityLogDto {
    event: number;
    detail: string;
    date: Date;
}

export interface VenueBoundConfigDto {
    beacons: { id: string; thres: number }[];
    radius: number;
    polygon: [number, number][];
}

export interface BeaconPlanCreateDto {
    mapId: string;
    name: string;
    beacons: any;
}

export interface FloorPlanMaskDto {
    mapId: string;
    boundary?: FloorPlanMaskItem;
    accessible: FloorPlanMaskItem[];
    inaccessible: FloorPlanMaskItem[];
    switchingZone: FloorPlanMaskItem[];
    genMask?: boolean;
}

export interface FloorPlanMaskItem {
    id: string | number;
    name: string;
    polygon: number[][] /* [lng, lat] */;
    enabled: boolean;
}

export interface JobBatchParmaDto {
    updateFp: boolean;
    minimal?: boolean;
    genBeacon?: boolean;
    clean?: boolean;
    augment?: boolean;
}

export interface JobBatchCreateDto {
    venueId: string;
    type: 'SURVEY' | 'CROWDSOURCE';
    params: JobBatchParmaDto;
}

export enum JobBatchStatus {
    PENDING = 'PENDING',
    GENERATING = 'GENERATING',
    CONVERTING = 'CONVERTING',
    PACKAGING = 'PACKAGING',
    FAIL = 'FAIL',
    DONE = 'DONE',
    CANCELLED = 'CANCELLED',
}

export interface JobBatchInfoDto {
    id: string;
    type: 'SURVEY' | 'CROWDSOURCE';
    status: JobBatchStatus;
    error?: string;
    createdAt: string;
}

export interface SurveyRevisionDto {
    mapId: string;
    blacklist: string[];
}

export interface SurveyPreviewDto {
    sessions: SurveySessionDto[];
    updatedAt?: Date;
}

export interface SurveySessionDto {
    id: string;
    name?: string;
    deviceModel: string;
    deviceOs: string;
    recordAt: Date;
    paths: SurveyPathDto[];
    built: boolean;
}

export interface SurveyPathDto {
    id: string;
    name: string;
    enabled: boolean;
    polyline: number[][];
    recordAt: Date;
    duration: number;
    distance: number;
    error?: string;
    sample?: { wifi: number; ble: number; mov: number };
}

export interface SurveyQualityDto {
    magneticQlt?: string;
    wifiCov?: string;
    wifiQlt?: string;
    beaconQlt?: string;
    analyzeAt?: Date;
    analyzeState?: string; // OUTDATED / ANALYZING / DONE
    augmentState?: string; // READY / AVAILABLE / NOT_READY
}

export interface ConvertAlignDto {
    mapAlign?: Partial<AlignmentDto>;
    masks: boolean;
    beacons: boolean;
}

export enum AugBatchStatus {
    PENDING = 'PENDING',
    GENERATING = 'GENERATING',
    CONVERTING = 'CONVERTING',
    FORMATTED = 'FORMATTED',
    READY = 'READY',
    FAIL = 'FAIL',
}

export interface AugmentCreateDto {
    blockSize: number;
    denseIds: string[];
}

export interface AugmentedBatchDto {
    id: string;
    outdated: boolean;
    activated: boolean;
    status: AugBatchStatus | string;
    createdAt: Date;
    blockSize: number;
}

export interface AugmentedAlignDto {
    scaleX: number;
    scaleY: number;
    offsetX: number;
    offsetY: number;
    blockSize?: number;
    useMask?: boolean;
}
export interface AugmentedUpdateDto {
    augId: string;
    activate: boolean;
    align?: AugmentedAlignDto;
}

export interface AugmentedFpDto {
    augId: string;
    align: AugmentedAlignDto;
    blockSize: number;
    rawByte: number;
    curByte: number;
    points: [number, number][];
}

export function toCornersDto(input: [number, number][]): CornersDto {
    return {
        tl: { lat: input[0][0], lng: input[0][1] },
        tr: { lat: input[1][0], lng: input[1][1] },
        br: { lat: input[2][0], lng: input[2][1] },
        bl: { lat: input[3][0], lng: input[3][1] },
    };
}

export const createVenue = (data: VenueCreateDto) => ApiClient.post<VenueDto>(`/venue`, data);
export const updateVenue = (id: string, data: VenueDto) => ApiClient.put(`/venue/${id}`, data);
export const deleteVenue = (id: string) => ApiClient.delete(`/venue/${id}`);
export const getVenue = (id: string, extId?: string, handleException?: boolean) =>
    ApiClient.get<VenueDetailDto>(extId ? `/venue/ext?extId=${extId}` : `/venue/${id}`, undefined, {
        handleException,
    });
export const cloneVenue = (id: string, data: VenueCloneDto) =>
    ApiClient.post<VenueCloneDto>(`/venue/${id}/clone`, data);
export const createVenueMap = (id: string, data: VenueMapCreateDto) =>
    ApiClient.post<VenueMapCreateDto>(`/venue/${id}/map`, data);
export const deleteVenueMap = (id: string, mapId: string) =>
    ApiClient.delete(`/venue/${id}/map?mapId=${mapId}`);
export const updateVenueMap = (id: string, data: VenueMapDto) =>
    ApiClient.put<VenueMapDto>(`/venue/${id}/map`, data);
export const updateVenueMapAlign = (id: string, data: VenueMapAlignDto) =>
    ApiClient.put(`/venue/${id}/map/align`, data);
export const getVenuePackages = (venueId: string, page: string) =>
    ApiClient.get<VenuePackageDto>('/package/list', { venueId, limit: 10, page });
export const createVenuePackage = (data: VenuePackageCreateDto) =>
    ApiClient.post<VenuePackageCreateDto>(`/package`, data);
export const publishVenuePackage = (data: VenuePackagePublishDto) =>
    ApiClient.post<VenuePackagePublishDto>(`/package/publish`, data);

export const getActivityLog = (venueId: string, page: number, limit: number = 20) =>
    ApiClient.get<ActivityLogDto[]>(`/venue/${venueId}/activities?limit=${limit}&page=${page}`);
export const createBeaconPlan = (data: BeaconPlanCreateDto) =>
    ApiClient.post<BeaconPlanCreateDto>(`/beacon/plan`, data);

export const getFloorplanMask = (id: string, mapId: string) =>
    ApiClient.get<FloorPlanMaskDto>(`/venue/${id}/map/mask?mapId=${mapId}`);
export const updateFloorplanMask = (id: string, data: FloorPlanMaskDto) =>
    ApiClient.put(`/venue/${id}/map/mask`, data);

export const getVenueRuntimeConfig = (venueId: string) =>
    ApiClient.get<VenueConfigGroup>(`/rtconfig/${venueId}/list`);
export const createVenueRuntimeConfig = (venueId: string, data: VenueRuntimeConfigDto) =>
    ApiClient.post(`/rtconfig/${venueId}`, data);
export const updateVenueRuntimeConfig = (venueId: string, data: VenueRuntimeConfigEditDto) =>
    ApiClient.put(`/rtconfig/${venueId}`, data);
export const deleteVenueRuntimeConfig = (venueId: string, configId: string) =>
    ApiClient.delete(`/rtconfig/${venueId}?id=${configId}`);
export const importRuntimeConfig = (parentId: string, data: VenueRuntimeConfigDto[]) =>
    ApiClient.post(`/rtconfig/${parentId}/import`, data);

export const getVenueActiveBoundConfig = (venueId: string) =>
    ApiClient.get<VenueBoundConfigDto>(`/rtconfig/${venueId}/bound`);
export const updateVenueActiveBoundConfig = (venueId: string, data: VenueBoundConfigDto) =>
    ApiClient.post(`/rtconfig/${venueId}/bound`, data);

export const simulateConfig = (parentId: string, data: ConfigSimulateDto) =>
    ApiClient.post<ConfigSimulateResultDto>(`/rtconfig/${parentId}/simulate`, data);

export const createBatchJob = (data: JobBatchCreateDto) =>
    ApiClient.post<JobBatchInfoDto>(`/batch`, data);
export const getBatchJobs = (venueId: string, page: number = 0, running?: boolean, type?: string) =>
    ApiClient.get<JobBatchInfoDto[]>(`/batch`, { venueId, limit: 5, page, running, type });
export const getBatchInfo = (id: string) => ApiClient.get<JobBatchInfoDto>(`/batch/${id}`);
export const cancelBatchJob = (id: string) => ApiClient.put<JobBatchInfoDto>(`/batch/${id}/cancel`);

export const updateSurveyRevision = (venueId: string, data: SurveyRevisionDto) =>
    ApiClient.put(`/survey/${venueId}/revision`, data);
export const getSurveySessions = (mapId: string) =>
    ApiClient.get<SurveyPreviewDto>(`${statsBaseUrl}/survey/${mapId}/info`, null, {
        handleException: false,
    });
export const getSurveyQuality = (mapId: string) =>
    ApiClient.get<SurveyQualityDto>(`/survey/${mapId}/quality`, null);
export const analyzeSurveyQuality = (mapId: string) =>
    ApiClient.post<SurveyPreviewDto>(`${statsBaseUrl}/survey/${mapId}/analyze`);
export const generateMask = (mapId: string) =>
    ApiClient.get<FloorPlanMaskDto>(`${statsBaseUrl}/survey/${mapId}/mask`);
export const convertAlignment = (mapId: string, data: ConvertAlignDto) =>
    ApiClient.post<Omit<ConvertAlignDto, 'mapAlign'>>(`${statsBaseUrl}/map/${mapId}/convert`, data);

export const getAugmentedList = (mapId: string, page: number = 0, limit: number = 10) =>
    ApiClient.get<AugmentedBatchDto[]>(`/survey/${mapId}/aug/list?limit=${limit}&page=${page}`);
export const requestAugmentation = (mapId: string, data: AugmentCreateDto) =>
    ApiClient.post<AugmentedBatchDto>(`/survey/${mapId}/aug`, data);
export const updateAugmentConfig = (mapId: string, data: AugmentedUpdateDto) =>
    ApiClient.put<AugmentedBatchDto[]>(`/survey/${mapId}/aug`, data);
export const getAugmented = (mapId: string, augId: string, block?: number) =>
    ApiClient.get<AugmentedFpDto>(
        `${statsBaseUrl}/survey/${mapId}/augment/${augId}`,
        block ? { block } : undefined,
    );
