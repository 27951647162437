import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import { BeaconDto } from 'apis/BeaconApi';
import {
    Button,
    Checkbox,
    ConfigProvider,
    Divider,
    Input,
    InputNumber,
    Space,
    Tooltip,
} from 'antd';
import {
    AimOutlined,
    EnvironmentOutlined,
    WarningTwoTone,
    InfoCircleOutlined,
} from '@ant-design/icons';
import {
    ActionType,
    EditableFormInstance,
    EditableProTable,
    ProColumns,
} from '@ant-design/pro-table';
import { useAuth } from 'providers/AuthProvider';

type BeaconRowId = number | string;

export type BeaconTableProp = {
    sourceId?: string;
    data: BeaconDto[];
    editable: boolean;
    onLocate: (id: BeaconRowId) => void;
    onDelete: (id: BeaconRowId) => void;
    onHover: (id?: BeaconRowId) => void;
    onSetMarker: (id?: BeaconRowId, label?: string) => void;
    onChanged: (data: BeaconDto[]) => void;
};

export type BeaconTableRef = {
    reset: () => void;
    getData: () => { result: BeaconDto[]; error: boolean | undefined } | undefined;
    scrollTo: (index: number) => void;
    scrollToId: (index: BeaconRowId) => void;
    replaceAll: (key: string, value: any, ids?: BeaconRowId[]) => number;
    setHoverId: (id?: BeaconRowId) => void;
    filter: (keyword?: string) => void;
};

const BeaconTableSection = React.forwardRef<BeaconTableRef, BeaconTableProp>((props, ref) => {
    const { isSuperAdmin } = useAuth();
    const nativeTableRef = useRef<React.LegacyRef<HTMLTableElement>>();
    const actionRef = useRef<ActionType>();
    const tableFormRef = useRef<EditableFormInstance<BeaconDto>>();
    const [editingId, setEditingId] = useState<string | undefined>();
    const [keyword, setKeyword] = useState<string | undefined>();
    const allowHover = props.data.length < 500;

    const displayMode = useMemo(() => {
        // 0: ProTable; 1: NativeTable; 2: Native View Table
        if (props.editable === false) {
            return 2;
        } else if (props.data.length < 200) {
            return 0;
        } else if (props.data.length < 1000) {
            return 1;
        } else {
            return 2;
        }
    }, [props.data]);

    const isVisible = (el: BeaconDto) => {
        if (!keyword || keyword.trim().length === 0) return true;
        else {
            const mKeyword = keyword.toLowerCase();
            return (
                el.uuid.toLowerCase().includes(mKeyword) ||
                el.major.toString().includes(mKeyword) ||
                el.minor.toString().includes(mKeyword)
            );
        }
    };

    const getNumAndValidate = (
        el: any,
        option?: { min?: number; max?: number; fallback?: number; required?: boolean },
    ) => {
        if (!el) return option?.fallback;
        const strValue: string = el?.value ?? el?.dataset?.colVal;
        const numValue: number = Number(strValue);
        if (strValue === undefined || strValue === '' || isNaN(numValue)) {
            if (option?.required === false) return undefined;
            el.style.border = '1px solid red';
            return option?.fallback;
        }
        el.style.border = 'none';
        if (option?.min !== undefined && numValue < option.min) return option.min;
        if (option?.max !== undefined && numValue > option.max) return option.max;
        return numValue;
    };

    useImperativeHandle(ref, () => ({
        reset() {
            if (actionRef.current) actionRef.current.reload();
            if (tableFormRef.current) tableFormRef.current.resetFields();
            // if (nativeTableRef.current) nativeTableRef.current.reset();
        },
        getData() {
            if (displayMode == 2) {
                if (!nativeTableRef.current) return undefined;
                const tableEl = nativeTableRef.current as any;
                const trArray: any[] = Array.from(tableEl.tBodies[0].rows);
                const result: BeaconDto[] = [];
                let noError = true;
                for (let tr of trArray) {
                    const strId = tr.dataset.rowKey;
                    const record = props.data.find((x) => `${x.id}` === strId);
                    const uuidEl = tr.querySelector('td[data-col-key="uuid"]');
                    const strLowPower = tr.querySelector('td[data-col-key="isLowPower"]')?.dataset
                        ?.colVal;
                    const strDoorway = tr.querySelector('td[data-col-key="isDoorway"]')?.dataset
                        ?.colVal;
                    const strUuid = uuidEl?.dataset.colVal;
                    const mThreshold = getNumAndValidate(
                        tr.querySelector('td[data-col-key="threshold"]'),
                        {
                            min: -9999,
                            max: 0,
                            fallback: record?.threshold,
                        },
                    );
                    const row = {
                        id: strId,
                        uuid: strUuid,
                        major: getNumAndValidate(tr.querySelector('td[data-col-key="major"]')),
                        minor: getNumAndValidate(tr.querySelector('td[data-col-key="minor"]')),
                        weight: getNumAndValidate(tr.querySelector('td[data-col-key="weight"]'), {
                            fallback: record?.weight,
                        }),
                        threshold: mThreshold,
                        iosThreshold: getNumAndValidate(
                            tr.querySelector('td[data-col-key="iosThreshold"]'),
                            {
                                min: -9999,
                                max: 0,
                                fallback: record?.iosThreshold ?? mThreshold,
                            },
                        ),
                        txPower: getNumAndValidate(tr.querySelector('td[data-col-key="txPower"]'), {
                            min: -9999,
                            max: 0,
                            required: false,
                        }),
                        isLowPower: strLowPower ? strLowPower === 'true' : false,
                        isDoorway: strDoorway ? strDoorway === 'true' : false,
                    };
                    if (!row.uuid || row.uuid === '') {
                        uuidEl.classList.add('ant-input-status-error');
                    } else {
                        uuidEl.classList.remove('ant-input-status-error');
                    }
                    noError =
                        noError &&
                        row.uuid !== '' &&
                        row.major !== undefined &&
                        row.minor !== undefined;
                    result.push(row as BeaconDto);
                }
                return { result, error: !noError };
            } else if (displayMode == 1) {
                if (!nativeTableRef.current) return undefined;
                const tableEl = nativeTableRef.current as any;
                const trArray: any[] = Array.from(tableEl.tBodies[0].rows);
                const result: BeaconDto[] = [];
                let noError = true;
                for (let tr of trArray) {
                    const uuidEl = tr.querySelector('input[name="uuid"]');
                    const mThreshold = getNumAndValidate(
                        tr.querySelector('input[name="threshold"]'),
                        {
                            min: -9999,
                            max: 0,
                        },
                    );
                    const row = {
                        id: tr.dataset.rowKey,
                        uuid: uuidEl?.value,
                        major: getNumAndValidate(tr.querySelector('input[name="major"]')),
                        minor: getNumAndValidate(tr.querySelector('input[name="minor"]')),
                        weight: getNumAndValidate(tr.querySelector('input[name="weight"]')),
                        threshold: mThreshold,
                        iosThreshold: getNumAndValidate(
                            tr.querySelector('input[name="iosThreshold"]'),
                            {
                                min: -9999,
                                max: 0,
                                fallback: mThreshold,
                            },
                        ),
                        txPower: getNumAndValidate(tr.querySelector('input[name="txPower"]'), {
                            min: -9999,
                            max: 0,
                            required: false,
                        }),
                        isLowPower: tr.querySelector('input[name="isLowPower"]')?.checked ?? false,
                        isDoorway: tr.querySelector('input[name="isDoorway"]')?.checked ?? false,
                    };
                    if (!row.uuid || row.uuid === '') {
                        uuidEl.classList.add('ant-input-status-error');
                    } else {
                        uuidEl.classList.remove('ant-input-status-error');
                    }
                    noError =
                        noError &&
                        row.uuid !== '' &&
                        row.major !== undefined &&
                        row.minor !== undefined;
                    result.push(row as BeaconDto);
                }
                return { result, error: !noError };
            } else {
                if (!tableFormRef.current) return undefined;
                const result = [...props.data];
                const mRows = tableFormRef.current.getFieldsValue();
                Object.entries(mRows).forEach(([key, value]) => {
                    const mIndex = result.findIndex((x) => `${x.id}` === key);
                    if (mIndex > -1) {
                        result[mIndex] = { ...result[mIndex], ...value };
                    } else {
                        result.push(value);
                    }
                });
                return { result, error: undefined };
            }
        },
        scrollTo(index: number) {
            const totalRow = props.data.length;
            const tableBody = document.querySelector('.ant-table-body');
            tableBody?.scrollTo(0, (tableBody?.scrollHeight / totalRow) * index);
        },
        scrollToId(id: BeaconRowId) {
            const element = document.querySelector(`[data-row-key="${id}"]`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        replaceAll(key: string, value: any, ids?: BeaconRowId[]) {
            if (value == undefined) return 0;
            let counter = 0;
            if (displayMode === 0) {
                const data = tableFormRef.current!.getFieldsValue();
                Object.entries(data).forEach(([rowKey, row]) => {
                    if (!ids || ids.includes(rowKey)) {
                        row[key] = value;
                        counter++;
                    }
                });
                tableFormRef.current!.setFieldsValue(data);
            } else if (displayMode === 1) {
                const tableEl = nativeTableRef.current as any;
                const trArray: any[] = Array.from(tableEl.tBodies[0].rows);
                for (let tr of trArray) {
                    const rowKey = tr?.dataset?.rowKey;
                    if (!!ids && !ids.includes(rowKey)) continue;
                    const el = tr.querySelector(`input[name="${key}"]`);
                    if (el && typeof value === 'boolean' && el.checked !== value) {
                        el.click();
                    } else if (el) {
                        el.value = value;
                    }
                    counter++;
                }
            } else if (props.onChanged) {
                const cloned = [];
                for (let x of props.data) {
                    if (!ids || ids.includes(x.id.toString())) {
                        cloned.push({ ...x, [key]: value });
                        counter++;
                    } else {
                        cloned.push(x);
                    }
                }
                props.onChanged(cloned);
            }
            return counter;
        },
        setHoverId(id?: BeaconRowId) {
            const allRows = document.getElementsByClassName('ble-tr');
            const strId = id === undefined ? undefined : `${id}`;
            for (let i = 0; i < allRows.length; i++) {
                if (id === undefined) {
                    allRows[i].classList.remove('tr-hover-row');
                } else {
                    const rowKey = (allRows[i] as any)?.dataset?.rowKey;
                    if (rowKey === strId) {
                        allRows[i].classList.add('tr-hover-row');
                    } else {
                        allRows[i].classList.remove('tr-hover-row');
                    }
                }
            }
        },
        filter(keyword?: string) {
            setKeyword(keyword);
        },
    }));

    const columns: ProColumns<BeaconDto>[] = [
        {
            title: '',
            dataIndex: 'id',
            width: 20,
            readonly: true,
            editable: false,
            formItemProps: { style: { textAlign: 'center' } },
            render: (_, record) => {
                if (record && record.lat && record.lng) {
                    return (
                        <Button type="text" size="small" onClick={() => props.onLocate(record.id)}>
                            <AimOutlined size={12} style={{ color: '#aaa' }} />
                        </Button>
                    );
                } else {
                    return <WarningTwoTone twoToneColor="red" title="Missing beacon position" />;
                }
            },
        },
        {
            title: 'UUID',
            dataIndex: 'uuid',
            valueType: 'text',
            width: 280,
            fieldProps: {
                placeholder: '',
                style: { textOverflow: 'ellipsis' },
                size: 'small',
            },
            formItemProps: { rules: [{ required: true }] },
        },
        {
            title: 'Major',
            dataIndex: 'major',
            valueType: 'digit',
            width: 65,
            fieldProps: { size: 'small', className: 'w100', placeholder: 0 },
            formItemProps: { rules: [{ required: true }] },
            render: (dom, entity) => entity.major,
        },
        {
            title: 'Minor',
            dataIndex: 'minor',
            valueType: 'digit',
            width: 65,
            fieldProps: { size: 'small', className: 'w100', placeholder: 0 },
            formItemProps: { rules: [{ required: true }] },
            render: (dom, entity) => entity.minor,
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            valueType: 'digit',
            width: 65,
            fieldProps: { size: 'small', className: 'w100', placeholder: 1.0 },
            formItemProps: { rules: [{ required: true }] },
            tooltip: 'Weight on floor classier',
            hidden: !isSuperAdmin,
        },
        {
            title: 'Threshold',
            dataIndex: 'threshold',
            valueType: 'digit',
            width: 85,
            fieldProps: {
                placeholder: 0,
                className: 'w100',
                max: 0,
                min: -9999,
                size: 'small',
            },
            formItemProps: { rules: [{ required: true }] },
            tooltip: 'Scan threshold',
            hidden: !isSuperAdmin,
        },
        {
            title: 'Threshold (iOS)',
            dataIndex: 'iosThreshold',
            valueType: 'digit',
            width: 85,
            fieldProps: {
                placeholder: 0,
                className: 'w100',
                max: 0,
                min: -9999,
                size: 'small',
            },
            tooltip: 'Scan threshold (iOS)',
            hidden: !isSuperAdmin,
        },
        {
            title: 'txPower',
            dataIndex: 'txPower',
            valueType: 'digit',
            width: 75,
            fieldProps: {
                placeholder: '',
                className: 'w100',
                max: 0,
                min: -9999,
                size: 'small',
            },
        },
        {
            title: 'Low Power',
            dataIndex: 'isLowPower',
            valueType: 'switch',
            width: 90,
            fieldProps: {
                style: { marginLeft: 12 },
                checkedChildren: 'Yes',
                unCheckedChildren: '×',
            },
            tooltip: 'Low power beacon might improve floor detection.',
        },
        {
            title: 'Doorway',
            dataIndex: 'isDoorway',
            valueType: 'switch',
            width: 80,
            fieldProps: {
                style: { marginLeft: 12 },
                checkedChildren: 'Yes',
                unCheckedChildren: '×',
            },
            tooltip: 'Beacon near doorway might used for venue detection.',
        },
        {
            title: 'Lat',
            dataIndex: 'lat',
            valueType: 'digit',
            initialValue: 0,
            hidden: true,
        },
        {
            title: 'Lng',
            dataIndex: 'lng',
            valueType: 'digit',
            initialValue: 0,
            hidden: true,
        },
        {
            title: 'Action',
            valueType: 'option',
            width: 120,
            hidden: !props.editable,
            render: (text, record, _, action) => [
                <a
                    key="pin"
                    onClick={() => props.onSetMarker(record.id, record.major.toString())}
                    title="Set position"
                >
                    <EnvironmentOutlined size={12} />
                </a>,
                <Divider key="d01" type="vertical" />,
                <a
                    key="editable"
                    onClick={() => {
                        action?.startEditable?.(record.id);
                    }}
                >
                    Edit
                </a>,
                <a key="delete" onClick={() => props.onDelete(record.id)}>
                    Delete
                </a>,
            ],
        },
    ];

    const _renderProTable = () => {
        return (
            <ConfigProvider theme={{ components: { Table: { rowHoverBg: '#bae0ff80' } } }}>
                <EditableProTable<BeaconDto>
                    editableFormRef={tableFormRef}
                    actionRef={actionRef}
                    style={{ margin: 0, padding: 0 }}
                    cardProps={{ bodyStyle: { margin: 0, padding: 0 } }}
                    defaultSize="small"
                    size="small"
                    ghost
                    rowKey="id"
                    scroll={{
                        x: 900,
                        scrollToFirstRowOnChange: true,
                        y: '25vh',
                    }}
                    columns={columns}
                    recordCreatorProps={false}
                    value={props.data}
                    onChange={(values) => {
                        props.onChanged([...values]);
                    }}
                    onRow={
                        allowHover
                            ? (record, rowIndex) => {
                                  return {
                                      onMouseOver: (event: any) => {
                                          props.onHover(record.id);
                                      },
                                      onMouseLeave: (event: any) => {
                                          props.onHover(undefined);
                                      },
                                  };
                              }
                            : undefined
                    }
                    rowClassName={(record, key) => {
                        if (!isVisible(record)) return 'hidden';
                        if (!record.lat && !record.lng) {
                            return 'ble-tr tr-error-row';
                        } else {
                            return 'ble-tr';
                        }
                    }}
                    editable={{
                        type: allowHover ? 'multiple' : 'single',
                        actionRender: (row, conf, dom) => {
                            const mActions = allowHover
                                ? [
                                      <a
                                          key="pin"
                                          onClick={() => {
                                              let mLabel = row.major.toString();
                                              if (tableFormRef.current) {
                                                  const mRows =
                                                      tableFormRef.current.getFieldsValue();
                                                  const entry = Object.entries(mRows).find(
                                                      ([key, value]) => `${key}` === `${row.id}`,
                                                  );
                                                  mLabel = entry?.[1]?.major ?? mLabel;
                                              }
                                              props.onSetMarker(row.id, mLabel);
                                          }}
                                          title="Set position"
                                      >
                                          <EnvironmentOutlined size={12} />
                                      </a>,
                                      <Divider key="d01" type="vertical" />,
                                  ]
                                : [dom.save, dom.cancel];
                            mActions.push(
                                <a key="delete" onClick={() => props.onDelete(row.id)}>
                                    Delete
                                </a>,
                            );
                            return mActions;
                        },
                        editableKeys: allowHover ? props.data.map((x) => x.id) : undefined,
                        onlyOneLineEditorAlertMessage:
                            'Only one row can be edited at the same time',
                        onlyAddOneLineAlertMessage: 'Only one row can be added at the same time',
                    }}
                    className="tiny-table"
                />
            </ConfigProvider>
        );
    };

    const syncInputData = (id: string) => {
        const idx = props.data.findIndex((el) => `${el.id}` === id);
        if (idx === -1) return;
        const cloned = [...props.data];
        const tableEl = nativeTableRef.current as any;
        const tr = tableEl.querySelector(`tr[data-row-key="${id}"]`);
        if (!tr) return;

        cloned[idx] = {
            ...cloned[idx],
            uuid: tr.querySelector('input[name="uuid"]')?.value,
            major: getNumAndValidate(tr.querySelector('input[name="major"]')),
            minor: getNumAndValidate(tr.querySelector('input[name="minor"]')),
            weight: getNumAndValidate(tr.querySelector('input[name="weight"]'), {
                fallback: cloned[idx].weight,
            }),
            threshold: getNumAndValidate(tr.querySelector('input[name="threshold"]'), {
                min: -9999,
                max: 0,
                fallback: cloned[idx].threshold,
            }),
            iosThreshold: getNumAndValidate(tr.querySelector('input[name="iosThreshold"]'), {
                min: -9999,
                max: 0,
                fallback: cloned[idx].iosThreshold,
            }),
            txPower: getNumAndValidate(tr.querySelector('input[name="txPower"]'), {
                min: -9999,
                max: 0,
                required: false,
            }),
            isLowPower: tr.querySelector('input[name="isLowPower"]')?.checked ?? false,
            isDoorway: tr.querySelector('input[name="isDoorway"]')?.checked ?? false,
        } as any;
        console.log('Edit done:', cloned[idx]);
        if (props.onChanged) props.onChanged(cloned);
        return;
    };
    const _renderNativeTable = (editable?: boolean) => {
        const __renderInputRow = (el: BeaconDto, editMode?: boolean) => {
            const visibleCls = isVisible(el) ? '' : 'hidden';
            return (
                <tr
                    data-row-key={el.id}
                    key={`tr-row-${el.id}`}
                    className={`ble-tr ${!el.lat && !el.lng ? 'tr-error-row' : ''} ${visibleCls}`}
                    onMouseOver={() => props.onHover(el.id)}
                    onMouseLeave={() => props.onHover(undefined)}
                >
                    <td>
                        {el && el.lat && el.lng ? (
                            <Button type="text" size="small" onClick={() => props.onLocate(el.id)}>
                                <AimOutlined size={12} style={{ color: '#aaa' }} />
                            </Button>
                        ) : (
                            <WarningTwoTone twoToneColor="red" title="Missing beacon position" />
                        )}
                    </td>
                    <td>
                        <Input
                            name="uuid"
                            size="small"
                            defaultValue={el.uuid}
                            type="text"
                            required
                        />
                    </td>
                    <td>
                        <InputNumber
                            name="major"
                            size="small"
                            defaultValue={el.major}
                            placeholder="0"
                            min={0}
                            max={65535}
                            type="number"
                        />
                    </td>
                    <td>
                        <InputNumber
                            name="minor"
                            size="small"
                            defaultValue={el.minor}
                            placeholder="0"
                            min={0}
                            max={65535}
                            type="number"
                        />
                    </td>
                    {isSuperAdmin ? (
                        <>
                            <td>
                                <InputNumber
                                    name="weight"
                                    size="small"
                                    defaultValue={el.weight}
                                    placeholder="1"
                                    type="number"
                                />
                            </td>
                            <td>
                                <InputNumber
                                    name="threshold"
                                    size="small"
                                    defaultValue={el.threshold}
                                    placeholder="-75"
                                    min={-9999}
                                    max={0}
                                    type="number"
                                />
                            </td>
                            <td>
                                <InputNumber
                                    name="iosThreshold"
                                    size="small"
                                    defaultValue={el.iosThreshold}
                                    placeholder="-75"
                                    min={-9999}
                                    max={0}
                                    type="number"
                                />
                            </td>
                        </>
                    ) : undefined}
                    <td>
                        <InputNumber
                            name="txPower"
                            size="small"
                            defaultValue={el.txPower}
                            min={-9999}
                            max={0}
                            type="number"
                        />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Checkbox name="isLowPower" defaultChecked={el.isLowPower} />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <Checkbox name="isDoorway" defaultChecked={el.isDoorway} />
                    </td>
                    {props.editable ? (
                        <td>
                            {editMode === true ? undefined : (
                                <a
                                    key="pin"
                                    onClick={() => props.onSetMarker(el.id, el.major.toString())}
                                    title="Set position"
                                >
                                    <EnvironmentOutlined size={12} />
                                </a>
                            )}
                            <Divider key="d01" type="vertical" />
                            <a key="delete" onClick={() => props.onDelete(el.id)}>
                                Delete
                            </a>
                            {editMode === true ? (
                                <a
                                    key="done"
                                    onClick={() => {
                                        setEditingId((x) => {
                                            if (!!x) syncInputData(x);
                                            return undefined;
                                        });
                                    }}
                                    style={{ marginLeft: 12 }}
                                >
                                    Done
                                </a>
                            ) : undefined}
                        </td>
                    ) : undefined}
                </tr>
            );
        };
        const __renderViewRow = (el: BeaconDto) => {
            const visibleCls = isVisible(el) ? '' : 'hidden';
            return (
                <tr
                    data-row-key={el.id}
                    key={`tr-row-${el.id}`}
                    className={`ble-tr ${!el.lat && !el.lng ? 'tr-error-row' : ''} ${visibleCls}`}
                >
                    <td>
                        {el && el.lat && el.lng ? (
                            <Button type="text" size="small" onClick={() => props.onLocate(el.id)}>
                                <AimOutlined size={12} style={{ color: '#aaa' }} />
                            </Button>
                        ) : (
                            <WarningTwoTone twoToneColor="red" title="Missing beacon position" />
                        )}
                    </td>
                    <td data-col-key="uuid" data-col-val={el.uuid}>
                        {el.uuid}
                    </td>
                    <td data-col-key="major" data-col-val={el.major}>
                        {el.major}
                    </td>
                    <td data-col-key="minor" data-col-val={el.minor}>
                        {el.minor}
                    </td>
                    {isSuperAdmin ? (
                        <>
                            <td data-col-key="weight" data-col-val={el.weight}>
                                {el.weight}
                            </td>
                            <td data-col-key="threshold" data-col-val={el.threshold}>
                                {el.threshold}
                            </td>
                            <td data-col-key="iosThreshold" data-col-val={el.iosThreshold}>
                                {el.iosThreshold}
                            </td>
                        </>
                    ) : undefined}
                    <td data-col-key="txPower" data-col-val={el.txPower}>
                        {el.txPower}
                    </td>
                    <td data-col-key="isLowPower" data-col-val={el.isLowPower}>
                        {el.isLowPower ? 'Yes' : '-'}
                    </td>
                    <td data-col-key="isDoorway" data-col-val={el.isDoorway}>
                        {el.isDoorway ? 'Yes' : '-'}
                    </td>
                    {props.editable ? (
                        <td>
                            <a
                                key="pin"
                                onClick={() => props.onSetMarker(el.id, el.major.toString())}
                                title="Set position"
                            >
                                <EnvironmentOutlined size={12} />
                            </a>
                            <Divider key="d01" type="vertical" />
                            <a key="delete" onClick={() => props.onDelete(el.id)}>
                                Delete
                            </a>
                            <a
                                key="edit"
                                onClick={() => setEditingId(el.id.toString())}
                                style={{ marginLeft: 12 }}
                            >
                                Edit
                            </a>
                        </td>
                    ) : undefined}
                </tr>
            );
        };

        return (
            <>
                <div style={{ maxHeight: '25vh', overflowY: 'auto' }}>
                    <table className="w100 ble-table" ref={nativeTableRef as any}>
                        <thead
                            style={{
                                backgroundColor: '#fafafa',
                                color: 'rgba(0,0,0,0.88)',
                            }}
                        >
                            <tr>
                                {columns
                                    .filter((x) => !x.hidden)
                                    .map((x) => {
                                        return (
                                            <th
                                                style={{
                                                    textAlign: 'left',
                                                    width: x.width ?? 'auto',
                                                }}
                                                key={`th-${x.dataIndex}`}
                                            >
                                                <Space size={'small'}>
                                                    {x.title?.toString()}
                                                    {x.tooltip ? (
                                                        <Tooltip title={x.tooltip.toString()}>
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    ) : undefined}
                                                </Space>
                                            </th>
                                        );
                                    })}
                            </tr>
                        </thead>
                        <tbody key={props.sourceId}>
                            {props.data.map((el) => {
                                if (!editable && `${el.id}` === editingId) {
                                    return __renderInputRow(el, true);
                                } else if (editable) {
                                    return __renderInputRow(el);
                                } else {
                                    return __renderViewRow(el);
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    switch (displayMode) {
        case 0:
            return _renderProTable();
        case 1:
            return _renderNativeTable(true);
        default:
            return _renderNativeTable(false);
    }
});

export default React.memo(BeaconTableSection, (prev, curr) => {
    const isEqual =
        prev.sourceId === curr.sourceId && JSON.stringify(prev.data) === JSON.stringify(curr.data);
    return isEqual;
});
