import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

interface DragLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    style?: CSSProperties;
    label?: string;
    value: number;
    onValueChange: (val: number) => void;
    min?: number;
    max?: number;
}

export const DragLabel = React.memo<DragLabelProps>(
    (props) => {
        // We are creating a snapshot of the values when the drag starts
        // because the [value] will itself change & we need the original
        // [value] to calculate during a drag.
        const [snapshot, setSnapshot] = useState<number>(props.value);

        // This captures the starting position of the drag and is used to
        // calculate the diff in positions of the cursor.
        const [startVal, setStartVal] = useState(0);

        // We use document events to update and end the drag operation
        // because the mouse may not be present over the label during
        // the operation..
        useEffect(() => {
            // Only change the value if the drag was actually started.
            const onUpdate = (event: any) => {
                if (startVal) {
                    const nextVal = snapshot + event.clientX - startVal;
                    if (
                        (props.min !== undefined && nextVal < props.min) ||
                        (props.max !== undefined && nextVal > props.max)
                    )
                        return;
                    else props.onValueChange(snapshot + event.clientX - startVal);
                }
            };

            // Stop the drag operation now.
            const onEnd = () => {
                setStartVal(0);
            };

            document.addEventListener('mousemove', onUpdate);
            document.addEventListener('mouseup', onEnd);
            return () => {
                document.removeEventListener('mousemove', onUpdate);
                document.removeEventListener('mouseup', onEnd);
            };
        }, [startVal, snapshot]);

        return (
            <label
                onMouseDown={(event: any) => {
                    setStartVal(event.clientX);
                    setSnapshot(props.value);
                }}
                style={{
                    color: 'gray',
                    cursor: 'ew-resize',
                    userSelect: 'none',
                    ...props.style,
                }}
            >
                {props.label}
            </label>
        );
    },
    (prev, curr) => prev.label === curr.label && prev.value === curr.value,
);

export const DragInputNumber: React.FC<InputNumberProps> = (props: InputNumberProps) => {
    return (
        <InputNumber
            {...props}
            addonBefore={
                <DragLabel
                    label={props.title}
                    value={props.value as any}
                    onValueChange={(val) => {
                        if (!isNaN(val)) {
                            if (props.onChange) props.onChange(val);
                        }
                    }}
                />
            }
            value={props.value}
            variant="borderless"
            onChange={(val) => {
                if (!isNaN(Number(val))) {
                    if (props.onChange) props.onChange(Number(val));
                }
            }}
        />
    );
};
