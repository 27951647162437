import ApiClient from 'utils/NetworkManager';

export interface BeaconDto {
    id: number;
    uuid: string;
    major: number;
    minor: number;
    txPower?: number;
    weight: number;
    threshold: number;
    iosThreshold?: number;
    isLowPower: boolean;
    isDoorway: boolean;
    lat: number;
    lng: number;
}

export interface BeaconPlanCreateDto {
    mapId: string;
    name: string;
    beacons: BeaconDto[];
}

export interface BeaconPlanUpdateDto {
    mapId: string;
    name: string;
    beacons: BeaconDto[];
    id: string;
}

export interface BeaconPlanDto extends BeaconPlanUpdateDto {
    isActive: boolean;
    isGenerated: boolean;
}

export interface BeaconPlanActiveDto {
    mapId: string;
    planId: string|null;
}

export interface BeaconPlanInfoDto {
    id: string;
    name: string;
    isActive: boolean;
}

export const getBeaconList = (id: string) =>
    ApiClient.get<BeaconPlanInfoDto[]>(`/beacon/plan/list?mapId=${id}`);
export const getBeaconPlan = (id: string) =>
    ApiClient.get<BeaconPlanDto>(`/beacon/plan?planId=${id}`);
export const createBeaconPlan = (data: BeaconPlanCreateDto) =>
    ApiClient.post<BeaconPlanInfoDto>(`/beacon/plan`, data);
export const updateBeaconPlan = (data: BeaconPlanUpdateDto) => ApiClient.put(`/beacon/plan`, data);
export const deleteBeaconPlan = (id: string) => ApiClient.delete(`/beacon/plan?planId=${id}`);
export const activeBeaconPlan = (id: string, data: BeaconPlanActiveDto) =>
    ApiClient.put<BeaconPlanActiveDto>(`/venue/${id}/map/beacon`, data);
